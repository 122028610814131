// import React from 'react';
// import PropTypes from 'prop-types';

// interface ContextProvider_I extends React.Component {
//     childContextTypes: any
// }

// type C = React.Component & {childContextTypes: any}

// function contextWrapper(WrappedComponent: any, context: any){

//   class ContextProvider extends C  {
//     childContextTypes : any
//     getChildContext() {
//       return context;
//     }

//     render() {
//       return <WrappedComponent {...this.props} />
//     }
//   }

//   ContextProvider.childContextTypes = {};
//   Object.keys(context).forEach(key => {
//     ContextProvider.childContextTypes[key] = PropTypes.any.isRequired;
//   });

//   return ContextProvider;
// }

// export default contextWrapper;
// export default 1
import React from 'react';
import PropTypes from 'prop-types';


export function withContext(WrappedComponent: any, context: any){

    class ContextProvider extends React.Component {
      static childContextTypes: {[key: string]: typeof PropTypes.any.isRequired} ={};
      getChildContext() {
        return context;
      }
  
      render() {
        return <WrappedComponent {...this.props} />
      }
    }
  
    ContextProvider.childContextTypes = {};
    Object.keys(context).forEach(key => {
      ContextProvider.childContextTypes[key] = PropTypes.any.isRequired; 
    });
  
    return ContextProvider;
  }