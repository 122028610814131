import React, { useEffect } from "react";
import {
  Link,
  RouteComponentProps,
  useHistory,
  useParams,
} from "react-router-dom";
import { fetchMarkerMeta, getPreviousAndAfterId } from "../helpers/markers";
import "./Place.css";
import { textLengthInMinutes, textLengthInWords } from "../helpers/textLength";

type PlaceParams = {
  placeName: string; // parameters will always be a string (even if they are numerical)
};
type PlaceProps = RouteComponentProps<PlaceParams>;

const Place: React.FC<PlaceProps> = ({ match }) => {
  useEffect(()=> {
    window.scrollTo(0, 0)

  })

  const history = useHistory();
  let placeMeta = fetchMarkerMeta(match.params.placeName);
  let previousAndAfterId = getPreviousAndAfterId(match.params.placeName);
  // if placeMeta not found, push to home page
  if (!placeMeta) {
    history.replace("/404");
    return <section>NOT FOUND</section>;
  }
  // Define before and after links block
  let beforeAndAfterLinksBlock;

  if (previousAndAfterId) {
    let beforeMarker;
    if (previousAndAfterId.previousMarkerId) {
      beforeMarker = fetchMarkerMeta(previousAndAfterId.previousMarkerId);
    }

    let afterMarker;
    if (previousAndAfterId.afterMarkerId) {
      afterMarker = fetchMarkerMeta(previousAndAfterId.afterMarkerId);
    }
    beforeAndAfterLinksBlock = (
      <div className="pagination-wrapper">
        <div className="pagination-left main-font">
          {(() => {
            if (beforeMarker) {
              return (
                <Link to={`/places/${beforeMarker.markerId}`}>{`<== jour précédent`}</Link>
              );
            }
          })()}
        </div>
        <div className="pagination-right main-font">
          {(() => {
            if (afterMarker) {
              return (
                <Link to={`/places/${afterMarker.markerId}`}>{`jour suivant ==>`}</Link>
              );
            }
          })()}
        </div>
      </div>
    );
  }

  // Define description block
  let descriptionBlock;
  if (placeMeta?.description) {
    descriptionBlock = (
      <h1 className="description">{placeMeta?.description}</h1>
    );
  }

  // define title block
  let titleBlock;
  if (placeMeta?.title) {
    titleBlock = (
      <h1 className="title"><i>{placeMeta?.title}</i></h1>
    );
  }

  // Define text block
  let textBlock;
  let textLength;
  let textLengthBlock;
  if (placeMeta?.text) {
    textBlock = <p className="text" dangerouslySetInnerHTML={{ __html: placeMeta?.text }}></p>;
    textLength = textLengthInWords(placeMeta?.text);
    textLengthBlock = <p className="text">{textLength} mots ({textLengthInMinutes(textLength)}mn de lecture)</p>;
  }

  // Define image block
  let imageBlock;
  if (placeMeta?.imagesName?.length) {
    imageBlock = placeMeta.imagesName.map((imageName) => (
      <img
        className="picture"
        src={`${process.env.PUBLIC_URL}/images/${placeMeta?.markerId}/${imageName}`}
        alt=""
      />
    ));
  } 
  // else {
  //   imageBlock = <p>IMAGES ARE COMING (les images vont arriver bientôt)</p>;
  // }

  // return section

 

  return (
    <div className="content">
      {beforeAndAfterLinksBlock}
      {descriptionBlock}
      {titleBlock}
      {textBlock}
      {textLengthBlock}
      {imageBlock}
      {beforeAndAfterLinksBlock}
    </div>
  );
};

export default Place;
