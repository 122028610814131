import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, withRouter, RouteComponentProps, Switch } from "react-router-dom";
import Home from './pages/Home';
import Place from './pages/Place';
import NotFound from './pages/NotFound';
import MapView from './components/MapView/MapView';
import HeaderNav from './components/HeaderNav/HeaderNav';



const App = (props: RouteComponentProps) => {
  return (
    <div>
      <HeaderNav></HeaderNav>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/map" exact component={MapView} />
        <Route path="/places/:placeName" component={Place} />
        <Route path="/404" component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
}

export default withRouter(App);
