import { useEffect } from "react";
import L from "leaflet";
import { useMap} from 'react-leaflet';


export const MapScale = function () {
    const map = useMap() as L.Map;
    useEffect(() => {
        L.control.scale({imperial: false, metric:true, position: "bottomright", maxWidth: 200}).addTo(map);
    }, [map]);
    return null;
};


