import { Component } from "react";
import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import { LatLngExpression } from "leaflet";

import { MapScale } from "../MapScale";
import PropTypes from "prop-types";
import { withContext } from "../../helpers/ContextWrapper";
import { Link } from "react-router-dom";
import { allMarkers, lastPosition } from "../../helpers/markers";
import { stepsGeojson } from "./stepsGeoJson";

class MapView extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      currentLocation: { lat: lastPosition[0], lng: lastPosition[1] },
      zoom: 12,
    };
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  render() {
    const PopupWithContext = withContext(
      Popup,
      this.context
    ) as unknown as typeof Popup;

    return (
      <MapContainer
        className="leaflet-container"
        // center={MelvinPosition}
        center={lastPosition}
        zoom={6}
        maxZoom={18}
        minZoom={2}
        attributionControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {allMarkers.map((marker) => {
          if (marker.toShow !== false) {
            return (
              <Marker
                position={marker.latLng as LatLngExpression}
                icon={marker.iconType}
              >
                <PopupWithContext className="pre-line">
                  {(() => {
                    if (marker.description) {
                      return (
                        <section>
                          <span>{marker.description}</span>
                          <br />
                        </section>
                      );
                    }
                  })()}
                  {(() => {
                    if (marker.title) {
                      return (
                        <section>
                          <span>
                            <i>{marker.title}</i>
                          </span>
                          <br />
                        </section>
                      );
                    }
                  })()}
                  <Link
                    to={`places/${marker.markerId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click to see more
                  </Link>
                </PopupWithContext>
              </Marker>
            );
          }
        })}
        {stepsGeojson.map((stepGeojson, index) => {
          let color = "#003dff";
          if (index % 2 === 0) {
            color = "#011863";
          }
          return (
            <GeoJSON
              data={stepGeojson}
              pathOptions={{ fillColor: color, color: color }}
            ></GeoJSON>
          );
        })}
        <MapScale />
      </MapContainer>
    );
  }
}

export default MapView;
