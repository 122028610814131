import { Component } from "react";
import "./Home.css";
import { stepMarkers } from "../helpers/markers";
import { textLengthInMinutes, textLengthInWords } from "../helpers/textLength";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



export const options = {
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: 'Combien de kms et de mots par jour ?',
    },
  },
  scales: {
    y1: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
    },
    y2: {
      type: 'linear' as const,
      display: true,
      position: 'right' as const,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};


class Home extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    const totalKM = 2222;

    let cycledKM = 0;
    let numberOfDays = 0;
    let numberOfWords = 0;
    let numberOfWordsEvenDays = 0;
    let numberOfWordsOddDays = 0;

    const labelDays = [];
    const dataKM = [];
    const dataWords = [];

    for (let stepMarker of stepMarkers) {
      const kmThisDay = stepMarker.kms || 0;
      cycledKM = cycledKM + kmThisDay;
      numberOfDays = numberOfDays + 1;
      labelDays.push(`J${numberOfDays}`);
      dataKM.push(kmThisDay);

      if (stepMarker.text) {
        const wordsThisDay = textLengthInWords(stepMarker.text);
        numberOfWords = numberOfWords + wordsThisDay;
        dataWords.push(wordsThisDay);
        if (numberOfDays % 2 === 0) {
          numberOfWordsEvenDays = numberOfWordsEvenDays + wordsThisDay;
        } else {
          numberOfWordsOddDays = numberOfWordsOddDays + wordsThisDay;
        }

      }
    }
    const percentCycled = (100 * cycledKM) / totalKM;

    let featuresDict: {[index: string]: number} = {}

    for (let stepMarker of stepMarkers) {
      if (stepMarker.features) {
        for (let featureKey of Object.keys(stepMarker.features)) {
          let featureValue = stepMarker.features[featureKey]
          if (featureKey in featuresDict) {
            featuresDict[featureKey] = featuresDict[featureKey] + featureValue
          } else {
            featuresDict[featureKey] = featureValue
          }
        }
      }
    }




    const data = {
      labels: labelDays,
      datasets: [
        {
          label: 'kms',
          data: dataKM,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          yAxisID: 'y1',
        },
        {
          label: 'Mots',
          data: dataWords,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          yAxisID: 'y2',
        },
      ],
    };


    return (
      <div className="home">
        <img
          className="home__picture"
          src={`${process.env.PUBLIC_URL}/images/home/HOME_PHOTO.jpg`}
          alt=""
        />
        <h1 className="home__title">The Waïkiki Tour</h1>
        <p className="home__text">
          Bonjour, nous sommes Kiki et Waya, 2 jeunes gens en quête de sens et
          de mojo dans ce monde de brute. Afin de trouver du sens et du fun,
          nous avons décidé de mener une quête spirituelle en réalisant un
          pèlerinage pour rejoindre notre ami Melvin en Pologne depuis la France
          avec la force de nos jambes.
          <br />
          <br />
          Nous partons donc le 15 Juillet 2021 avec tous nos bagages accrochés à
          nos vélos le long de la côte Nord au travers de la Belgique, des Pays
          bas et de l'Allemagne.
          <br />
          <br />
          Nous irons dormir là où le vent nous emportera, chez l'habitant, dans
          des campings ou tout simplement en camping sauvage freestyle quand
          cela est possible.
          <br />
          <br />
          Nous nous sommes fixés un objectif par défaut de plus ou moins 6
          semaines pour arriver à notre destination soit environ une 50aine de
          km de ride par jour.
          <br />
          <br />
          Pour la petite histoire, Melvin habite depuis plusieurs années dans la
          campagne polonaise près de Łódż et a monté une ferme de permaculture
          avec sa future femme Paulina.
          <br />
          <br />
          La ferme s'appelle la Clairiere du Lac :{" "}
          <a
            className="href"
            href="https://www.facebook.com/Clairieredulac/"
            target="_blank"
            rel="noreferrer"
          >
            plus d'infos ici
          </a>{" "}
          ou{" "}
          <a
            className="href"
            href="https://laclairieredulac.com/?fbclid=IwAR17xy0_MwhlwVbvlcqdZKX3KOvZav1Q4IgLdhuNizLX9nSwcs0Y9eElI0E"
            target="_blank"
            rel="noreferrer"
          >
            ici
          </a>
          <br />
          <br />
          Les concepts qui nous accompagneront tout au long de notre parcours
          sont les suivants : le mojo, la sobriété, la kiffance, le freestyle,
          le slow travel et par-dessus toute la nourriture of course.
          <br />
          <br />
          Ce site vous permettra de suivre nos aventures grâce à la{" "}
          <a className="href" href="./map">
            Waïkiki map interactive
          </a>{" "}
          designée sur mesure. N’hésitez pas à cliquer sur chaques étapes pour
          accéder aux photos et histoires farfelues.
          <br />
          <br />
          Que la kiffance soit avec nous.
          <br />
          <br />
          <b>La bise</b>
        </p>
        <h2 className="home__title">Nos stats</h2>
        <div className="home__child">
          <p className="main-font">
            {`KM parcourus : ${cycledKM} / ${totalKM} kms`}
          </p>
          <div className="meter">
            <span style={{ width: `${percentCycled}%` }}>
              <span className="progress"></span>
            </span>
          </div>
          <p className="main-font">
            {`KM journalier moyen : ${Math.floor(cycledKM/numberOfDays)} km`}
          </p>
          <br />
          <p className="main-font">
            {`Nombre de mots écrits : ${numberOfWords} putain de mots `}
          </p>
          <p className="main-font">
            {`(jours pairs = ${numberOfWordsEvenDays} mots ; jours impairs = ${numberOfWordsOddDays} mots)`}
          </p>
          <span className="main-font">
            {`${textLengthInMinutes(numberOfWords)}mn de lecture`}
          </span>
          <div>
          <Line options={options} data={data} />

        </div>
          <br />
          <div className="main-font">
            {Object.keys(featuresDict).map(featureKey => {
              let featureValue = featuresDict[featureKey]
              return (<p>Nombre de {featureKey} : {featureValue}</p>)
            })}
          </div>
        </div>

        <h2 className="home__title">Notre Journal de Guidon : </h2>

        {stepMarkers.map((marker) => (
          <p className="home__text">
            <a className="href" href={`places/${marker.markerId}`}>
              {`${marker.description}`}
              {marker.title ? <i>{` : ${marker.title}`}</i> : ""}
              {`   (${textLengthInWords(marker.text || "")} mots)`}
            </a>
          </p>
        ))}
      </div>
    );
  }
}

export default Home;
