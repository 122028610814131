import { Component } from "react";
import "./HeaderNav.css";
import logoImg from "./WAIKIKITOURMELVINSOLEIL.jpg";

class HeaderNav extends Component {
  render() {
    return (
      <div id="header">
        <div className="header__div">
          <a className="header__link" href="/">
            HOME
          </a >
        </div>
        <img id="logo" src={logoImg}></img>
        <div className="header__div">
          <a className="header__link" href="/map">
            MAP
          </a >
        </div>
      </div>
    );
  }
}

export default HeaderNav;
