import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import './icons.css';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import melvinIconPath from "../icons/melvin_icon.jpg"
import tentIconPath from "../icons/tent_icon.png"
import binocularsIconPath from "../icons/binoculars_icon.png"
import tandemIconPath from "../icons/tandem_icon.png"
import trainIconPath from "../icons/train_icon.png"


export let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [30, 46],
  shadowSize: [35, 46],
  iconAnchor: [17, 46],
  shadowAnchor: [13, 45],
  popupAnchor: [-2, -45],
  //   className: "rotate"
});

export let MelvinIcon = L.icon({
  iconUrl: melvinIconPath,
  iconSize: [60, 60],
  iconAnchor: [17, 46],
  shadowAnchor: [13, 45],
  popupAnchor: [-2, -45],
  // className: "rotate"
});

export let TentIcon = L.icon({
  iconUrl: tentIconPath,
  iconSize: [45, 60],
  iconAnchor: [17, 46],
  shadowAnchor: [13, 45],
  popupAnchor: [5, -45],
  // className: "rotate"
});


export let BinocularsIcon = L.icon({
  iconUrl: binocularsIconPath,
  iconSize: [45, 45],
  iconAnchor: [17, 46],
  shadowAnchor: [13, 45],
  popupAnchor: [5, -45],
  // className: "rotate"
});

export let TandemIcon = L.icon({
  iconUrl: tandemIconPath,
  iconSize: [60, 60],
  iconAnchor: [30, 46],
  shadowAnchor: [13, 45],
  popupAnchor: [5, -45],
  // className: "rotate"
});

export let TrainIcon = L.icon({
  iconUrl: trainIconPath,
  iconSize: [60, 60],
  iconAnchor: [50, 40],
  shadowAnchor: [13, 45],
  popupAnchor: [5, -45],
  // className: "rotate"
});


