import { LatLngTuple } from "leaflet"
import { BinocularsIcon, MelvinIcon, TandemIcon, TentIcon, TrainIcon } from "../components/icons/icons"


const MelvinPosition: LatLngTuple = [51.72637431350857, 19.137116728144566]

const DEFAULT_TEXT = "The text and the images are coming really soon (on tomorrow), we are completely overbooked because of the fast life of our slow travel. If you have some urgent needs, please consider about creating a new lichee cagnote and contributing to it."

interface MarkerMeta {
    latLng: LatLngTuple,
    markerId: string, //unique
    iconType: L.Icon<L.IconOptions>,
    description?: string,
    imagesName?: string[],
    text?: string,
    index?: number,
    kms?: number,
    title?: string,
    features?: { [index: string]: number },
    toShow?: boolean,
}


let specialMarkersPositions: MarkerMeta[] = [
    {
        latLng: MelvinPosition,
        markerId: "melvin-farm",
        iconType: MelvinIcon,
        description: "Notre cible, la clairière du lac !",
        text: `More coming soon !`,
        toShow: false,
        imagesName: ["20210909_155350-COLLAGE.jpg", "20210909_154734-COLLAGE~2.jpg"],
    },
    {
        latLng: [51.031491, 2.368358],
        markerId: "day1-train",
        iconType: TrainIcon,
        description: "Shits just start",
        imagesName: [
            "PXL_20210715_105622857.jpg",
            "PXL_20210715_112703937.jpg",
            "PXL_20210715_165112181.jpg",
            "PXL_20210715_174501419.jpg",
        ]
    },
]

export let stepMarkers: MarkerMeta[] = [
    {
        index: 1,
        latLng: [51.064111, 2.489760],
        markerId: "day1-zuydcoote",
        iconType: TentIcon,
        description: "Jour 1 – Paris -> Dunkerque -> Zuydcoot – 10 km",
        kms: 10,
        title: "Le Grand Départ",
        text: "Après 4 TER, 1 concaténer cassé, 1 heure de retard et 1 erreur de train à Lille, nous arrivons finalement sur notre ligne de départ à Dunkerke à 20h.\n\nAussitôt arrivés au km 0, nous partons en direction du petit village de Zuydcoot à la frontière franco-belge pour être hébergé dans la famille de Vanessa, Maxime et leurs 3 petites filles.\n\n10 km plus loin, nous sommes chaleureusement accueillis par le chef de famille avec des frites, des fricadelles et de la bière lilloise !\n\nNous découvrons notre lieu de repos : un petit clic clac dans le bureau de Vanessa au plus grand des calmes.\n\nMaxime nous donne nos premiers trucs et astuces de cycliste pour la grande aventure qui nous attend (d’après lui, le vent sera dans notre sens) puis on prend une tisane avec la cheffe de famille et au lit !\n\nBilan : première étape passée sans trop de boutade",
        features: {
            "bières": 1,
            "frites": 1,
            warmshower: 1,
        },
        imagesName: [
            "PXL_20210715_180912797.jpg",
            "PXL_20210715_182344084.jpg",
            "PXL_20210716_085954013.jpg",
        ]
    },
    {
        index: 2,
        latLng: [51.202701, 2.914800],
        markerId: "day2-oostende",
        iconType: TentIcon,
        description: "Jour 2 – Zuydcoot -> Koksijde -> Oostende – 47 km",
        kms: 47,
        title: "L'arrivée en Belgique",
        text: "Après un petit déjeuner chill avec la famille Zuydcootoise (grosse dédicasse à Albane la plus jeune qui fête ses 8 ans aujourd’hui), nous nous remettons tranquillou en route à 11h sans trop de pression pour aller voir la Mer du Nord pour la première fois sur la côte de Zuydcoot.\n\nTrès rapidement, on passe la frontière franco-belge avec un petit arrêt au poste de frontière reconverti en chocolatier (on est tellement des mecs stylés que la vendeuse nous a offert 2 boules chocolat blanc coco -> gros gain de mojo instantané pour bien commencer la journée).\n\nContrairement à ce que nous a annoncé Maxime hier, le vent n’est malheureusement pas dans notre sens aujourd’hui.\n\nNous découvrons donc ce que veut dire « avoir le vent de face » dans le jargon cycliste et ce n’est clairement pas le même délire.\n\nPause déjeuner dans un petit boui-boui de la ville côtière balnéaire Koksijde à mi-parcours -> Fish and chips et burger poisson de ouuuuuf (thon pané, wakamé, sauce wasabi, bonjour, merci, au revoir) !\n\nPuis direction Oostende où on retrouve Bram qui nous accueille dans son co-housing tout neuf composé de 30 personnes vivant sur le même site dans une 10aine de maison différente.\n\nNous découvrons notre lieu de repos : l’étage de l’ancienne ferme du site rénovée en immense sale commune, cuisine, salle à manger, salle de bain et chambres pour les invités -> très propre.\n\nOn finit la journée par un petit dîner dans un bar/restau belge juste à côté avec bière belge et fricadelle évidemment. Bilan : si on continue de manger des frites et fricadelles tous les jours jusqu’à la fin du trip, on risque clairement pas d’être en déficit calorique.",
        features: {
            "franchissements de frontière": 1,
            "frites": 2,
            "jours en vent de face": 1,
            warmshower: 1,
            "bières": 1,
        },
        imagesName: [
            "PXL_20210716_091338554.jpg",
            "DSC_2871.JPG",
            "PXL_20210716_202908516.jpg"
        ]
    },
    {
        index: 3,
        latLng: [51.356546, 3.344836],
        markerId: "day3-zwin",
        iconType: TentIcon,
        description: "Jour 3 - Oostende -> Knokke - > Zwin - 46 km",
        kms: 46,
        title: "Découverte du Camping Sauvage sous Zwin",
        imagesName: ["DSC_2877.jpg", "DSC_2884.jpg", "DSC_2890.jpg", "DSC_2893.jpg", "DSC_2897.jpg", "PXL_20210717_134223829.jpg", "DSC_2898.jpg", "PXL_20210717_160954411.jpg", "PXL_20210717_162343292.jpg", "PXL_20210717_164419785.jpg", "DSC_2903.jpg", "DSC_2906.jpg", "DSC_2908.jpg", "DSC_2910.jpg", "PXL_20210717_192947741.jpg", "DSC_2913.jpg", "PXL_20210717_194253938.jpg", "PXL_20210717_211313211.NIGHT.jpg", "DSC_2914.jpg", "DSC_2915.jpg"],
        features: {
            "campings sauvages": 1,
            "sandwichs": 2,
            "glace": 1,
            "frites": 1,
        },
        text: `
Réveil au calme à 9h dans l'ancienne ferme rénovée de Bram. Malgré quelques petites douleurs étranges aux genous, nous repartons tranquillement à 13h30 (slow travel mood tu connais le sang) après une matinée à chiller dans le co-housing et discuter avec le fondateur du projet. En partant, on achète une baguette et du fromage, sandwich express et go sur la route ! 

C'est la 1ère journée sans nuages donc short et ti-shirt de rigueur (t’inquiètes maman on a mis de la crème solaire).

On avance de stations balnéaires en stations balnéaires (remplies de belges riches tout rouge de coup de soleil -> pas vraiment très sexy). Entre 2 stations balnéaires, on trouve des petits lotissements regroupant les belges les plus riches du pays en mode Desperate Housewifes (ou les Sims pour les gamers), gazon de compétition coupé à ras les pâquerettes.

Petite pause waffle (#gaufre) obligatoire à Wenduine à mi-parcours pour reprendre des forces puis direction Knokke, dernière ville avant la frontière.

C’est notre dernière vraie journée en Belgique donc on est forcés de s'arrêter avant Knokke pour manger les vraies frites belges dans une vraie baraque à frite à l'ancienne. 

Ce soir c'est camping sauvage pour la première fois donc on part acheter 2-3 provisions en mode Koh Lanta puis on commence à chercher notre spot proche de la réserve naturelle de Zwin juste avant la frontière. On trouve un petit emplacement vers 21h, montage de la tente, petit couché de soleil, étirements sous les étoiles, tentative de branchement du réchaud échouée donc ça part en sandwich et au lit. 

Challah personne ne nous trouve !`,
    },
    {
        index: 4,
        latLng: [51.578949, 3.584909],
        markerId: "day4-zeeduin",
        iconType: TentIcon,
        description: "Jour 4 – Zwin -> Cadzand-Bad -> Vlissingen -> Zeeduin – 56 km",
        kms: 56,
        title: "Arrivée au Pays du Vélo",
        features: {
            "camping normal": 1,
            ferry: 1,
            "franchissements de frontière": 1,
            "café": 1,
            "jours en vent de face": 1,
            "bières": 1,
            "sandwichs": 1,
            glace: 1,
        },
        imagesName: ["DSC_2915.JPG", "PXL_20210718_072823114.jpg", "PXL_20210718_074741736.jpg", "PXL_20210718_080246228.jpg", "PXL_20210718_081336043.jpg", "DSC_2926.JPG", "PXL_20210718_084257816.jpg", "DSC_2931.JPG", "PXL_20210718_115939999.jpg", "DSC_2933.JPG", "PXL_20210718_122015014.jpg", "DSC_2939.JPG", "DSC_2938.JPG", "PXL_20210718_135701408.jpg", "PXL_20210718_152849021.jpg", "DSC_2944.JPG", "DSC_2946.JPG", "DSC_2951.JPG", "DSC_2948.JPG", "DSC_2956.JPG", "PXL_20210718_191820366.jpg"],
        text: `
Personne ne nous a finalement délogé au cours de notre nuit sauvage. Nuit pas très ouf au passage, il faisait un peu froid et humide. On prépare nos affaires puis on fuit vers 9h du spot pour aller rider dans la réserve naturelle du Zwin remplies d’oiseaux aux cris bizarres, dernière étape avant de sortir du pays.

Ce n'est pas sans émotions que nous faisons notre entrée à la Mecque du vélo (et de la drogue douce) : les Pays-Bas qui n’ont, soit dit en passant, rien à voir avec la vielle côte belge. En effet, nous sommes accueillis comme des rois avec des pistes cyclables double sens signalisés de niveau supérieur situées en bonus au sommet des dunes qui servent de digue. Arrêt obligatoire au café à Cadzand-Bad pour faire un point organisation sur notre vie : lavage de dent, remplissage de bouteille, planning et itinéraire du jour et mise en place de crème solaire sur nos corps tout blancs. Kiki en profite pour acheter 500 grammes de ribs hollandais et 2 énormes cuisses de poulet à 11h du matin, rien de plus normal dans la vie de Kiki. 

Un banc spirituel dans les dunes face à la mer nous permet de déguster ce festin avec les mains en mode moyen-âge. 

Puis arrive notre premier ferry à Breskens pour traverser l’embouchure et arriver sur les îles des Pays-Bas : la Zeeland ! On arrive dans le petit port à l’ancienne de Vissligen puis on continue notre route entre les moulins, les dunes, les forêts et les plages de sable fin. Le vent de face nous contraint à s’arrêter pour déguster une glace (excuse valable). On trouve une place restante pour notre tente dans un minicamping pour ce soir (il y en a des dizaines et des dizaines le long de la côte, c’est des micro campings installés dans des fermes et c’est censé être cheap). En arrivant, la boss nous annonce que ça va coûter 25€ en cash, ça commence mal. Heureusement que nous rencontrons Ana un jeune allemand de 15 ans en vacances avec sa famille qui effectue un énorme transfert de mojo en 3 étapes : il nous fait tester un tandem bike (le rêve absolu de kiki et waya), il nous offre 2 bières et cerise sur le gâteau, ayant repéré assez facilement que nous sommes des campeurs débutants, il nous prête 2 chaises de camping pour manger nos pistaches -> montée de mojo immédiate.

Go douche + découverte de la lessive à la main + sandwich au jambon cru et au lit -> 1h du matin !
        `
    },
    {
        index: 5,
        latLng: [51.741205, 3.777779],
        markerId: "day5-renesse",
        iconType: TentIcon,
        description: "Jour 5 - Zeeduin -> Burgh Haamstede -> Renesse - 31 km",
        kms: 31,
        title: "Camping Sauvage 5*",
        features: {
            "campings sauvages": 1,
            "jours en vent de face": 1,
            "bières": 1,
            "sandwichs": 1,
            "frites": 1,
        },
        imagesName: ["DSC_2963.jpg", "PXL_20210719_120025867.jpg", "DSC_2966.jpg", "DSC_2971.jpg", "DSC_2976.jpg", "PXL_20210719_135114743.jpg", "DSC_2978.jpg", "PXL_20210719_141514604.jpg", "PXL_20210719_142425847.jpg", "DSC_2981.jpg", "DSC_2986.jpg", "PXL_20210719_161352713.jpg", "DSC_2988.jpg", "PXL_20210719_183640895.jpg", "PXL_20210719_184402470.jpg", "PXL_20210719_185225319.jpg", "PXL_20210719_192132729.jpg", "PXL_20210719_204044346.NIGHT.jpg"],
        text: `
En se réveillant, on se rend compte qu'on a oublié un petit détail non négligeable : la rosée du matin hollandaise. 

En effet, nos habits propres qui séchaient dehors sont encore plus trempés que la veille (grosse tuile). 

On est donc contraint de rester au camping le matin le temps que nos habits sèchent. 

Une fois nos habits séchés, on salue nos nouveaux potos du camping pour leur accueil puis on reprend la route à 14h (tranquille slow life) pour aller déjeuner à la meilleure friterie poisson de la région recommandée par Arne à quelques km de là. 

Grosse friture dans le ventre puis ça repart. 

Pour passer d'une île à une autre sur la côte, on traverse 2 barrages d'une longueur totale de 10 km (ces barrages servent à retenir l'eau de la mer pour éviter les inondations lors des grandes marées) au milieu des champs d'éoliennes (vent de face de rigueur, on commence à être habitué). 

Notre objectif ultime pour ce soir est de faire marcher notre réchaud pour faire cuire nos premières pâtes sauvages. Le problème est que nous n'avons pas l'embout adapté à notre bouteille de gaz et que les magasins de camping sont en rupture de cet embout car, il serait, d'après la légende hollandaise, encore bloqué sur le cargo evergreen à Rotterdam lol. 

Pas de pâtes pour ce soir du coup... Pour la première fois, nous commençons à ressentir tous les 2 la fatigue dans les jambes et dans la tête, nous décidons donc de changer de planning et de s'arrêter à mi-parcours après 30 km de ride. En marchant dans les ronces des dunes, on trouve le meilleur spot de camping sauvage de la côte Dutch, isolé, au sommet des dunes, vue sur la mer et la plage ❤ On monte le camp + apéro bière pistache musique + sandwich jambon gouda + méditation et au lit à 23h.
        `
    },

    {
        index: 6,
        latLng: [51.987447, 4.110148],
        markerId: "day6-hookofholland",
        iconType: TentIcon,
        description: "Jour 6 - Renesse -> Goedereede -> Hook of Holland -> 62 km",
        kms: 62,
        title: "De la Compté au Mordor en 500 mètres",
        features: {
            "campings sauvages": 1,
            ferry: 1,
            "sandwichs": 3,
            "café": 1,
        },
        imagesName: ["PXL_20210720_061113261.jpg", "DSC_2995.jpg", "PXL_20210720_083451756.jpg", "DSC_2996.jpg", "DSC_2998.jpg", "DSC_3001.jpg", "PXL_20210720_111021195.jpg", "PXL_20210720_111732733.jpg", "DSC_3006.jpg", "DSC_3009.jpg", "PXL_20210720_140748759.PORTRAIT.jpg", "DSC_3012.jpg", "PXL_20210720_151232545.jpg", "DSC_3014.jpg", "DSC_3015.jpg", "PXL_20210720_155512973.jpg", "DSC_3017.jpg", "DSC_3020.jpg", "DSC_3025.jpg", "DSC_3026.jpg", "PXL_20210720_171752386.jpg", "PXL_20210720_191400827.jpg", "DSC_3032.jpg", "DSC_3035.jpg"],
        text: `
Comme notre spot de camping l'annonçait, on a passé une nuit 5 étoiles bercés par les vagues. On se réveille dans notre tente tranquille à 8h puis petit déjeuner face à la mer classique sandwich gouda jambon avec en bonus une orange. 

Le temps de ranger le camp, on repart vers 10h et cette fois-ci plein d'énergie ! Entre 2 champs, on fait notre première rencontre sur le vélo, Hetty, une hollandaise qui (re)découvre son propre pays en faisant le tour des Pays Bas toute seule sur son vélo, 700km environ pendant 3 semaines. 

Nous passons brutalement de la Comté gazon rasé au Mordor plage mazouté en arrivant à l'embouchure du port de Rotterdam. Nous débarquons donc dans un décor fait de cargo, contenairs par milliers, usine à charbon, autoroutes, voies ferrées, entrepôt de stockage, usines... Bref, le cœur de la matrice, où transite tout le commerce mondial (route maritime la plus empruntée au monde d'après un type qu'on a croisé). On prend donc un ferry pour fuir au plus vite des entrailles du démon. 

Sur le ferry qui nous ramène sur la côte, on recroise une fraté qu'on avait croisé sur la route, Anne Lise, une hollandaise d'environ 70 ans qui est en vacances. Elle nous propose de venir visiter son magnifique cottage qu'elle a acheté il y a 8 ans (bungalow dans une parcelle de camping).

Après avoir constaté que son gazon était bien tondu et qu'elle n'allait pas nous proposer de dormir chez elle, nous décidons de se mettre à la recherche d'un spot de camping car il est déjà 20h. 

On trouve un spot dans un genre de marecage d'une réserve nationale proche de la côte. On installe le campement accueillis par un famille de moustiques qui vit ici depuis plusieurs générations. 

3eme sandwich au gouda de la journée + appel des amoureuses et parents + au lit 

Bilan : grosse grosse journée aujourd'hui, on a battu notre record de distance : 62 km. Les genous sont un peu fatigués mais ça va on est chauds.
`
    },
    {
        index: 7,
        latLng: [52.27018106945414, 4.4764519893594725],
        markerId: "day7-noordwijk",
        iconType: TentIcon,
        description: "Jour 7 - Hook of Holland -> La Haye -> Noordwijk - 53 km",
        kms: 53,
        title: "Balade sur les Dunes Maléfiques de Tatooine",
        features: {
            "camping normal": 1,
            "sandwichs": 3,
            "bières": 1,
            glace: 1,
        },
        imagesName: ["DSC_3038.jpg", "PXL_20210721_073851354.jpg", "DSC_3039.jpg", "DSC_3041.jpg", "DSC_3042.jpg", "PXL_20210721_094231889.jpg", "PXL_20210721_094349492.jpg", "DSC_3044.jpg", "DSC_3045.jpg", "DSC_3046.jpg", "DSC_3048.jpg", "DSC_3049.jpg", "DSC_3050.jpg", "DSC_3052.jpg", "DSC_3054.jpg", "DSC_3055.jpg"],
        text: `
        Levé 8h, nuit pas ouffissime, un peu froid et humide, assez logique pour un marécage en fait... 

On décide la douche en top numéro 1 de nos priorités du jour (en effet ça fait plus de 48h que nos corps n'ont pas été lavés). On se rend donc sur la plage rempli de beach club à une centaine de mètre de notre campement où, bingo, nous trouvons de magnifiques douches publiques vue sur la mer 5 étoiles. Nous profitons d'être la pour aller tester la fraîcheur de la mer du Nord pour la première fois avec vue sur les porte conteneurs. Bain froid dans la mer au soleil + douche Ushuaïa savon de Marseille lavande et thym = montée de mojo instantanée.

On traverse quelques champs de serres puis on fait une vingtaine de kilomètres au sommet des dunes entre sable, fleurs et bunkers (pour l'instant la plus belle partie qu'on ai faite à vélo). 

On arrive dans notre première grande ville, le port de la Hague et la fatigue de la nuit se fait sentir. Un sandwich chaud + une glace pour nous rebooster et c'est reparti à 16h pour 20 km de ride sur les dunes. 

On découvre pour la première fois la signification du mot "dénivelé" dans le jargon cycliste et ça fait clairement pas plaisir avec 20 kg de bagages. On décide de s'arrêter vers 20h dans un mini camping hollandais après 50 km dans les jambes. On plante la seule tente du camping à côté d'un champ de tulipes (sans tulipes car la récolte se fait au printemps) et grosse montée de mojo lorsque la boss vient nous amener 2 chaises et une table pour chiller en mode vrai campeur Dutch (les montées de mojo sont plus faciles après 2 jours de camping sauvage). Pendant notre apéro amandes, on a la chance de pouvoir assister à un traditionnel tondage de pelouse hollandais en live avec tondeuse voiturette, vraiment typique. On enchaîne avec un gros festin en fêtant nos premiers légumes du trip et nos premiers 300 km  ! 

Après un coucher de soleil étirements méditation introspective bien sympa, on prend la sage décision de ne pas rider le lendemain et donc, de ne pas aller à Amsterdam tout de suite. En effet, nos genoux ont plus que bien travaillé aujourd'hui, ils méritent amplement une bonne journée de repos au camping. Cet élan de lucidité inespéré nous permet donc de nous endormir dans notre tente en toute paisibilité.`
    },
    {
        index: 8,
        latLng: [52.27018106945414, 4.48],
        markerId: "day8-noordwijk2",
        iconType: TentIcon,
        description: "Jour 8 - Noordwijk -> Noordwijk – 5.0 km",
        kms: 5,
        title: "Pause Stratégique des Genoux dans le Monde des Tulipes",
        features: {
            "camping normal": 1,
            "sandwichs": 2,
            glace: 1,
            "jours de repos": 1,
            "tulipes décortiquées": 12,
        },
        imagesName: ["DSC_3059.jpg", "PXL_20210722_111736141.jpg", "DSC_3060.jpg", "DSC_3063.jpg", "DSC_3066.jpg", "PXL_20210722_150806954.jpg", "DSC_3067.jpg", "PXL_20210722_191810012.jpg"],
        text: `Le réveil à 9h puis le snooze jusqu'à 10h annonce clairement la couleur de la journée : le CHILL. On a passé la meilleure nuit du séjour, plus de 9h de sommeil, je pense qu'on en avait bien besoin...

Grosse montée de bonheur quand le boss du camping nous amène une grosse tente ombrière pour pouvoir chiller à l'ombre toute la journée tel de vrais campeurs hollandais expérimentés (photos à l'appui). 

On profite de notre jour de congé pour faire le point sur nos vies, laver nos habits, décrasser et entretenir nos vélos qui ont bien été attaqués dans cet environnement hostile sablé qu'est la côte Nord. La boss du camping nous apprend même le cycle de culture des tulipes (fleur nationale) : on plante un bulbe en Septembre-Octobre, la tulipe fleurit au Printemps, en Juin on récupère les nouveaux bulbes sous terre, on les fait sécher un petit peu pendant l’été puis on les replante à nouveau. Kiki se prête même à l’exercice de nettoyer les bulbes sortis de terre. On est tellement bon délire que la boss nous fait don d’une dizaine de bulbe de tulipe rouge de qualité supérieure. 

Petit tour au village d’à côté pour faire quelques courses (des légumes maman t’inquiètes, c’est fini les poissons frits). Kiki en profite pour réhausser sa potence et ajouter des cales pieds sur ses pédales dans un bikestore.

C’est décidé, demain on fera 40 km pour aller à Amsterdam, un ami de Kiki pourra nous héberger là-bas vendredi soir. Challah on trouve un endroit pour dormir Samedi soir et on reste une journée de plus.

Apéro saucisson + dîner fruits et légumes + coucher de soleil et au lit, demain on part tôt pour rider dans la matinée.`
    },
    {
        index: 9,
        latLng: [52.37020935609072, 4.867728878960399],
        markerId: "day9-amsterdam1",
        iconType: TentIcon,
        description: "Jour 9 - Noordwijk -> Amsterdam - 40 km",
        kms: 40,
        title: "Amsterdam, la Découverte du Voltarene",
        features: {
            "dodo chez des potos": 1,
            "sandwichs": 1,
            "bières": 2,
            "vrais restos": 1,
            "pots de voltaren achetés": 1,
        },
        imagesName: ["DSC_3069.jpg", "DSC_3070.jpg", "DSC_3072.jpg", "DSC_3073.jpg", "PXL_20210723_092838771.jpg", "DSC_3075.jpg", "PXL_20210723_102922189.jpg", "PXL_20210723_104131239.jpg", "DSC_3080.jpg", "DSC_3081.jpg", "DSC_3083.jpg", "DSC_3084.jpg", "DSC_3085.jpg", "PXL_20210723_162456931.jpg", "PXL_20210723_165913491.jpg", "PXL_20210723_193616101.jpg"],
        text: `
Levé 8h, mini snooze de 10 min, dernier petit déjeuner au camping et départ à 10h après avoir salué nos potos éleveurs de tulipes. Même si nos genoux semblent avoir bien kiffé leur journée de congé hier, on repart tranquille pour les ménager. Aujourd'hui c'est objectif Amsterdam situé à 40 km !!!

Entre 2 maisons de Sims gazon parfaitement rasé, on fait des pauses abricot-mandarine pour garder la pêche (lol). Sur le chemin, on découvre un des secrets de leur gazon parfait : le robot tondeuse qui tond le jardin tout seul, accessoire typique d'un bon citoyen hollandais. 

En se rapprochant d'Amsterdam, on remplace progressivement les champs par les immeubles. Comme prévu, on arrive à Amsterdam à 15h, accueilli par Thibault aka Crunch (pour les gadz trads qui nous lisent, c’est le P6 de Kiki), un pote de Kiki qui habite ici et qui nous héberge dans son appartement pour ce soir. 

Après avoir pris une bonne douche saveur lavande, nous décidons de partir vadrouiller dans le centre-ville. En se perdant dans les rues, nous découvrons et achetons une drogue très puissante. En effet, les effets sont impressionnants : immédiatement après consommation de la substance, toutes les douleurs musculaires et articulaires du corps sont effacées et nos âmes peuvent enfin se reposer en paix. Je parle bien évidemment du Voltaren Emulgel anti-inflammation bien chargé avec 1,16% de diclofebac duethylammonium, c'est vraiment la frappe atomique, du très bon produit. Seul inconvénient, cette drogue ne peut se consommer que 4 fois maximum par jour… On se balade donc dans Amsterdam sous Volta à la recherche du meilleur Gouda de la ville. Après 3 ou 4 dégustations de fromage aux saveurs improbables (truffe, lavande, pesto, chili...), nous décidons d'acheter 300 g de jeune Gouda à déguster sur le pouce. 

On rentre à 19h chez Crunch pour passer à l'apéro gouda-bière avant de se rendre au restaurant indonésien pas loin de chez lui (apparemment une des meilleures villes pour tester cette cuisine car l'Indonésie est une ancienne colonie hollandaise). Ce dîner est l'occasion d'apprendre beaucoup de choses car Crunch nous transmet sa vision du français expatrié depuis maintenant 7-8 ans dans ce pays. 

Fun fact (ou pas) :
- Les fruits et légumes de ce pays sont bels et bien de qualité médiocre et sans goût (production industrielle de masse sous serre et lumière…).
- Amsterdam est situé à -2 mètres du niveau de la mer, 2/3 du pays est situé sous le niveau de la mer. Merci les digues.
- Certains immeubles ne sont pas droit dans le centre-ville car les vielles fondations en bois commencent à se détériorer.
- La cuisine Dutch n'existe quasiment pas, contrairement à nous, ces gens n'ont pas mis en priorité numéro 1 la bouffe dans leurs vies. 
- Il y a environ 50% d'expat à Amsterdam, c’est une grosse ville internationale.
- Les 50% d'expats d'Amsterdam ne restant généralement pas plus de quelques années, Amsterdam est un marché de location. Les apparts se louent aussi chers qu'à Paris pour des prix à l'achat deux fois moindre.

Après ce festin, seulement une petite bière digestive à l'appartement suffit pour nous coucher. Après 6 nuits dans une tente 2 personnes, nous redécouvrons enfin les joies et plaisir d'un confortable canapé lit. 

Bonne nuit.
`
    },
    {
        index: 10,
        latLng: [52.360503, 4.878788],
        markerId: "day10-amsterdam2",
        iconType: TentIcon,
        description: "Jour 10 - Amsterdam -> Amsterdam - 5 km",
        kms: 5,
        title: "Désynchronisation dans le Cloud",
        features: {
            "nuits à l'auberge": 1,
            "sandwichs": 1,
            "plats de pâtes": 1,
            "frites": 1,
            "jours de repos": 1,
        },
        imagesName: ["PXL_20210724_085136080.jpg", "PXL_20210724_095641936.jpg", "DSC_3087.jpg", "DSC_3088.jpg", "PXL_20210724_124058117.jpg", "DSC_3090.jpg", "PXL_20210724_134346673.jpg", "sandwich2.jpg", "sandwich1.jpg", "DSC_3093.jpg", "PXL_20210724_163517606.jpg", "DSC_3094.jpg", "DSC_3096.jpg"],
        text: `
Réveil au calme à 9h dans notre canapé lit ultra confort. On prend enfin un vrai petit déjeuner à base de yaourt et muesli qui fait plaisir. On trouve sur internet une auberge de jeunesse qui a l'air cool pour ce soir, the Flying Pig (grosse dédicace à Melvin Pig tu coco). On se met sous Volta, on range nos affaires, on salue notre ami Crunch puis on se met en route direction l'auberge pour le check-in à 11h au Sud du centre-ville. 

A l’accueil, en remarquant qu'on est des cyclistes pros, le boss de l'auberge a la bonne idée de nous mettre dans le dortoir situé au dernier étage sans ascenseur (mauvaise nouvelle pour les genoux sachant que les escaliers Dutch ressemblent plutôt à des échelles). 

On range nos affaires, puis on décolle avec 1 objectif prioritaire en tête : trouve enfin le bon adaptateur pour notre réchaud. On atteint rapidement notre but ultime dans un magasin de camping pas loin -> grosse victoire qu'on célèbre en allant manger dans un restaurant de sandwich hollandais (avec en bonus un smoothie banane framboise pomme) -> grosse montée de mojo. 

On vit actuellement notre best life car on réalise un gros goal life en ridant Amsterdam, la Mecque du vélo, sur nos propres vélos (sans bagages of course, état de liberté maximal atteint sur l’échelle du Nirvana) 

Les genoux de Waya étant encore très fragile, nous prenons la sage décision de rester une nuit de plus à Amsterdam en congé vélo (y’a pire comme ville pour faire une pause). 

Après une petite balade dans le centre, on décide d'aller prendre un petit goûter frites + cookies chez 2 adresses voisines qui nous avaient fait de l’œil durant la journée. 

En commandant nos frites de luxe, on a le malheur de constater que le cookieshop (style Scoop me a Cookie à Paris pour les vrais food lover) à côté vient à peine de fermer (18h30, il est 18h31) -> énorme déception/bad, descente de mojo immédiate... 

Ce qu'il va se passer dans la suite de ce récit dépasse toute imagination et illustre parfaitement la définition du fameux "Mojo" dont nous faisant tant référence ces derniers jours (il est fortement conseillé d'essayer de reproduire ça à la maison) :

Dans un élan de détresse, Waya s'approche du magasin pour contempler les cookies en vitrine une dernière fois. À cet instant précis, celui-ci remarque qu'il reste 3 employées au comptoir. Eye contact réalisé, il essaye immédiatement d'établir un contact avec elles à l'aide de la langue des signes en exprimant sa détresse. Inespérément, la vendeuse lui sourit et lui propose de rentrer dans le shop, ce que Waya s'empresse de faire suivi par Kiki 2 pas derrière lui. Elles nous indiquent qu'il reste justement 2 cookies, totalement improbable ! Et là, coup de théâtre, elle décide de nous offrir généreusement ces deux derniers cooks. Waïkiki estomaqués, n'en croient pas leurs estomacs. Ils remercient avec grande joie ces 3 muses tombées tout droit du ciel et s'en vont déguster leurs trouvailles sur un banc face à un canal. Conclusion de l'histoire : ces cookies sont vraiment délicieux. 

Suite à cette grande bouffée de bonheur, nous reprenons nos vélos pour rider dans Amsterdam, aller visiter la places des musées, faire quelques petites courses et come back à l'hostel. En arrivant à 21h, on découvre que la cuisine de l'auberge est fermée pour raisons sanitaires. En faisant usage de notre mojo infini avec le gars à l'accueil, il nous ouvre finalement la cuisine en mode VIP French guest.

Pendant la préparation de notre Goudanara (carbonara avec du gouda) + salade courgette tomate feta en entrée, nous faisons la rencontre de Chema, un jeune espagnol qui travaille ici pour l'été en tant que volontaire (et qui n'aime pas cette ville car trop internationale et pas assez traditionnelle). 

On dîne dans la salle à manger qui est aussi la reception, le bar, le salon, le fumoir, la salle des jeu de sociétés et le restaurant de l'auberge. C'est samedi soir donc il y a pas mal de passage autour de nous, des espagnols, brésiliens, italiens, belges... On se croirait dans une blague de mauvais goût mais qui reste assez drôle tout de même. 

Quelques échanges internationaux et parties d'échecs plus tard, nous décidons de mettre fin à cette longue et belle journée dans notre dortoir 10 personnes confort max. 

Nos genoux sont encore très faibles, nous croisons les doigts pour que le mojo les soigne au plus vite.

"Que la voltaren soit avec nous."  Crunch

Bonne nuit le sang.
`
    },
    {
        index: 11,
        latLng: [52.36083426289695, 4.88002006697522],
        markerId: "day11-amsterdam3",
        iconType: TentIcon,
        description: "Jour 11 - Amsterdam -> Amsterdam - 0 km",
        title: "Pause Chinoise Epicée",
        kms: 0,
        features: {
            "nuits à l'auberge": 1,
            "sandwichs": 1,
            "jours de repos": 1,
            "vrais restos": 1,
            "jours de pluie": 1,
            "paires de chaussettes stylées achetées": 7,
        },
        imagesName: ["PXL_20210725_103447018.jpg", "DSC_3099.jpg", "PXL_20210725_122704846.jpg", "DSC_3101.JPG", "PXL_20210725_175335887.jpg", "DSC_3102.jpg", "PXL_20210725_182005368.jpg", "DSC_3104.jpg", "DSC_3107.jpg"],
        text: `
Levé 9h30 dans notre lit superposé maxi confort. Nos articulations semblent aller un peu mieux mais on reste clairement toujours des mous du genoux. On a le petit déj all-you-can-eat inclus avant 10h30 donc vous pouvez compter sur Waikiki pour ne pas rater ça. Œufs durs, bananes, beurre de cacahouètes, oranges, pommes, gouda… on se met relativement bien.

A notre grand regret, nous nous fixons pour objectif aujourd'hui de faire reposer nos petits genoux avant le départ prévu pour demain matin. En effet, nous aimerions tant chevaucher nos vélos et partir déambuler, flâner et faire des bêtises dans cette charmante ville mais, sagesse Waikiki oblige (c'est rare il faut en profiter), on se contient et on passe en mode chill.

On nous fait changer de chambre (on passe du 4ème au 2ème étage -> bonne nouvelle pour les genoux) , on prend une bonne douche puis on part chiller dans un parc en face de l'auberge. Quelques gouttes de pluie nous contraignent à décaler notre chillance au bar de l'auberge.

Soit dit en passant, nous sommes toujours en attente de la tempête de pluie hollandaise prévue dans tout le pays depuis 2 jours, challah elle n’est pas décalée à demain !

On ne va pas se mentir, à part se shooter au Volta et à l'Ibuprofène, nous n'avons littéralement rien branlé aujourd'hui. Par conséquent, nous décidons de nous bouger un petit peu ce soir et sortir dans un gros restau. On trouve un restaurant chinois bien noté pas loin de l'auberge, donc on booke et on go. Au menu, grosse fondue chinoise traditionnelle avec légumes, dumplings, mushrooms, brochettes de poulet et même langue de bœuf, tripes... Bref, truc de ouf, on se casse l'estomac et on valide complètement surtout que les serveurs sont des sanchos de la veine. 

Après cette expérience umami remplie de sapidité, nous rentrons tranquillement à l'auberge avec pour planning petit tournoi d'échec avec deux français rencontrés dans la journée suivie d'un gros dodo régénérateur.

Nos plans tombent rapidement à l'eau lorsque nous découvrons la grosse soirée organisée dans la salle commune de l'auberge qui rassemble environ 30 personnes, dont tout le staff de l'auberge (staff qui, comme nous, n'en branle vraiment pas une à part se mettre des caisses et se rouler des gros dragons). 

On fait donc la rencontre de Félix, un professeur autrichien de 31 ans, sang de la veine, qui réalise un Interrail tout seul en Europe pendant un mois (cadeau de ses potes pour ses 30 ans, à croire qu'ils n'avaient pas trop envie de voir sa tête cet été). On délire avec lui puis la fatigue et la sagesse reprennent le dessus et on décide d'aller se coucher à 1h afin d'être en forme pour la reprise des hostilités demain.

Buena noche.
        `
    },
    {
        index: 12,
        latLng: [52.663228, 5.044301],
        markerId: "day12-hoorne",
        iconType: TentIcon,
        description: "Jour 12 - Amsterdam -> Hoorne - 46 km",
        title: "Maitrise des Elements avec Drop Alert",
        kms: 46,
        features: {
            "warmshower": 1,
            "sandwichs": 1,
            "ferry": 1,
            "jours de pluie": 1,
            "vrais restos": 1,
            "pots de beurre de cacahouètes": 1,
        },
        imagesName: ["DSC_3110.jpg", "DSC_3111.jpg", "PXL_20210726_113014207~2.jpg", "PXL_20210726_120224027~2.jpg", "DSC_3119.jpg", "DSC_3131.jpg", "DSC_3132.jpg", "DSC_3133.jpg", "DSC_3136.jpg", "DSC_3140.jpg", "original_5e2f2137-c93a-40ec-b8a4-493323ae8dc1_PXL_20210726_150511667.jpg", "PXL_20210726_153220960.jpg", "DSC_3145.jpg", "DSC_3146.jpg", "DSC_3149.jpg", "PXL_20210726_195154831~2.jpg", "PXL_20210726_200940805.jpg", "DSC_3151.jpg"],
        text: `
    Ce matin, c'est le grand jour de la reprise, levé 9h, on se sent bien reposé après ces 2-3 jours de congés même si nos genoux sont loin d'être tout neuf. Quelques minutes après notre réveil, nous constatons assez rapidement que choisir un bouillon medium spicy hier au restau n’était vraiment pas une idée de génie (rip toilet). 
            
    Comme depuis notre arrivée, les gens de l'hostel sont vraiment trop sympas, on se croirait aux pays des merveilles ici avec une petite saveur madeleine de Proust Erasmus. Il se trouve que la smoking room (tobacco forbidden) de l’hostel n’est pas si étanche que ça, on est peut-être tous dans le cloud au final… 
            
    Avant de quitter la Mecque du vélo, on passe acheter un mini jeu d'échec pour garder les neurones au frais durant le voyage ainsi qu'un "6 qui prend" pour enjailler nos futurs hôtes.
            
    Quand on pense finalement avoir finalisé nos emplettes et pouvoir partir (vers 13h, 3 heures plus tard que prévu), on tombe nez à nez devant notre magasin de rêve : le plus gros shop de chaussettes du pays. Kiki et Waya n'en croient définitivement pas leurs yeux et se précipitent à l'intérieur. 
            
    Il y en a des millions, pour tous les goûts : animaux, nourritures, films, sports, dragons, on ne sait vraiment pas où donner de la tête, on est comme 2 enfants perdus à Disneyland. On arrive tant bien que mal à sélectionner 2 paires de chaussettes chacun en vitesse pour ne pas perdre trop de temps et se remettre en route. 
            
    Après seulement un ferry pris à la gare d'Amsterdam pour traverser la rivière, on se retrouve en quelques minutes dans les champs au Nord de la ville, gros changement d'ambiance.
            
    Le ciel nous menaçant clairement, nous décidons de faire une pause à mi-parcours dans la petite ville de Puréedemerde (aka Purmerend). Aussitôt après avoir commandés 2 sandwichs dans un café stylé du centre-ville, une énoooooorme tempête s'abat sur la ville pendant quasiment une demi-heure (très rare d'après le boss du restau), vraiment impressionnant ! Immense mojo timing, on ne pouvait clairement pas faire mieux ! (Cet épisode pluvieux nous aura au moins permis de tester l’étanchéité de nos bagages -> on valide). Après la tempête et notre dessert brownie, nous reprenons la route vers 17h. 
            
    Par le mojo miracle, nous arrivons enfin à notre destination, Hoorn, en évitant la grosse tempête nationale. Nous sommes très chaleureusement accueillis par Hanneke et sa pote Connie dans un lotissement en périphérie de la ville. On prend une douche, range nos affaires, puis notre hôte nous offre une bonne soupe chaude veggie trop bonne. Puis on enchaine histoires farfelues sur histoires farfelues avec elles, sachant que Hanneke en a plus d’une dans son sac. En effet, cette dame s’est rendue, il y a 2 ans, jusqu’à St Jacques de Compostelle en Espagne depuis sa ville en Hollande toute seule à vélo. Plus de 3000 km parcourus dont environ 70 à 80 km par jour avec tous ses bagages pendant 6 semaines (farcie complet). Elle n’a apparemment pas eu mal aux genoux durant ce « petit » voyage ce qui nous fait comprendre que nous ne sommes clairement pas nés sous la même étoile.
            
    On apprend aussi une chose CAPITALE : le gouda ne se prononce pas « gouda » mais plutôt « raaaaouda » (des barres de rire).
            
    Petite infusion et au dodo dans notre chambre privative.
            
    Il est 1h et tout va bien.
            
    Bisou.
        `
    },
    {
        index: 13,
        latLng: [53.065790, 5.425850],
        markerId: "day13-makkum",
        iconType: TentIcon,
        description: "Jour 13 - Hoorn -> Stavoren -> Makkum - 51 km",
        kms: 51,
        title: "Echec et Mat dans la Clairière Enchantée",
        features: {
            "camping normal": 1,
            "ferry": 1,
            "café": 1,
            "jours de pluie": 1,
            "pains perdus": 1,
        },
        imagesName: ["DSC_3152.jpg", "PXL_20210727_071111280.jpg", "DSC_3156.jpg", "DSC_3158.jpg", "PXL_20210727_093849176.jpg", "DSC_3159.jpg", "DSC_3178.jpg", "PXL_20210727_133756235.jpg", "PXL_20210727_141835171.jpg", "DSC_3186.jpg", "PXL_20210727_163058465.jpg", "DSC_3195.jpg", "DSC_3207.jpg", "DSC_3208.jpg"],
        text: `
    Levé 8h, énorme petit déj avec Hanneke à base d'œufs brouillés, haricots verts sauce tomate, gouda ET surtout, nouvelle découverte : le Hagelslag (des vermicelles en chocolat) sur une tartine de beurre de cacahouète (farci complet). Apparemment, les Dutch mangent ces vermicelles au petit déjeuner sur des tartines de beurre, wtf.

    À force de tchatcher Hanneke, nous prenons du retard sur le planning et partons à 10h20 sachant que notre objectif est d'aller prendre un ferry à Enkhuizen à 12h45 situé à 25 km (il n'y a que 3 ferrys par jour donc nous n’avons clairement pas le choix). On salue et remercie Hanneke le sang pour l'accueil et on repart aussitôt. 

    Notre retard nous contraint à raccourcir notre trajet et passer par une piste cyclable bidon située le long d'un grand axe routier. Sur la route, on croise à nouveau le robot tondeur de gazon dans un jardin et un passant nous en apprend un peu plus sur le secret ultime de leur gazon parfait : ce robot est équipé d'une navigation GPS et possède sa propre station de recharge automatique (ce robot doté d'intelligence artificielle de pointe vous fera gagner de précieuses minutes dans votre semaine bien chargée, vous avez la possibilité de vous procurer ce bijou de technologie pour la modique somme de 1000 €, no way! We are estomaqués! This is completely farci!).

    En parlant de smart acquisition, Kiki trouve sa nouvelle mascotte sur le bord de la route (quelques hollandais mettent devant leur portail des tables avec des objets à vendre et une tirelire, la confiance est maximale). Pour seulement 1.50€, il fait l’acquisition d’une peluche Pikachu qu’il accroche à la proue de son vélo. C’est certain, cette amulette sacrée nous apportera du dénivelé de mojo positif (merci à Naïs pour la punchline) et nous protégera des flammes du dragon. 

    On arrive just on time au ferry, à peine le temps d'acheter une barquette de Kibbeling (poisson frits, spécialité de la côte Dutch) pour le voyage qui dure 1h30, l'occasion parfaite de tester notre nouveau mini jeu d'échec transportable. 

    Grâce à notre nouvelle application téléphone de prévision pluie sur les 2 prochaines heures avec précision de 5 minutes (Drop Alert, appli de pro recommandée par des hollandais qui s'y connaissent en chute d'eau), nous contrôlons à présent les éléments et choisissons donc de nous réfugier dans un café à l'arrivée du ferry pour éviter le nuage de pluie arrivant tout droit vers nos corps tout secs. 

    Après ce petit caféchec et mat, nous reprenons la route et découvrons pour la première fois ce que veut dire ''avoir le vent dans le dos'' dans le jargon cycliste : on fait des grosses pointes à 17-18 sans forcer, avec le vent dans le dos, le vélo c'est clairement de l'eau (lol). 

    À mi-parcours, on effectue un petit blitz d'échec spirituel au centre d'une clairière sacrée pour nous rebooster mentalement. 

    Ce soir, nous allons re-découvrir le plaisir de la tente dans un mini camping Dutch à Makkum (camping sauvage trop galère sur cette côte et avec la pluie prévue pour toute la nuit). Pour notre dîner au camping ce soir, Kiki tient absolument à nous préparer sa recette préférée : le pain perdu façon Kiki. Pour réaliser cette recette, c'est très simple :

- acheter un bon gros paquet de pain de mie au supermarché (pain complet de préférence) ;
- fixer grossièrement ledit paquet sur son vélo ;
- perdre malencontreusement le paquet sur la route qui mène au camping ;
- se rendre compte de la perte dudit paquet juste avant de vouloir préparer les sandwichs du dîner ;
- finir par couper du gouda de la veille en allumettes puis les tremper dans du houmous industriel ;
- et voilà, j’espère que cette recette vous a plu, n’hésitez pas à la reproduire chez vous, bon appétit 😊

    À peine le temps de rencontrer le propriétaire du mini camping et de mettre en place notre tente qu'une averse s'abat sur notre logement. Une fois de plus, le mojo timing nous a sauvé de justesse. 

    On s'endort tranquillement dans ce camping cheap au son des gouttes de pluie glissant sur la toile de tente. 

    Bise

`
    },
    {
        index: 14,
        latLng: [53.272876, 5.603653],
        markerId: "day14-stjacobi",
        iconType: TentIcon,
        description: "Jour 14 - Makkum -> Harlingen -> Sint Jacobiparochie - 38 km",
        kms: 38,
        title: "Hissez les voiles pour St Jacques",
        features: {
            "warmshower": 1,
            "bières": 1,
            "café": 1,
            "jours de pluie": 1,
            "kms sans pédaler grâce au vent dans le dos": 5,
            "plats de pâtes": 1,
        },
        imagesName: ["PXL_20210728_092130889.jpg", "PXL_20210728_103140353.jpg", "DSC_3220.jpg", "PXL_20210728_110729512.jpg", "DSC_3227.jpg", "DSC_3230~2.jpg", "PXL_20210728_111910592.MP.jpg", "DSC_3234.jpg", "DSC_3238.jpg", "DSC_3245.jpg", "DSC_3252.jpg", "PXL_20210728_160239441.MP.jpg", "PXL_20210728_161425322.PORTRAIT.jpg", "PXL_20210728_164603776.MP.jpg", "PXL_20210728_165229349.MP.jpg", "DSC_3260.jpg", "PXL_20210728_175023080.jpg", "PXL_20210728_181814535.jpg", "PXL_20210728_182216613.jpg", "PXL_20210728_191008077.jpg", "DSC_3266.jpg", "PXL_20210728_212559597.jpg"],
        text: `
        Comme le disait le bon vieux Kiki Luther King lors de son réveil à 8h le 28 Juillet 2021 : « I have a dream : one day, our knees will be strong and will ride 50 km per day ». En effet, malgré plusieurs cachets d’Ibuprofène et crémages de Voltaren à notre actif ces derniers jours, nos genoux ne se sont toujours pas remis de leur traumatisme vent de face + dénivelé positif des dunes pré-Amsterdam. Néanmoins, l’évolution de la santé de nos genoux semblent aller dans le bon sens (du vent lol) depuis hier donc nous sommes mojo-contents. 
        
        Petit déj pris en évitant les gouttes, nous nous mettons en route pour cette journée qui s’annonce très venteuse. Heureusement, cette fois-ci, pour fêter nos 2 semaines de vadrouille, le vent est entièrement dans notre dos. Kiki rentre même dans le Guiness book des cyclistes en effectuant 4 km sur du plat sans pédaler à 20km/h de moyenne tout en atteignant des vitesses de pointe de 25km/h lors de grosses rafales (true story). Waya is estomaqued !
        
        Waya et Kiki deviennent de vrais météorologues en herbe. Grâce à leurs applications mobiles à la pointe de la technologie et à l’analyse des mouvements des anticyclones et dépressions, il leur est maintenant possible de prédire n’importe quelle averse n’importe où dans le monde dans les 2 prochaines heures. Ce savoir faire d’exception leur permet de planifier où et quand s’abriter dans la ville côtière de Harlingen. Nous tombons par hasard dans un énorme café-bar-brasserie possédant beaucoup de sex-appeal : gros canapés maxi confort, peu de monde, bières de ouf locales brassées sur place, chien du boss qui va chercher et ramène les sous-bock… Bref, on nous met tellement bien qu’on commande 2 bières, un hamburger et un hot-dok (blague de pêcheur hollandais).
        
        3 heures et quelques bières plus tard, Waikiki repart sur la route et innove avec des nouvelles techniques de vélo-à-voile grâce aux différentes bâches et toiles de tentes leur permettant de collecter et transformer cette énergie gratuite et écologique qu’est le vent de dos. Comme le dit le proverbe écrit sur les emballages plastiques des poivrons vendus à l’unité dans les supermarchés néerlandais : « be smart, buy green ».
        
        Nos 2 compères inventent même une nouvelle discipline : le bikekite. Kikite se spécialise dans la voile humaine avec son anorak pendant que Waya joue l’équilibriste sur son vélo tracté par sa couverture d'étanchéité pour bagage recyclée en voile. On fait le plein de sensations fortes et Kiki réalise même un de ses goal life : toucher un mouton hollandais. 
        
        À l'entrée de notre village de destination, Sint Jacobiparochie, on fait la rencontre hasardeuse de Kitty qui vend quelques kilos de prunes sur un petit stand devant chez elle. Nous voyant potentiellement intéressés par sa came, cette dame nous propose immédiatement de venir visiter son jardin pour nous montrer sa production de prune et ainsi nous prouver la qualité de son produit, ce que Waikiki accepte bien évidement sur le champ. Elle nous explique qu'elle et son mari, instits de profession, cultivent plus de 300 pruniers en tant que ''passe-temps'' dans leur jardin qu'ils ont racheté à un vieux du village il y a quelques années. Le jardin est grave stylé, les prunes ont l'air grave bonne, Kitty est grave le sang de la veine, le kilo de prune est grave pas cher (2,50€), nous n'hésitons pas une seule seconde à réaliser cet investissement qui changera peut-être notre vie à tout jamais. Nous sommes à 2 doigts de rester une semaine de plus dans ce village lorsqu'elle nous annonce qu'une collecte de prune aura lieu la semaine prochaine chez elle et que n'importe qui pourra alors venir dans son jardin cueillir les prunes au prix imbattable de 1€ le kilo. Sagesse Waikiki oblige, heure qui tourne, nous respirons un bon coup, nous récupérons notre sac de prune puis repartons direction notre objectif final situé dans le même village, notre hôte pour ce soir. 
        
        On arrive donc chez Jan un énorme Sancho fan de train qui a grandi et vécu toute sa vie dans la région. 
        
        Jan nous embarque pour un free tour magique en voiture autour de son village. De villages en villages, en passant par des moulins, des champs de patate, des digues et églises, il nous conte son histoire ainsi que celle de sa région où il vit, le Friesland. 
        
        La ville de notre hôte possède donc une église qui est l'un des nombreux points de départ du chemin pour rejoindre Saint Jacques de Compostelle. Cette expérience fait étrangement écho à notre rencontre avec Hanneke 2 jours plus tôt qui est allée à Santiago de Compostelle à vélo. Coïncidence ? Nous ne le pensons pas. 
        
        Par la suite, nous apprenons que la zone où nous nous situons était 500 ans plus tôt une partie de la "mer du milieu", mer qui a été transformée en terre grâce aux digues construites il y a une centaine d'année à la pelle (dans un but agricole (la moula)). 
        
        On finit par un sunset de dingue sur la digue avec les oiseaux (d'après Jan, de nombreux peintres habiteraient la région à cause de la ''special light'' que l'on retrouve ici au coucher de soleil). 
        
        En rentrant vers 22h, Jan nous prépare le dîner. Au menu : macaronis bien cuites accompagnées de bœuf et de légumes + danette vanille format family pack en dessert. Ce dîner est l'occasion de constater que notre hôte est un grand voyageur, il est allé tout seul jusqu'en Chine en prenant le trans-mongolien, un train de farci avec couchettes qui fait Moscou-Pékin en 7 jours avec de nombreux stops sur la route. 
        
        Après toutes ces histoires de voyage, nous finissons ENFIN notre « jour de repos » easy avec nos 37 petits km réalisés grâce au vent. 
        
        Go dodo.`
    },
    {
        index: 15,
        latLng: [53.412436, 6.260070],
        markerId: "day15-lauwersoog",
        iconType: TentIcon,
        description: "Jour 15 - Sint Jacobiparochie -> Lauwersoog - 59 km",
        kms: 59,
        title: "Invention du Bike-Kite",
        features: {
            "campings sauvages": 1,
            "kms sans pédaler grâce au vent dans le dos": 5,
            "vrais restos": 1,
            "sandwichs": 1,
        },
        imagesName: ["PXL_20210729_092604775.jpg", "PXL_20210729_092649420.jpg", "PXL_20210729_092908652.jpg", "PXL_20210729_093417055.jpg", "PXL_20210729_094723806.jpg", "PXL_20210729_112524073.jpg", "PXL_20210729_121036294.jpg", "PXL_20210729_123628253.jpg", "DSC_3270.jpg", "DSC_3275.jpg", "DSC_3277.jpg", "PXL_20210729_151059423.jpg", "PXL_20210729_151226966.jpg", "DSCPDC_0002_BURST20210729173006035_COVER.jpg", "DSC_3286.jpg", "PXL_20210729_174831456.jpg", "PXL_20210729_183437199.jpg", "PXL_20210729_190342678.jpg", "PXL_20210729_192635228.jpg", "PXL_20210729_193212466.jpg"],
        text: `
    Ce matin, levé 9h chez Jan dans notre petite chambre privative à l'étage. Nos genoux retrouvent peu à peu leur mojo d'antan, il nous est maintenant possible de monter des escaliers sans l'aide des bras. 

    Petit déj avec Jan puis reprise de la route vers 11h après avoir fait une photo souvenir avec notre hôte devant l'ancienne église du village, point de départ du chemin de Santiago de Compostelle.

    Avant de partir, nouveau passage obligatoire chez Kitty pour racheter 1 kilo de prune et faire le plein de vitamines. 

    À peine 2 km de parcourus, nous nous arrêtons dans la ville voisine, Sint Anna, pour acheter une nouvelle pile pour le compteur de Waya qui ne marche plus. Kiki en profite pour assouvir ses pulsions capitalistiques en achetant un sécateur ainsi que des gants de jardinage en annonçant : "Désormais, plus aucun buisson n'osera se mettre en travers de ma route lors de la mise en place du campement !". 

    Sur la route, on profite de tomber sur un stand de fruits et légumes bio (enfin !) pour acheter 5 tomates et 2 poivrons pour le prix très concurrentiel de seulement 2€. Après cette transaction de maître effectuée, nous remontons sur nos bikekite pour aller rider le vent. 

    Comme par hasard, au moment où nos ventres commencent à crier famine, nous tombons nez à nez sur un restaurant locomotive vendeur de pancake sortit de nul part. Ce train fait très étrangement écho à notre rencontre avec Jan hier, fan de train qui a voyagé à bord du trans-mongolien. Coïncidence ? Encore une fois, nous ne le pensons pas. À croire que nous vivons clairement le Trueman Show depuis notre arrivée aux Pay-Bas. 

    Pour continuer sur notre mojo lancée, Kiki tient à nouveau à nous préparer sa recette du pain perdue mais, cette fois-ci, en modifiant un peu les ingrédients... En effet, au moment de passer à la caisse, Kiki ne retrouve plus son portefeuille (technique classique), la tuile. Ce n'est qu'en allant checker son vélo stationné devant le restaurant qu'il retrouve finalement ledit portefeuille posé sur un de ses sacs. D’après la légende, le portefeuille aurait ridé plus de 10 km sans aucune pression posé sur son sac situé sur son porte bagage arrière (cf. photo ci-dessous). Damn ! Cette fois-ci, c'est bel et bien raté pour la recette du portefeuille perdu. Merci mojo ❤ ! 

    On passe notre journée à rider à la force du vent en slalomant entre les moutons. Le vent, bien plus énervé que la veille nous permet d'améliorer nos bikekite skills et tester de nouveaux tricks. 

    On finit notre trajet par un gros dénivelé de mojo positif en ridant une dizaine de km sur les grosses digues hollandaises à marée basse, 30 km/h, soleil tombant, oiseaux migrants, techno kiffante -> le turfu est à nous. 

    Vers 20h, on finit par trouver un spot de camping vandale proche de la digue et d'une zone militaire (challah on ne pose pas la tente sur une mine de WW2). Après avoir monté le camp, on se rend sur la digue afin de déguster notre sandwich traditionnel au gouda tout en appréciant un coucher de soleil de qualité supérieure. 

    À la nuit tombée, nous retournons à la tente puis on s'endort au calme bercé par le son des criquets, du vent caressant les roseaux ainsi que par tous les autres bruits chelous de la nature, comme dirait JUL, nous sommes "dans notre paranoïa".
            `
    },
    {
        index: 16,
        latLng: [53.323636, 6.697688],
        markerId: "day16-stedum",
        iconType: TentIcon,
        description: "Jour 16 – Lauwersoog -> Zoutcamp -> Stedum - 50 km",
        kms: 50,
        title: "A la Recherche de la Banane Perdue",
        features: {
            "warmshower": 1,
            "sandwichs": 2,
            "jours de pluie": 1,
            "verre de whishy": 1,
        },
        imagesName: ["DSC_3293.jpg", "DSC_3294.jpg", "DSC_3297.jpg", "PXL_20210730_085916364.jpg", "DSC_3300.jpg", "DSC_3301.jpg", "PXL_20210730_102652122.jpg", "DSC_3304.jpg", "DSC_3305.jpg", "DSC_3306.jpg", "PXL_20210730_130709246.jpg", "PXL_20210730_140330217.jpg", "DSC_3307.jpg", "PXL_20210730_144819765.jpg", "PXL_20210730_160644744.jpg"],
        text: `
    Levé à 9h après une bonne nuit de sommeil dans notre champ de mines. Aujourd’hui, c’est le retour du soleil grand ciel bleu (on meurt déjà de chaud à 9h30…) donc ça part en grosse crème solaire indice 50++. 

    On débute la journée par un petit ride champêtre dans les bois, puis dans le no man’s land de la zone militaire en croisant les doigts pour que personne ne nous tire dessus. Dans le pire des cas, Pikachu sera là pour nous protéger. On garde en tête notre objectif prioritaire qui est de trouver de l’eau potable ASAP ! En effet, suite à une mauvaise gestion de notre stock d’eau, nous sommes à sec depuis le réveil. Nous décidons donc de nous rendre à un camp de hippie repéré sur la vue satellite Google Maps dans un champ très proche de notre campement. En arrivant là-bas, personne, aucune tente, pas d’eau, pas d’âme, gros échec.

    Nous continuons notre quête et débarquons dans un mini village pommé sans bar ni café, Vierhuizen, où on fait la rencontre d’une meuf et ses 3 triplées qui, après une discussion de 10 minutes sur la génétique des triplés et des tremblements de terre aux Pays-Bas, nous indique qu’il n’y a pas d’eau dans ce village… Néanmoins, elle nous recommande de nous rendre au village voisin à 2 km de là, Zoutcamp (Camp de sel), pour trouver de l’eau et visiter la ville qui, apparemment est sympa. Nous remplissons finalement nos vessies 200 mètres plus loin au petit cimetière du village (astuce de cycliste expérimenté).

    On décide tout de même de se rendre au village recommandé pour aller manger des sandwichs au poissons (spécialité du village). En arrivant là-bas, nous tombons totalement hasardeusement sur 3 toureuses (filles qui voyagent à vélo) que nous avions rencontrés J8, il y a 7 jours, dans un mini camping. Après s’être émerveillé pendant 5 minutes de la microscopique taille de notre chère Terre, Waya décide soudainement de nous préparer sa master recette préférée pour le déjeuner : la banane perdue. Accrochez-vous bien…

    Pour préparer cette recette, c’est très simple, il faut :
- Se munir d’un sac de type « banane » pouvant s’accrocher autour de la taille ou en bandoulière (y ajouter quelques pins collector très stylés de préférence) ;
- Rassembler les objets suivants par ordre de priorité : une GoPro contenant des vidéos du voyage en cours, un Opinel, la seule et unique clef de votre antivol de vélo, des lunettes de vélo aérodynamique procurant une vitesse inégalée, des écouteurs filaires, un tube de Voltaren gel quasiment fini, une boussole servant à s’orienter dans les Catacombes de Paris, un Posca noir pour faire signer nos hôtes, un tube de crème solaire indice 30 ainsi que des restes de sandwich hollandais ;
- Mélanger tous les ingrédients ensemble et les introduire dans ladite banane ;
- Oublier malencontreusement ladite banane sur une botte de foin lors d’une pause pour mettre son pull car il fait froid ;
- Se rendre compte de la perte de ladite banane et retourner sur les lieux 30 minutes plus tard.

    Pour savoir si la recette est réussie, c’est très simple : si la banane ne se trouve plus sur les lieux, préparez vos papilles, ça risque d’être un délice. Par contre, si la banane est encore là, alors il vaut mieux retenter une 2ème fois dans une passage plus fréquenté, vous augmenterez vos chances de réussite.

    Verdict : le résultat est époustouflant, Waikiki déguste la meilleure banane perdue de sa vie.

    C’est un fait, après plusieurs minutes de recherche intensive et de de discussion avec les fermiers du coin, la fameuse banane est officiellement perdue. Enorme premier vrai dénivelé de mojo négatif en 2 semaines de voyage, c’est un coup dur pour Waikiki. C’est un grand classique dans la mojolife, en effet, lors d’enchainements d’expériences procurant de forte montée de mojo, il y a très souvent une redescente terrible. Waya, étant un fin connaisseur de la théorie mojo, s’était bien évidemment préparé à cela. Après avoir laissé des petites affichettes avec son contact proche du lieu maudit et respirer de grandes bouffées d’air pur introspectives, il finit donc par accepter sa destinée et réguler son mojo interne, le temps soignera le mojo, il en est convaincu.

    De cette erreur, nous apprenons une leçon très importante aujourd’hui : « il ne faut pas perdre ses affaires ».

    Cette perte de sex-appeal a mis nos 2 compères bien en retard par rapport à leur planning initial. Il est maintenant 16h et nous avons RDV à 18h chez notre hôte situé à plus de 30 km d’ici, d’autant plus qu’une tempête pas contente se dirige tout droit vers nous.

    S’en suit une course effrénée contre la montre et contre la tempête qui nous poursuit depuis plusieurs heures. A présent, plus de temps pour le slow travel, nous mettons le turbo et on fonce vers notre destination finale, la ville de Stedum, sans ménager nos genoux. Heureusement que Boris Brejcha (Bobo pour les intimes) est là pour nous booster et ouvrir nos chakras.

    Nous arrivons pile poil à l’heure avant la tempête chez Tes, une fille qui vit dans ce village depuis une dizaine d’années avec ses 2 enfants. Elle nous apprend que la région est touchée par de nombreux tremblements de terre causés par quelques entreprises capitalistiques envoyées par le diable qui forent, au péril de la vie des habitants locaux, la croûte terrestre à la recherche de gaz enfoui dans le sol. Il est donc fréquent de retrouver, dans cette zone, des maisons fragilisées et craquelées par cette activité satanique (et non pas sismique lol). Un gaz, qui soit dit en passant, participe au dérèglement climatique et, par extension, à la montée des eaux qui menace fortement le pays. Conclusion : ce pays s’auto-noie !!! (Ils sont fous ces Hollandais)

    Nous défions ainsi tout climato-sceptique à venir réfuter cette démonstration triviale.

    Bref, après moultes autres palabres, une douche, un dîner végétarien préparé par Tes, un verre de Whisky et une partie de « 6 qui prend », nous allons nous coucher pour en finir avec cette journée forte en mojo extrême.`
    },
    {
        index: 17,
        latLng: [53.442493, 7.308650],
        markerId: "day17-grossemeer",
        iconType: TentIcon,
        description: "Jour 17 - Stedum -> Delfzijl -> Emden -> Grosse meer - 41 km",
        kms: 41,
        title: "Arrivée au Pays des kartoffel",
        features: {
            "camping normal": 1,
            "sandwichs": 2,
            "jours de pluie": 1,
            "franchissements de frontière": 1,
            "café": 1,
            "frites": 1,
            "pots de miel": 1,
        },
        imagesName: ["PXL_20210731_065548665.jpg", "DSC_3311.jpg", "PXL_20210731_081433023.jpg", "PXL_20210731_092651660.jpg", "PXL_20210731_103918593.jpg", "PXL_20210731_104355132.jpg", "PXL_20210731_104434434.jpg", "DSC_3327.jpg", "PXL_20210731_112624007.jpg", "DSC_3329.jpg", "DSC_3331.jpg", "PXL_20210731_165751908.jpg", "PXL_20210731_180105776.jpg", "PXL_20210731_180121010.jpg", "PXL_20210731_181201841~2.jpg", "PXL_20210731_181342393.jpg", "DSC_3341.jpg", "DSC_3342.jpg", "PXL_20210731_194524724.jpg"],
        text: `
    Comme il est écrit dans la mojo bible : après la pluie, vient le bon mojo ou bien encore après le bad mojo vient le beau temps. Ce matin, un cocktail de mojo (moyo d'après la maman Kiki) explosif nous permet de rejoindre notre destination en un temps record, 1h soit 16 km/h de moyenne. 

    Voici la liste des ingrédients à mélanger pour concocter ce breuvage énergétique :
- un petit-déjeuner à base d'orange, pain et beurre de cacahouètes hollandais (son prix imbattable de 6€ par kilo en Hollande pour du 100% peanuts ainsi que son ratio énergie/poids hors norme fait de ce produit un ingrédient incontournable pour tout cycliste expérimenté, à consommer bien sur sans modération) ;
- un gros mix de 1h de raggatek (Mix Vandale de 2011 pour les fins connaisseurs de musique électronique) sur la boombox de Kiki ;
- quelques rayons de soleil inespérés (faille de beau temps spatio-temporelle située au milieu de la tempête hollandaise) ;
- un gros vent de dos des familles qui fait bien plaisir.

    Nous arrivons donc en avance à Delfzijl pour prendre le ferry à 10h50, bateau qui nous permettra de passer la frontière pour aller rejoindre l'Allemagne. Petite nostalgie non négligeable en quittant les Pays-Bas, ces terres plates, pays du vélo, des gaufres, des digues, de l'emballage plastique et des mojo personnes que nous avons rencontré tout au long de notre chemin. À nous les apfelstrudel, les kartoffel, les sandales chaussettes, le club maté, la techno et les saussices en tout genre.

    En faisant notre entrée dans le pays, on regrette rapidement les Pays bas... On est accueilli par la pluie et sans piste cyclable, on a trop été trop habitué aux standards qualitatifs hollandais. 

    On trouve rapidement une petite baraque à poisson frais où on achète des sandwichs froids au poisson frais (c'est fraiment très vrai) et un fish and chips chaleureux sauce rémoulade qui réchauffe fraichement nos cœurs endeuillés. 

    On se réfugie ensuite dans un café ultra stylé en dehors du centre-ville, Einstein (smart move), où on se pose enfin dans un canapé, au chaud, au soleil, en sirotant nos boissons chaudes caféinées et en dégustant des bons gâteaux frais faits maisons. Cette douce pause ensoleillée nous permet de remettre nos pendules à l'heure et faire le point à chaud sur nos existences. Waya en profite même pour siroter son premier Club Maté glacé ❤ (dédicace à Anthon Die Blandtun tu cocos) avant de repartir à 18h. Le mojo est définitivement de retour, il n'y a pas de doute. 

    Petit fun fact : Kiki a choppé un rhume en été, improbable, à croire qu'il frais vraiment fait ici. 

    Nous essayons d'appeler des campings allemands dans la zone, mais impossible d'établir une communication potable en anglais avec qui que ce soit dans ce pays. 

    Après 5h de pause revigorante bien méritée dans le café, nous décidons donc de reprendre la route pour se rendre directement au camping situé à 15 km afin d'établir un contact face to face en langage des signes. 

    On quitte alors rapidement la ville pour se retrouver perdu dans la campagne allemande à slalomer entre les limaces. En l'espace de quelques minutes, nous croisons toute la bassecour : chevaux, vaches, lapins, souris, canards, oies, oiseaux en tout genre et même 2 bambis furtifs. Le paysage a bien changé en a peine quelques kilomètres de frontière.

    On arrive au camping vers 21h et on réussit enfin à établir un contact primitif avec le boss : ''guten tag, 2 people, 1 tent, 1 night, how much?''. Le voyant jouer avec ses doigts pendant 2 minutes pour essayer de nous indiquer tant bien que mal un montant à 2 chiffres, nous l'aidons en lui donnant un téléphone portable pour écrire sa pensée. 22 euros nous écrit t-il, ''das gut das goute, file danke'' répond Waikiki qui n'avait de toute façon plus aucune autre solution pour ce soir vu le late timing. On lui donne des sous et il nous donne des petits oursons Haribo en échange (peut-être une tradition allemande...).

    Nous faisons enfin notre première rencontre avec un allemand civilisé parlant la langue de Shakespeare, Félix, qui, comme par hasard encore une fois, est de la génération 94 comme nous -> énorme sancho de la vega. Ce mec de Dortmund en vacance voyage tout seul à vélo pour la première fois, il a déjà plus de 300 km dans les pattes. Il nous file plein de trucs et astuces d'allemand pour survivre sur ce territoire hostile.

    Comme l’avait bien entendu prédit Waikiki, la pluie arrive. Nous partons donc vite se réfugier sous le seul abri du camping avec nos vivres pour passer au dîner pique-nique sur la seule table. Il se trouve que l'abri de la maison où nous nous abritons est aussi la salle de jeu (spielhaus) et aussi le QG des jeunes allemands de 15 ans qui font une boom baby-foot là-bas. Étant très étonnés en nous voyant manger notre poivron cru comme une pomme, ils nous offrent des oursons Haribo, (étrange tradition allemande...). Waya leur fait même découvrir du rap allemand.

    Après cet échange culturel intense et ce dîner, nos 2 confrères partent prendre une douche puis se font même le luxe de regarder un film dans la tente sous la pluie : le Truman Show ❤ ce film nous permettra d'en apprendre un peu plus sur la Truman show life pour le reste de notre périple.
            `,
    },
    {
        index: 18,
        latLng: [53.605118, 7.138645],
        markerId: "day18-norddeich",
        iconType: TentIcon,
        description: "Jour 18 - Grosse Meer -> Norddeich - 34 km",
        kms: 34,
        title: "Good Morning Felix",
        features: {
            "camping normal": 1,
            "sandwichs": 1,
            "poulets rotis entiers": 1,
            "jours de pluie": 1,
            "pots de beurre de cacahouètes": 1,
        },
        imagesName: ["DSC_3343.jpg", "DSC_3345.jpg", "PXL_20210801_125730880.jpg", "DSC_3346.jpg", "DSC_3354.jpg", "PXL_20210801_163117930.jpg", "PXL_20210801_164930308.jpg", "PXL_20210801_175648544.jpg", "DSC_3356.jpg", "PXL_20210801_184102833.jpg", "DSC_3360.jpg", "DSC_3362.jpg", "PXL_20210801_200552641.jpg"],
        text: `
    Ce matin, grasse mat' stoppée à 9h45 par notre poto Félix qui nous informe qu'il y a un camion à croissants devant l'accueil qui ferme à 10h. On se dépêche donc d’aller au camion avant la fermeture avec tout l'argent qui nous reste à savoir la modique somme de 1,30€. Le gars du camion qui ne prend bien sûr pas la carte (il va falloir s'y habituer dans ce pays apparemment), il nous montre ce qu'on peut se payer avec nos économies : 5 bouts de pain ou 1 croissant. Notre choix est vite fait, nous partons sur le croissant Deutsch, le gars a tellement pitié de nous qu'il nous offre un 2ème -> petite montée de mojo matinale.

    On passe donc au petit dej, où Félix nous fait découvrir du miel avec de la cire d'abeille qu'il a acheté dans une ferme -> excellent et très bon pour notre karma intérieur. Kiki mélange cela avec un peu de Doliprane car il ne se sent pas très bien depuis le réveil, mal de tête + courbature (challah ce n’est pas un mauvais virus…). 

    On reçoit une notification de notre application de météorologue pro nous indiquant qu'une pluie diluvienne va s'abattre sur nous dans 30 minutes. Puisque l'on a 30 minutes, on passe les 20 premières à finir tranquillement le petit dej pour ensuite s'acharner à tout ranger dans les 10 minutes restantes. On se réfugie pile poil à temps sous l'abri avec toutes nos affaires. Cette heure de pluie nous permet d'améliorer notre allemand en discutant pokémon avec un crew d'enfants d'environ 10 ans (pumeluff = rondoudou).

    Il se trouve que nous allons dans la même direction que notre nouveau poto Félix donc nous décidons de faire un bout de chemin ensemble et partons vers 13h après avoir dégusté un sandwich au poisson frit offert par notre nouveau fraté au bistrot du camping.

    Sur la route, notre nouveau compagnon, fumeur de cigarette sur son vélo, nous apprend qu'il y a plusieurs endroits dans le Nord de l'Allemagne où il est possible d'acheter directement les produit de la ferme sur place (appelés Hofladen), tout ce que nous kiffons. Ces endroits contenant beaucoup de mojo sont des petites cabanes en bord de route accessibles par tout le monde. Tous les produits de la ferme sont présents à l'intérieur (miel, charcuterie, lait, œufs, légumes, confiture...) et pour les acheter, il suffit de mettre ses économies dans une tirelire laissée à disposition -> concept très stylé.

    Ce trajet en vélo est l'occasion d'apprendre les basiques en allemand : '' Wo ist grosse und gut döner ?'' = ''Où se trouve un gros et bon kebab ?''. Pendant le trajet, nous décidons finalement de suivre notre nouvel ami jusqu'à sa prochaine destination, un camping situé sur la côte proche de Norden à 25 km de là.

    En arrivant au camping, on agrandit rapidement notre gang en faisant la rencontre de Annika et Siggy, nos voisins de tentes qui se rencontrent également pour la première fois. Siggy à 34 ans et ride son pays en vélo électrique (plus de 100 km par jour et 500 km au compteur). Annika, qui vient de Hanovre, vient de finir le lycée et a décidé de partir seule ce week-end sur un coup de tête pour passer le week-end à la mer et faire du kitesurf.

    Notre dîner avec nos potos allemands est l'occasion d'inaugurer enfin notre réchaud après déjà 18 jours de trépidantes aventures. Kiki nous prépare donc une infusion camomille gingembre que nous dégustons avec notre poulet rôti acheté sur le chemin (tarpin bon). 

    Après ce festin, nous partons en vélo vers 21h avec notre (grosse) gang voir la mer et le sunset à quelques km de là car Félix tient absolument à se baigner. En effet, c'est la fin de son grand pèlerinage, il a atteint son objectif ultime qui était d'arriver jusqu'à la Mer du Nord en vélo, il se met aussitôt en maillot et court tout droit vers l'horizon pour enfin se jeter dans cette mer qu'il a tant convoité (gros moment émotion), Annika en profite pour le suivre. On rentre au camping car ils ont froid (assez prédictible quand on se baigne dans la mer du Nord à 21h le soir) puis on tape des barres avec quelques parties de 6 qui prend avant d'aller se coucher dans notre tente. 

    De quoi sera fait demain ? Aucune idée pour l'instant. La suite dans le prochain épisode.
        `,
    },
    {
        index: 19,
        latLng: [53.698783, 7.805354],
        markerId: "day19-carolinensiel",
        iconType: TentIcon,
        description: "Jour 19 - Norddeich -> Carolinensiel - 53 km",
        kms: 53,
        title: "Good Bye Felix",
        features: {
            "camping normal": 1,
            "sandwichs": 2,
            "jours de pluie": 1,
            "pots de voltaren achetés": 1,
        },
        imagesName: ["IMG-20210802-WA0004.jpg", "PXL_20210802_073900571.jpg", "DSC_3364.jpg", "DSC_3365.jpg", "DSC_3366.jpg", "DSC_3370.jpg", "DSC_3380.jpg", "DSC_3383.jpg", "DSC_3384.jpg", "DSC_3392.jpg", "PXL_20210802_160718010.jpg", "original_1eba7ac6-a492-4fc4-975b-9d4947036f57_PXL_20210802_161224498.jpg", "PXL_20210802_161445037.jpg", "original_7a3c7274-9795-483a-9d64-ad775dcab502_PXL_20210802_161606003.jpg", "DSC_3400.jpg", "PXL_20210802_191026942.jpg", "PXL_20210802_191039882~3.jpg"],
        text: `
    Réveil tranquille au camping vers 10h après une bonne nuit de sommeil réparatrice. On prend notre petit déjeuner sur la butte de gazon devant notre tente (gazon tant apprécié par Waikiki qui a pu se rouler dessus la veille en arrivant au camping). 
    
    Après une petite séance bronzette, 2-3h chill à discuter avec notre poto ainsi que le rangement du camp nous partons vers 13h pour aller déjeuner en ville à un petit restaurant de sandwich au poisson recommandé par Félix, endroit qu'il affectionne tout particulièrement car il y est allé avec ses parents étant petit (poisson frit de Proust).
    
    Ce n'est pas sans peine que nous quittons notre premier vrai compagnon de voyage, Félix, 94 rpz, qui nous aura guidé et appris tant de chose lors nos premiers jours en Allemagne. Il repartira chez lui demain en train après une journée de chill bien méritée face à la Nordsee (partie de la mer allemande à l’ouest du Danemark (mer du Nord). A l’est, ils appellent ça la Ostsee (mer Baltique)). Tchuss collègue et bonne continuation ❤
    
    Après notre délicieux poisson frit, nous reprenons le ride sur la côte Nord avec un gros vent dans le dos accompagné d’ambiance musicale à base de soundtrack de Tenet + album anthologique « Maison » de Salut c'est cool (les vrais savent). Ce combo explosif nous permet de rider avec un rythme effréné sur la digue allemande.
    
    Après les genoux en début de séjour, c’est maintenant au tour des fesses de montrer des signes de faiblesse (poste goulet pour les potos en prod). En effet, il semblerait qu’elles n’apprécient pas trop les 5000 tours de pédale par heure, 3-4 heures par jour, tous les jours et elles nous le font bien ressentir. Ce sont en partie elles qui nous imposent de nous arrêter pour se reposer. Nous écoutons nos corps d’athlètes et décidons de nous arrêter pour recharger les batteries sur un banc spirituel face à la mer au sommet de la digue (dédicace à Loulou et Juju, vous coco) avec en bonus un double arc-en-ciel posé dans le ciel.
    
    Pour la 3ème fois d’affilé, ça sera camping allemand ce soir (nous aimerions bien replanter la tente en mode sauvage mais c’est quasi-impossible sur cette partie de la côte entre champs, digue et mer...). Après 50 km dans les pattes, on débarque à 19h au camping où on rencontre notre voisine de tente, Pia, une dame de 55 ans, sancho de la veine, fan de Pokémon et Warcraft qui passe ses vacances ici et qui nous explique le fonctionnement de ce camping.
    
    On fonce dans la cuisine pour déguster le menu de ce soir composé à base des restes : fromage, pain et salade de pois-chiche. Kiki nous prépare à nouveau une savoureuse infusion camomille parsemée de carpaccio de gingembre découpé au sécateur (d’après lui, la coupe au sécateur améliorerait grandement l’extraction des molécules gustatives).
    
    Une micro pause entre 2 averses nous permet de nous réfugier sous la tente pour aller dormir.
    
    Minuit, bonne nuit.
        `
    },
    {
        index: 20,
        latLng: [53.494775, 7.963277],
        markerId: "day20-sande",
        iconType: TentIcon,
        description: "Jour 20 - Carolinensiel -> Sande - 37 km",
        kms: 37,
        features: {
            "warmshower": 1,
            "sandwichs": 1,
            "jours de pluie": 1,
            "bières": 1,
        },
        imagesName: ["IMG-20210803-WA0004.jpg", "PXL_20210803_090718702.jpg", "PXL_20210803_090757390.jpg", "DSC_3401.jpg", "DSC_3402.jpg", "DSC_3403.jpg", "PXL_20210803_140540770.jpg", "PXL_20210803_140703315~2.jpg", "PXL_20210803_140720813.jpg", "PXL_20210803_143236221~2.jpg", "PXL_20210803_171141202.jpg", "PXL_20210803_174449010.jpg", "PXL_20210803_191243848~2.jpg"],
        title: "Allo Maman J'ai froid et je suis Mouillé",
        text: `

        Après 20 jours d'aventure, nos 2 survivants Waya et Kiki ont passé la pire nuit de tout leur périple. Ils sont en partie mouillés (apparemment la tente n'est qu’à moitié étanche 😕) et ils n'ont encore jamais eu aussi froid durant la nuit malgré le fameux combo pull + survet + chaussettes + position fœtus mode sarcophage égyptien dans le duvet. Grosse gueule de bois comme après une grosse soirée techno énervé. 

        Au détour de 2 pets de tente, on peut même entendre Kiki Luther King marmonner quelques paroles remplies de sagesse : ''I had a dream, one day, the sky will stay blue at least during a full entire day''. 

        Bonne nouvelle en constatant, à notre réveil, que nous ne sommes pas les seuls dans la galère, tous les campeurs sans exception sont en train de faire sécher leurs affaires et leurs tentes grâce aux quelques rares rayons de soleil furtifs du matin. Rayons qui ne durent pas puisqu'il se met à pleuvoir une heure plus tard.

        Nous décidons donc de profiter de ce temps de crotte pour effectuer des smart moves tels que recharger nos équipements électriques, manger nos derniers restes ainsi que laver et (mal) sécher nos habits à la machine.

        Pia aka (''also known as'' pour les boomers, '' plus communément appelé'' pour les giga boomers) ''314a_lol'' (son blaze, devinette pour les plus chauds) qui est là depuis quelques jours déjà, nous explique qu’elle est venue dans ce camping pour tester la vie dans les alentours et éventuellement venir s'y installer dans un futur proche. La pluie ne semble pas l'avoir perturbée plus que ça puisque, maintenant, c'est quasi sur, elle veut vivre ici. Nous apprenons également que notre nouvelle amie, qui vient de Brême, fan de métal, détient une collection des plus originales : elle collectionne les personnes. En effet, depuis 1981, elle laisse son journal intime aux personnes qui croisent son chemin pour qu'ils laissent une petite trace à l'intérieur. 

        Une fois nos machines terminées et notre trace inscrite à jamais dans le journal de Pia, nous la saluons puis partons sur la route à 14h à la recherche d’un endroit où déjeuner. On finit par aller dans un petit supermarché pour acheter du pain et du fromage comme on sait bien le faire.

        Aujourd'hui, c'est trantille, seulement une trenquaine de kilomètres pour atteindre notre destination finale. Tranquille seulement en apparence, car nos muscles et nos fesses ressentent rapidement que la nuit n'a pas du tout porté conseil, bien au contraire, au bout de seulement 20 km nous sommes déjà au bout du pédalo.

        Avec toutes ces histoires de camping mouillé, de machine à laver et de pluie culottée, nous avons pris un retard monstre sur notre planning aujourd'hui, nous arrivons chez notre hôte à 19h30. Ce soir, c'est Bernd qui nous prête un bout de son magnifique jardin pour poser la tente. Il a déménagé de la ville à la campagne pour plus de calme en achetant cette maison il y a 5 ans. Quelques travaux lui ont permis de transformer le jardin en havre de paix qui était quasi une décharge à l'origine. 

        Nous ne sommes pas les seuls guests ce soir, un couple d'instits français grave cool, Véronique et Régis, qui vient des Vosges, est également là aussi. Ils font un voyage à vélo depuis leur maison pour se rendre à Hambourg, là où 2 de leurs enfants habitent. Nous dînons donc tous ensemble autour de 2 délicieux plats préparés par Bernd avec les légumes de son potager (blettes, chou rave, patates... ) -> on se régale. 

        Bernd travaille à quelques km de là en tant que responsable pour la réserve naturelle côtière (Friesland) de cette région d'Allemagne, réserve naturelle très importante pour la biodiversité car elle voit passer environ 30 millions d'oiseaux par an (locaux et migrateurs). 

        Grâce à lui, nous comprenons enfin le fonctionnement des digues et canaux que nous croisons sur notre chemin depuis notre entrée aux Pays-Bas (cf. J4). À l'inverse du sud de la France où l'eau douce manque terriblement en été, ici, il y en a trop (issue en grande majorité des pluies), il leur est donc nécessaire de pomper de l'eau depuis l'intérieur du pays vers la mer car le niveau de la mer est supérieur à celui des canaux (crazy world). 

        Après un bon dîner, nous retournons à notre tente pour nous préparer au dodo et, cette fois-ci tôt, car le petit déjeuner est à 7h demain, gros challenge pour Waikiki. ''Cette fois-ci, le froid ne nous aura pas !'' s'exclame Kiki en enfilant son 3ème T-shirt emmitoufflé dans son duvet tel un grand pharaon égyptien.

`
    },
    {
        index: 21,
        latLng: [53.643518, 8.496468],
        markerId: "day21-wreven",
        iconType: TentIcon,
        description: "Jour 21 - Sande -> Bremerhaven -> Wremen - 77km",
        kms: 77,
        title: "La rencontre avec nos Doubles du Futur",
        features: {
            "camping normal": 1,
            "sandwichs": 1,
            ferry: 1,
        },
        imagesName: ["IMG-20210804-WA0001.jpg", "PXL_20210804_080152496.jpg", "DSC_3413.jpg", "PXL_20210804_093022250.jpg", "DSC_3415.jpg", "DSC_3416.jpg", "DSC_3418.jpg", "PXL_20210804_123309727.jpg", "PXL_20210804_134549461.jpg", "PXL_20210804_141914733.jpg", "DSC_3419.jpg", "PXL_20210804_170509403~2.jpg", "DSC_3426.jpg", "PXL_20210804_175354754.jpg", "DSC_3434.jpg", "DSC_3437.jpg", "PXL_20210804_191920159~3.jpg", "PXL_20210804_192332279.jpg", "DSC_3439.JPG"],
        text: `
        Malgré les précautions prises par Waikiki cette nuit en termes de couche supplémentaires et de positions fœtus, nos 2 amis ont, une fois de plus, eu froid... 

       ''Vazy cest quoi ce delir frer !? Depui quant il fait 12 degrés la nuit en été com sa !? Ça rend fada, cass les couilles maintenant, ma bandé ce voyage de sgeg!'' ronchonne Waya au réveil. Nos 2 amis ne se sont définitivement pas préparés à ce froid polaire avant de partir et ils ne sont pas équipés pour avec leurs 2 pauvres duvets d'été +15°C. Ils sont même à 2 doigts d'envisager de passer en formation cuillère pour leur propre survie (#brokebackmountain). Pour cela, il faudra, au préalable, en discuter avec leurs copines respectives bien sûr. 

        Comme prévu, nous prenons un gros petit déjeuner régénérateur avec notre hôte et nos amis français vers 7h.

        Comme le radote si souvent Waikiki : ''C'est en vivant des moments complexes qu'il est possible de kiffer encore plus les moments simples''. En effet, seulement quelques minutes passées sur un banc au soleil en sirotant une petite infusion gingembre suffisent pour remonter la barre de mojo au max avant de partir. 

        En début de parcours, nous achetons 500 g de myrtille et une grosse courgette posés sur un petit étalage devant une maison (des genre de micro Hofladen, la folie, cf. J18) pour le prix imbattable de 4 euros. 

        Sur la route, nous faisons la rencontre de nos doubles venant tout droit du futur : 2 vieux allemands à vélo, équipés comme jamais, 2 sacoches à l'avant, 2 sacoches à l'arrière, matériel pro, nous reconnaissons tout de suite le style parfait du toureur expérimenté. Ces 2 fratés de 70 ans nous expliquent que ça fait 20 ans qu'ils se font des gros voyages à vélo tous les 2 en mode bestou, une fois tous les 2 ans environ, c'est vraiment des vieux de la vielle. C'est décidé, nous voulons devenir comme eux, nous avons enfin trouvé nos modèles de vie. Mais pour cela, il va falloir travailler dur, nous n'y sommes pas encore... Allez, on reprend la route... 

        Vers 14h, on commence à avoir faim, on s'arrête donc à 38 km dans un Hofladen (une mini boutique où on peut acheter les produits de la ferme, cf. J18) pas loin de la piste cyclable pour se recharger en fromage et saucisson frais qu'on déguste sur le pouce. Nous n'avons jamais ridé autant avant le déjeuner et ça, c'est grâce au lever à 7h du matin, il n'y a pas de doute : ''le mojo appartient à ceux qui se lèvent tôt''. 

        À 60 km, nous arrivons à Blexen où nous prenons un petit ferry pour aller à Bremerhaven. Après quelques courses effectués dans le centre-ville vers 18h (nous redevenons enfin civilisés en rachetant l'objet le plus important du campeur expérimenté : un opinel perdu il y une semaine, cf. J16), nous sommes anormalement toujours en forme olympique et nous décidons d'essayer d'exploser notre record actuel en allant plus loin que prévu. Nous visons donc un camping situé à une 20aine de km au Nord, à Wremen, camping recommandé par nos vieux fratés rencontrés plus tôt sur la route. D'autant plus qu'il y a des chances qu'ils se rendent là-bas, occasion parfaite de les recroiser et d'en apprendre un peu plus sur la vie de toureur. 

        Nous arrivons tranquillement à notre objectif final sans trop de problème vers 20h, en ayant ridé au sommet de la digue accompagné du soleil tombant et de musique chill. Résultat final : record explosé avec 77 km au compteur et plus de 5 heures assis les culs entre 2 selles !!! Nos genoux sont à présent plus forts que jamais. 

        On se récompense au plus vite en allant déguster nos produits de la ferme face à un coucher de soleil HD 4K. 

        Vers 22h, juste avant d'aller se coucher, un homme mystérieux nous interpelle soudainement devant notre tente dans le noir : ''Hey, that's my French friends guys, sai la no ami franssé !''. C'est 1 de nos 2 vieux poto croisés sur la route ! On papote un peu technique avec lui avant d'aller se coucher et enfin clore cette journée sportive riche en émotions !
        `
    },
    {
        index: 22,
        latLng: [53.821297, 8.590241],
        markerId: "day22-cuxhaven",
        iconType: TentIcon,
        description: "Jour 22 - Wremen -> Cuxhaven - 24km",
        kms: 24,
        title: "Voyage au Centre de la Ruche",
        features: {
            "warmshower": 1,
            "jours de pluie": 1,
            "frites": 1,
            "café": 2,
            "bières": 1,
        },
        imagesName: ["PXL_20210805_074123540.jpg", "PXL_20210805_112055566.jpg", "DSC_3442.jpg", "DSC_3444.jpg", "DSC_3446.jpg", "PXL_20210805_121008661.jpg", "PXL_20210805_125233469.jpg", "DSC_3447.jpg", "DSC_3450.jpg", "DSC_3455.jpg", "DSC_3458.jpg", "PXL_20210805_170147996.jpg", "DSC_3460.jpg", "DSC_3461.jpg", "PXL_20210805_171121760.jpg", "DSC_3463.jpg", "DSC_3464.jpg", "PXL_20210805_184427047.jpg", "DSC_3470.jpg", "PXL_20210805_213622537.jpg", "PXL_20210805_213957471.jpg", "PXL_20210805_220333853.jpg", "PXL_20210805_220949631.jpg"],
        text: `
    Cette fois-ci, nous passons ENFIN une nuit plutôt correcte, le froid n'a quasiment pas réussi à pénétrer nos sarcophages respectifs. Malgré nos 2-3 micro courbatures, la journée record d'hier nous a redonné confiance ! On est chauds pour la suite.

    Nos potos doubles allemands étant des toureurs expérimentés, ils partent bien entendu tôt vers 10h ce qu'ils qualifient d'ailleurs de ''en retard''. On les salue puis, en tant que bons toureurs amateurs, nous profitons du retour du soleil pour effectuer une grosse heure de dorage de pilule et de charbonnage du blog.

    Nous partons finalement du camping à 13h après avoir rangé nos affaires et mis de la crème solaire.

    Sur la route, nous nous retrouvons bloqués au milieu d'un champ en sandwich avec, au Sud, un orage menaçant à nos trousses et, au Nord, une centaine de vaches enragées au milieu de clôtures barbelés. Après s'être pris une décharge foudroyante venant tout droit de la clôture qui s'avère être électrique, Kiki, fou de rage, se dirige tout droit sur la trentaine de vache nous encerclant et réussit à les faire fuir. 

    Suite à cet acte héroïque, nous nous dépêchons de nous réfugier dans un café de la ville côtière la plus proche pour y déguster des gâteaux réconfortants ainsi que notre premier Currywurst et Apfelstrudel (mauvaise entrée en matière car les 2 n’étaient vraiment pas ouf, il semblerait qu’ils soient bien meilleur en poisson sur la côte allemande).

    Après l'orage qui n'est finalement jamais arrivé (légère erreur de calcul de la part de Waikiki), nous partons vers 17h en direction de la campagne où se trouve la maison de notre prochain hôte, Carlsen, « The olympic beekeeper ».

    En arrivant chez lui, il nous fait visiter sa maison, ancienne maison de vacances, où il habite depuis maintenant 19 ans. Carlsen est un passionné d'abeilles, il les dresse depuis qu'il est tout petit pour récolter leur précieux nectar. Ses parents, qui récoltaient plus de 800 kilos de miel dans les années 50, lui ont refilé ce virus royal. Il récolte le miel de sa petite dizaine de ruches presque tous les ans (une centaine de kilo en moyenne au total) puis les vend sur un petit étalage en libre-service devant sa maison, tout ça en tant que ''passe-temps'' nous indique-t-il, ''passe-temps'' qui prend du temps, mais le temps ne compte pas quand on est passionné comme lui.

    Notre hôte possède 5 ruches qu'il met à disposition d'un fermier dans un champ de colza (miel de colza = rapshonig) + quelques ruches dans son jardin.

    Nous ne sommes pas les seuls guests, il y a aussi Robert, un allemand de 30 ans qui bosse dans une banque à Berlin et qui a pris un congé sans solde de 11 semaines pour rejoindre l'Espagne à vélo (micro burnout). C'est son premier gros trip à vélo et seulement son deuxième jour de voyage donc on fait clairement les anciens à côté de lui avec nos 800 km dans les pattes. 

    Notre hôte nous amène tous boire un coup avec les abeilles à côté des ruches, nous en profitons pour lui poser un milliard de questions sur la routine de ces précieux insectes. 

    Waikikipedia : en été, 40 à 50 mille (8 à 10 mille en hiver) abeilles travaillent 8h par jour (un peu comme nous en fait, 8h chill + 8h boulot + 8h dodo) dans une ruche. Contrairement à nous, les abeilles taffent à la récolte de la moula seulement pendant les 3-4 derniers jours de leur courte vie de seulement 30 à 42 jours et, tout ce travail pour récolter seuelement une petite cuillère de miel par abeille soit environ une vingtaine de kilo par ruche. La reine, elle, vivra environ 4-5 ans car elle est nourrie avec du bon produit de qualité supérieur : la fameuse gelée royale. Quelques jours avant la fin de la vie de la queen, les abeilles se prépare au couronnement de la prochaine : 10 à 20 larves sont nourries à la gelée royale pendant les premiers jours de leur vie. Une fois ces larves devenues abeilles, elles effectuent un gros battle royal (lol) jusqu’à qu’il n’en reste qu’une -> la nouvelle reine.

    Bref, après cet instant très instructif, Carlsen nous prépare un délicieux dîner avec beaucoup d'attention, la recette typique du Borsch froid (sorte de soupe de betteraves des pays de l'Est) version Lettonie avec des raviolis, œufs, olives, crème fraîche, la foliiiie. 

    À la fin de ce festin, Carlsen nous propose de lui donner un coup de main avec la récolte du jour, ce que nous acceptons sur le champ of course (mojo life de ouf). En effet, nous tombons à pique car la récolte du miel se fait en Juin-Juillet. Nous nous transportons donc, le temps d'un instant, dans la peau d'un apiculteur allemand expérimenté à travers les différentes étapes de récolte du miel :
1ère étape : récupérer les racks dans les ruches où les abeilles ont stocké du miel pour l’hiver. (d’autres racks de cellules sont dédiées au développement des larves)
2ème étape : ouvrir toutes les cellules avec un outil spécial, un genre de peigne (les abeilles ont recouvert les cellules d’une pellicule de cire pour les rendre complètement hermétiques afin de mieux conserver le miel au cours de l’année) 
3ème étape : vérifier la qualité du miel en mesurant le pourcentage d’eau contenu dans celui-ci grâce à un réfractomètre : le miel doit contenir moins de 18% d'eau.
4ème étape : passer les racks à la centrifugeuse pour extraire le miel des cellules (en tant que bon beekeeper qui se respecte, il faut en laisser un peu (10%) pour les abeilles)
5ème étape :  filtrer le miel avec 2 filtres de taille différente pour éliminer les morceaux de cire restants.
6ème et dernière étape : mettre en pot et coller les étiquettes.

On finit notre taff vers 1h du matin après avoir récolté presque 20 kilos de moulaga. Couché 2h du matin. Malheureusement, demain, c’est levé 7h pour pouvoir chopper le ferry. Nos 2 aventuriers arriveront-ils à relever ce défi de taille ? La suite dans le prochain épisode.


        `
    },
    {
        index: 23,
        latLng: [53.78806200627762, 9.42384345160424],
        markerId: "day23-gluckstadt",
        iconType: TentIcon,
        description: "Jour 23 - Cuxhaven -> Bronsbuttle -> Glückstadt - 46km",
        kms: 46,
        title: "Comme un Phoque sous la Pluie",
        features: {
            "warmshower": 1,
            "jours de pluie": 1,
            ferry: 1,
            "sandwichs": 1,
            "pots de miel": 2,
            "jours en vent de face": 1,
            "plats de pâtes": 1,
            "bières": 1,
        },
        imagesName: ["DSC_3477.jpg", "PXL_20210806_072159436.jpg", "DSC_3479.jpg", "DSC_3483.jpg", "DSC_3493.jpg", "DSC_3496.jpg", "DSC_3504~3.jpg", "DSC_3505.jpg", "PXL_20210806_125732702.jpg", "PXL_20210806_130345588.jpg", "PXL_20210806_141422968.jpg", "PXL_20210806_143243709.jpg", "DSC_3519.jpg", "DSC_3522.jpg"],
        text: `
    En se levant seulement 5 heures plus tard momifié à 7h du matin, Waya fait la découverte hasardeuse d'un précieux tombeau qui s'avère être, après investigation, celui du légendaire Neferkiki, disparu il y seulement 5 heures.

    Nous dégustons tous ensemble un petit-déjeuner de grand sportif à base de porridge, fruits et MIEL bien sûr. Cet apport d’énergie non négligeable ainsi qu’un traditionnel mix de raggatek nous permettent de rejoindre notre ferry à Cuxhaven à 9h30 en un temps record (17 km/h de moyenne, vent de face).

    En arrivant de l’autre côté de l’Elbe, nous traversons une grosse zone industrielle style Mordor où nous ridons entre les centrales à gaz et même une centrale nucléaire (une des dernières en exploitation) sous les crépitements des lignes haute tension au-dessus de nos têtes. Sur la suite de notre trajet, nos jambes prennent tarif, un gros vent de face nous contraint à rouler à une vitesse moyenne de 10 km/h, dur.

    Sur le bord de la rivière, on fait soudainement la rencontre avec un animal rare, majestueux et poilu : un phoque de Hambourg en train de chiller fort sur le sable. Inspirés, Waya et Kiki décide de chiller pendant quelques instants avec leur nouveau poto sur la plage. 

    Cette pause animalière nous met finalement en retard par rapport à l'orage énervé qui arrive tout droit sur nous, au Sud, où se trouve notre direction finale. Comme Waikiki trouve que le retard accumulé n’est pas assez important, ils décident de s’arrêter dans un Hofladen pour faire le plein de produits de la ferme (pâtes, œufs, courgettes et tomates). Cette fois-ci, il n’y a plus le choix, planification météo ou pas, nous ne pourrons pas éviter la tempête.

    En tant que bons aventuriers qui se respectent, nous enfilons nos k-ways et nous décidons de foncer tout droit sur cet orage menaçant en mode Sharknado. La réalité nous rattrape rapidement lorsque, 2km avant l'arrivée, trempés, des éclairs nous tombent dessus. Nous nous arrêtons au plus vite sous le premier porsche que nous croisons sur la route pour s’abriter et attendre que la tempête se calme. Cet acte sage de bravoure nous permet d'éviter la tempête la plus démoniaque que nous avons croisé depuis le début de cette épopée (la pluie est si dense que même les voitures s'arrêtent sur le bord de la route). 

    Suite au démon, nous débarquons finalement dans la petite ville de Glückstadt, avec une petite heure de retard, chez notre nouvel hôte, Heiko, qui vit ici depuis 19 ans avec ses 2 enfants. On sympathise aussitôt avec ce père de famille, fan de lego et d’arrêt de bus, qui nous met rapidement à l’aise en nous racontant sa vie et ses histoires de voyage. En effet, Heiko est un grand toureur, il a déjà plus d’une dizaine de voyage à son actif dans le monde et plus particulièrement autour de la mer Baltique. 

    Pendant que Kiki concocte son traditionnel breuvage au gingembre, Waya redécouvre le plaisir de cuisiner en préparant des pâtes carbo improvisées et une salade tomate courgette concombres feta. 

    Après un bon dîner, Heiko, qui joue aussi aux échecs comme nous, nous fait découvrir un jeu de stratégie complètement disruptif avec un échiquier et des Lego qu'il appelle ''Line to action''. Même à 2 contre lui, il nous bat sans trop de problème.

    Une fois nos dernières forces intellectuelles journalières épuisées, nous partons rejoindre notre tente posée dans son jardin vers 23h. Comme il pleut encore un peu dehors, Heiko nous propose finalement de dormir sur le canapé du salon ce que nous acceptons avec grand plaisir bien sûr !
        `,
    },
    {
        index: 24,
        latLng: [53.74995, 9.921799],
        markerId: "day24-ellerau",
        iconType: TentIcon,
        description: "Jour 24 - Glückstadt -> Ellerau  - 47km",
        kms: 47,
        title: "Salam Kanka",
        features: {
            "warmshower": 1,
            "jours de pluie": 1,
            "café": 1,
            "bières": 2,
        },
        imagesName: ["IMG-20210807-WA0005.jpg", "DSC_3524.jpg", "DSC_3532.jpg", "PXL_20210807_090551982.jpg", "PXL_20210807_090608838.jpg", "PXL_20210807_091143106.jpg", "PXL_20210807_091334269.jpg", "DSC_3541.jpg", "PXL_20210807_112138958.jpg", "DSC_3547.jpg", "DSC_3553.jpg", "PXL_20210807_122202821.jpg", "PXL_20210807_130822191.jpg", "DSC_3554.jpg", "DSC_3555.jpg", "PXL_20210807_181435405.jpg", "PXL_20210807_185434933.jpg", "PXL_20210807_190231964.jpg", "DSC_3557.jpg"],
        text: `
    Levé tranquille à 8h après notre merveilleuse nuit de sommeil sur le canapé du salon. Petit déjeuner à base d'œufs brouillés à la française prépare par chef Waya accompagné de pain allemand et de la fameuse confiture cerise chocolat préparé maison par Heiko (assez surprenant mais très bon).

    En parlant du fameux pain allemand, le débat est une fois de plus remis sur la table : Heiko ose nous dire que le pain français n'est pas du vrai pain et que les champions sont bien sur les allemands (mdr). Afin de mettre fin au débat mouvementé, nous faisons mine d'être d'accord avec lui lorsqu'il nous flatte au sujet de nos merveilleux fromages français. Mise à part cette petite altercation, Heiko a vraiment été ultra cool avec nous, un de nos meilleurs hôtes jusqu’à présent. Nous le saluons donc la larme à l’œil et reprenons la route vers 10h.

    A peine quelques km après avoir décollé, nous tombons sur un Hofladen réservé aux légumes et il se trouve même que celui-ci est spécialisé dans la tomate. Quelle fût notre joie en découvrant des dizaines de variétés de tomates différentes, toutes plus stylées les unes que les autres (photos à l’appui). Des courgettes rondes, des poivrons longs, des petits concombres ET, tomate sur le gâteau, nous trouvons même des MELONS (qui sentent bon), les premiers (et très probablement les derniers…) que nous croisons sur notre route. Ce gros melon de Proust nous fait voyager dans le Sud le temps d’un instant, nous repartons sur la route les poches pleines de légumes.

    Pour nos milliers de lecteurs allemands qui nous suivent, nous tenons à nous excuser, nous avons été mauvaise langue en passant la frontière allemande en parlant des pistes cyclables : le réseau est incroyable ici et n'a rien à voir comparé avec les pistes françaises.

    A mi-parcours, nous tombons sur un petit café de rêve avec une serveuse trop sympa, Melissa, étudiante d'origine turque qui bosse ici à mi-temps pour faire un peu de moula.

    Nous dégustons notre melon et notre tomate assis dans le petit jardin du café en mode bobo canapé palette (demande effectuée au préalable à notre nouvelle pote Melissa bien sûr, nous sommes de jeunes gens civilisés) accompagné d'un gâteau et d'un café.

    Grâce à Pikachu le tchatcheur ainsi que quelques bonbons offerts en mode pervers, nous nous faisons pote avec Denis, un fraté de 7 ans, mi-turque mi-allemand, qui est là avec sa maman. Nos quelques skills de discussion Pokémon en allemand appris il y a quelques jours (cf. J17) permettent à Waya de réaliser un échange linguistique d'importance capitale avec ce nouveau pote, anglais contre allemand et turque. Waya sait maintenant faire ses lacets et compter jusqu'à 12 en allemand. Kiki en profite également pour effectuer ses plus belles imitations de la faune et de la flore.

    Après 2h passé dans cet endroit de génie, Melissa, trop cool, nous propose même de rester une heure de plus après la fermeture du café car il pleut dehors. Une fois la pluie calmée, nous saluons notre poto puis reprenons la route : « Salam Kanka ! » 

    1 km plus tard, nous nous rendons compte que nous avons complètement zappé de payer l’addition. Afin de ne pas s’attirer les foudres du Mojo, nous revenons bien entendu sur les lieux du crime pour réparer notre péché. Comme Mélissa n’est désormais plus là, nous décidons de rédiger un petit mot accompagné d’un petit billet pour que nous glisserons dans la boîte aux lettres. C’est à cet instant précis que Mélissa débarque devant nous et nous explique qu’elle a oublié quelque chose dans le café. « We forgot to pay ! » s’exclame Kiki en détresse. « It’s okay, it’s okay, you don’t need to pay ! » répond Mélissa génée par la situation cocasse.

    Nous engageons ainsi une bataille sans queue ni tête au cœur de l’octogone avec Mélissa qui refuse catégoriquement toute régularisation de notre part, aucun sens. Kiki réussit finalement à glisser furtivement un billet de 10 euros dans la tirelire cochon verte du comptoir pour le pourboire. On t‘as bien Mélissa 😉 You are the best ❤ Salam !

    Une fois la boss du game battue, nous remontons enfin sur notre vélo direction notre prochain hôte situé à une vingtaine de km de là dans la petit ville Ellerau. 

    La suite de l’histoire est assez insolite. En effet, l’arrivée chez notre hôte nous fait subitement basculer dans une dimension parallèle. Par conséquent, nous avons préféré remplacer les noms de nos futurs interlocuteurs dans la suite du récit afin de préserver leur identité :

    Nous débarquons ainsi vers 20h dans l’appartement d’Antoine où il vit avec sa famille recomposée : ses 2 enfants Julia et Louis, sa nouvelle copine Toshiba et son enfant Pompougnac de 3 ans et la personne préférée de la famille : Siri. En effet, Siri est la personne la plus sollicitée de la famille, elle sait tout faire, elle rend jaloux tout le monde ici, Antoine l’interpelle toutes les 5 minutes pour allumer la lumière de couleur rose, diminuer le son de la musique de quelques % ou même ouvrir la porte de son appartement. Fatigués après notre journée de vélo, nous ne pensions pas arriver au milieu d’un champ de bataille entre les enfants qui courent partout et les adultes qui se disputent, nous ne savons plus trop où nous mettre... Bref, comme les enfants commence à faire trop bordel dans la maison, Antoine décide de les envoyer passer la nuit chez une amie de Julia. Nous nous retrouvons donc à 6 : Kiki, Waya, Antoine, Toshiba, le petit Pompougnac et Siri. Afin de détendre un peu l’atmosphère et nous mettre à l’aise, Antoine nous offre une bière allemande que nous acceptons avec plaisir.

    Nous comprenons assez rapidement qu’Antoine et Toshiba, atteint d’un coup de foudre d’intensité 9 sur l’échelle de Cupidonter, se sont rencontrés il y a peine 3 semaines sur Badoo. Lui, fan de camping et de vélo, et elle, détestant le camping et le vélo, forment un couple plutôt éclectique et électrique. Toshiba, Kenyane de 26 ans née à Paris (1994 -> sang de la veine), est une personnalité plus que surprenante avec plus de 6 langues parlées à son actif : le français (sa langue préférée), l’anglais, l’allemand, le swahili (langue du Kenya), l’arabe et même le farssi (langue iranienne). Elle nous apprend, entre autres, à dire tomate en farssi (lol). Bref, Toshiba a pas mal bougé au cours de sa vie jusqu’à finir à Hambourg où elle a rencontré son ex-copain italien et eu son enfant Pompougnac qui comprend une dizaine de langue mais ne parle pas beaucoup. Toshiba est contente que nous sommes là, elle peut enfin parler un peu français, elle a tenu à nous préparer un plat iranien improvisé sans nom aux influences africaines, résultat d’un mélange de toutes les cultures qu’elle a croisé au cours de sa vie. Ces 2 là sont ouf, on rigole bien pendant le dîner en partageant des histoires toujours plus acadabrantesques les unes que les autres.

    On enchaine avec une soirée dans le salon avec une playlist insensée qui illustre bien le thème de cette soirée : Maitre Gims, techno et chansons françaises. Cette petite soirée aux lumières multicolores tamisées est l’occasion de remarquer que le petit Pompougnac n’est pas comme les jeunes de son âge, il n’est pas du tout intéressé par les vidéos de Scooby-doo et Bugs Bunny que nous lui montrons, ce qui l’excite : c’est la Techno et le tapage de pied ! Il finit même par coucher Kiki à 23h.

    En allant se coucher dans la chambre d'à côté, on s'engage dans une négociation musclée en allemand avec Siri qui refuse d'éteindre la lumière rouge romantique de notre chambre. Juste après avoir convaincu Siri, nous pensons enfin pouvoir dormir tranquille quand Toshiba ouvre soudainement la porte et nous demande de venir dans la cuisine, ce que nous faisons sans poser de questions. En arrivant dans la cuisine, elle nous prend la main, nous dispose en cercle avec Antoine et Pompougnac et nous demande d'effectuer une prière pour finir la journée : ''Kiki, tu commences !''. Le pauvre Kiki, en caleçon, baragouine tant bien que mal quelques paroles sorties tout droit de son chapeau en faisant usage de quelques dialectes que seul lui maîtrise, on ne comprend pas tout... Au tour de Waya de parler chinois, puis Antoine en caleçon, puis Pompougnac qui ne comprend pas grand-chose à ce qu'il est en train de se passer et qui aimerait plutôt être en soirée techno à l'heure qu'il est. Nous finissons cette longue et intense journée sur les belles paroles de Toshiba qui nous bénit et nous envoi tout droit chez le marchand de sable.

    "Ce vwayage est incrwayable", murmure Kiki en fermant les yeux.         
        `,
    },
    {
        index: 25,
        latLng: [53.866942, 10.712286],
        markerId: "day25-lubeck1",
        iconType: TentIcon,
        description: "Jour 25 - Elierau -> Lübeck  - 70km",
        kms: 70,
        title: "Epic Mille Time",
        features: {
            "warmshower": 1,
            "jours de pluie": 1,
            "sandwichs": 1,
            "plats de pâtes": 1,
        },
        imagesName: ["PXL_20210808_070143518.jpg", "DSC_3558.jpg", "PXL_20210808_080447014.jpg", "original_553eb266-41c0-480e-a8b2-d2ed81d94473_PXL_20210808_080537855.LS_exported_0.jpg", "DSC_3569.jpg", "DSC_3570.jpg", "PXL_20210808_111742224.jpg", "DSC_3576.jpg", "DSC_3580.jpg", "DSCPDC_0000_BURST20210808164611816.jpg", "DSC_3584.jpg", "PXL_20210808_150750445.jpg", "DSC_3589.jpg", "PXL_20210808_161312743.jpg", "PXL_20210808_162949860.jpg", "DSC_3590.jpg", "DSC_3592.jpg", "PXL_20210808_172045567.jpg", "DSC_3595.jpg", "PXL_20210808_190531645~2.jpg"],
        text: `
    En se levant à 8h, il y a encore beaucoup de remus ménage dans la ruche, les enfants d'Antoine sont revenus et tout le monde se prépare activement pour aller à la messe à Hambourg avec grand-mère. Nous nous dépêchons de fuir de ce souk non reposant en saluant la famille vers 9h.

    Aujourd'hui, nous avons beaucoup de kilomètres à parcourir avant d’atteindre la ville de Lübeck chez notre prochain hôte et malheureusement, cette fois-ci, nous ne pourrons pas éviter la pluie qui tombe en continu depuis ce matin. Heureusement que nous croisons un vendeur de fruits sur la route où l'acquisition de 500g fraise et 500g cerises nous redonne la force. 

    K-ways enfilés, nous ridons une vingtaine de kilomètres sur une ancienne voie ferrée réhabilitée en piste cyclable qui a l’avantage d’être relativement plate et au calme des voitures mais l’inconvénient d’être pleine de boue. Pour le passage de la barre des 1000 km aujourd’hui, le mojo nous met clairement à l'épreuve avec pluie, boue, dénivelé au programme. Mais, ce qu’il ne sait pas c’est que nous sommes déterminés plus que jamais, nous irons jusqu’au bout quoi qu’il arrive, quoi qu’il en coûte ! 

    Une petite pause déjeuner sandwich dans un parc sur un banc spirituel face à un lac avec nos potos les canards nous redonne un peu de force avant de franchir les 1000. C’est peu après notre reprise, au sommet d’une colline, que nous atteignons enfin les 1000 kilomètres parcourus depuis notre départ il y a 25 jours à Dunkerque. Nous prenons un instant pour célébrer ce moment fort, méditer et remercier le dieu mojo pour nous avoir accompagné tout au long de ce grand périple.

    Nous enchaînons avec une pause goûter lorsque nous croisons une petite clairière isolée avec 2 bancs et une table en plein milieu. Une courte sieste régénératrice allongés sur le banc suivi d'une traditionnelle tartine miel beurre de cacahouète (invention Waikiki) nous permettent de reprendre la route plein de sérénité et d'énergie.

    Nous arrivons enfin à 20h dans la charmante ville de Lübeck après 70 km de ride ! Johannes, architecte qui vit ici depuis 3 ans et qui vient de la région de Dortmund, nous accueille dans son nouvel appartement situé 2 km à l’Est du centre-ville. 

    Verre de vin italien en main (Montepulciano pour les amateurs), nous faisons connaissance avec notre hôte face à un coucher de soleil magnifique vue sur les toits de la ville à la fenêtre de sa cuisine tout en préparant un dîner digne de Top Chef.

    Nous passons ensuite à table dans son salon et dégustons de délicieuses pâtes préparées par chef Waikiki tout en écoutant l'orchestre national de France sous une lumière tamisée. 

    Comme notre hôte est en train de lire un livre sur le roi Arthur, Kiki lui fait découvrir Kaamelott et il a l'air de plutôt aimer ça. Après 2 verres de vins, nous arrivons même à lui faire apprécier les fines blagues nazis d'OSS 117. 

    Étant donné que cela fait plus de 2 semaines que nous ridons sans aucun répit, nous décidons de rester une journée entière à chiller dans la ville, notre hôte accepte généreusement de nous héberger une nuit de plus. Merci et bonne nuit Johannes !
        `,
    },
    {
        index: 26,
        latLng: [53.868942, 10.712286],
        markerId: "day26-lubeck2",
        iconType: TentIcon,
        description: "Jour 26 - Lübeck -> Lübeck  - 17km",
        kms: 17,
        title: "Repos, Lego, Dodo.",
        features: {
            "warmshower": 1,
            "jours de pluie": 1,
            "jours de repos": 1,
            "frites": 1,
            "pots de beurre de cacahouètes": 1,
        },
        imagesName: ["PXL_20210809_073751908.jpg", "PXL_20210809_080946586.jpg", "PXL_20210809_081014594.jpg", "PXL_20210809_081833240.jpg", "PXL_20210809_083615314.jpg", "PXL_20210809_091625794.jpg", "PXL_20210809_092443764.jpg", "DSC_3603.jpg", "PXL_20210809_093051204.jpg", "DSC_3606.jpg", "PXL_20210809_100558614.jpg", "DSC_3610.jpg", "PXL_20210809_135158625.jpg", "DSC_3615.jpg", "PXL_20210809_144852713.jpg", "DSC_3621.jpg", "DSC_3622.jpg", "PXL_20210809_153743601.jpg", "PXL_20210809_153757115.jpg", "PXL_20210809_164659350.jpg", "DSC_3623.jpg"],
        text: `
    Levé tôt à 7h ce matin car notre hôte part entamer sa semaine de travail à 8h (Lundi oblige). Après un petit-déjeuner rapide prit avec lui, il s’en va et nous indique que nous pouvons rester sans problème chez lui ce matin. Nous nous préparons donc tranquillement avant de rejoindre le vieux centre-ville de Lübeck avec nos bikes.

    Lübeck est en partie connue pour ses fameuses 7 tours (des églises et une cathédrale) réparties dans le vieux centre que nous pouvions déjà apercevoir hier sur la skyline de la ville durant le coucher de soleil. Nous nous perdons dans les petites rues de cette ancienne ville de marchands faite de briques rouges et naviguons de tour en tour. Nous commençons notre jour de repos bien mérité en dégustant le Marzipan (Massepain en français), spécialité de la ville, sorte de pâte d’amande comme nous avons en France, sous toutes les formes et pour tous les goûts.

    Nous en profitons pour réaliser quelques emplettes : Waya achète un Posca et des écouteurs (utilisés dans la recette de la banane perdue il y a une dizaine de jours) et Kiki achète une seconde peau et un nouveau matelas de camping auto-gonflable à la pointe de la technologie.

    Au menu à midi, c’est grosse Flammekuche dans un restaurant spécialisé, délire de Waikiki, qui n’est bien entendu pas du tout une spécialité locale. Ce repas nous achève littéralement, nous sommes crevés et partons donc faire une sieste dans l’herbe à côté d’un lac non loin d’ici après un instant méditation dans la cathédrale.

    Cette pause nous fait du bien et nous permet de faire un peu place dans nos cerveaux encombrés pour pouvoir accueillir les nouvelles connaissances qui vont suivre car nous allons au musée cet après-midi et, pas n’importe quel musée, un musée Lego instructif qui explique l’histoire de cette ville marchande.

    Instant Waikikipedia : Lübeck est une des 5 villes les plus incontournables de la Hanse (la « reine » comme l’appelle notre hôte), une association de marchand créée au 12ème siècle régissant les lois et accords des transactions dans toute la mer Baltique à cette époque, de l’Angleterre jusqu’à la Russie. Cet accord réunissait environ 200 villes autour de la mer dont les 5 villes les plus importantes : Londres, Bruges (Belgique), Bergen (Norvège), Newa (Russie) et Lübeck située au milieu de ce commerce. Durant plusieurs siècles, la Hanse a permis de nombreux échanges entre les différents pays la composant, des poissons séchés de Norvège à la cire et aux fourrures d’Allemagne en passant par la laine anglaise.

    On finit cette journée de répit par un gros bar à cocktail cubain. A la carte, le cocktail « Waikiki Feeling » (oui, oui, c’est bien comme cela qu’il s’appelle) à base de rhum, vanille, crème etc ne nous laisse pas vraiment le choix. Un évènement TrumanShow de plus qui ne nous surprend même plus à ce stade de l’aventure. A ceci, nous rajoutons des nachos, un mojito fraise et un gros burger histoire de bien célébrer officiellement la barre des 1000 km passés la veille.

    C’est après plus de 20 km ridés dans la ville que nous rentrons, pas si reposés que ça, chez notre hôte à 22h où nous le surprenons en train de se marrer sur Kaamelott et OSS 117.

    Nous effectuons un petit résumé de notre journée à Johannes puis partons nous coucher en douceur pour notre 2ème nuit à Lübeck.
        `,
    },
    {
        index: 27,
        latLng: [53.954848, 11.216557],
        markerId: "day27-klütz",
        iconType: TentIcon,
        description: "Jour 27 - Lübeck -> Klütz - 44km",
        kms: 44,
        title: "Passage entre les Mailles du Rideau de Fer.",
        features: {
            "campings sauvages": 1,
            "jours de pluie": 1,
            "sandwichs": 1,
            "café": 1,
        },
        imagesName: ["DSC_3626.jpg", "PXL_20210810_074737702.jpg", "DSC_3630.jpg", "DSC_3636.jpg", "PXL_20210810_144305373.jpg", "DSC_3644.jpg", "PXL_20210810_153328278.jpg", "PXL_20210810_155601581.jpg", "PXL_20210810_160526742.jpg", "DSC_3655.jpg", "PXL_20210810_171148914.jpg", "DSC_3661.jpg", "PXL_20210810_183927468.jpg", "PXL_20210810_184107332.jpg", "PXL_20210810_184252247.jpg", "DSC_3670.jpg", "PXL_20210810_190102687~2.jpg", "PXL_20210810_190537781.jpg"],
        text: `
    Levé tôt encore une fois à 7h pour saluer et remercier notre hôte avant qu'il parte travailler. Ce matin, c'est tranquille, on prend notre temps à l’appart de Johannes, petit-déj tartines infusion croissant + rangement des photos + pliage de tous nos habits propres qui séchaient dans le grenier depuis avant-hier + rangement de toutes nos affaires que nous avions littéralement éparpillées et mélangées partout dans l'espace que nous avait réservé notre hôte.

    Avant de quitter l'appart vers 13h, nous décidons enfin de remplacer la chambre à air avant de Kiki qui fuit un petit peu depuis quelques jours. Quelques minutes après avoir réalisé cette réparation de l’extrême, nous voilà enfin prêts pour partir quand, soudainement, la chambre à air que nous venons de réparer à l'instant explose devant le bâtiment... Elle devait probablement être mal positionnée à l'intérieur du pneu. Nous entamons donc notre 2ème réparation avec notre 2ème et dernière chambre à air. 

    Juste après cette seconde réparation effectuée vers 14h, il se met à pleuvoir un peu (la poisse), nous décidons malgré tout de partir vu le retard accumulé ce matin. 

    Malgré cette pluie, nous sommes heureux, nous sommes enfin des elfes libres, aucun rendez-vous, pas d'objectifs, pas d'hôte, on fait ce qu'on veut au rythme qui nous plaît !

    Par chance, la pluie s'intensifie juste au moment où nous passons devant un grand centre commercial à 3 km du centre-ville. On s'arrête, on fait des courses rapides puis on se prépare des gros sandwichs posés sur une table du café du centre commercial en attendant que le soleil veuille bien refaire son apparition. Juste avant de repartir après ce déjeuner, nous remarquons que le panier avant de Kiki est sur le point de casser. Dans la continuité de notre journée bricolage, nous entamons une réparation d'ingénieur en allant faire 2 courses au magasin de bricolage à côté. Résultat : nous repartons à 16h après avoir parcourus seulement 3 km, record de lenteur battu haut la main pour Waikiki. 

    Pas loin après Lübeck, nous passons l'ancienne frontière historique du rideau de fer direction l'Allemagne de l'est. En effet, comme nous l'expliquait Johannes avant-hier, le mur de Berlin n'était pas seulement situé à Berlin, de nombreux dispositifs tels que des barrières, barbelés, tour de garde, sable et no man's land... avaient été mis en place dans tout le pays du nord au sud afin de séparer l'Allemagne en 2. 

    Nous avons de la chance cet après-midi, pas de pluie car nous frôlons un gros nuage pendant toute la durée de notre ride vers l'est dans la campagne allemande vallonnée.

    Nous nous retrouvons dans la petite ville paumée de Klütz où nous remplissons nos stocks d'eau vers 20h. Les ventres commençant à crier famine, Kiki arrive assez facilement à convaincre Waya d'aller acheter un döner dans un kebab à la devanture qui ne paye vraiment pas de mine mais qui a l'air bon de l'intérieur. Personne ne parle notre langue à l'intérieur, heureusement qu'un allemand qui a fait Erasmus à le Mans (LOL) et qui parle un peu français nous aide à commander les 2 kebabs et choisir parmi les 5 sauces, les 2 viandes et les 5-6 légumes.

    Il se trouve que le mec qui nous a aidé est aussi le seul guide touristique de la ville (village) donc il nous recommande le meilleur spot de la zone pour aller déguster notre grosse (grossé) kebab des familles : le moulin situé au sommet de la ville. 

    En arrivant, nous validons complètement l'endroit et nous savourons notre délicieux kebab sur 2 balançoires devant le soleil tombant : moment rempli d'émotions mojoesques ! 

    Ce soir, on est chauds de faire notre grand come-back au camping sauvage après 5 nuits chez l'habitant. En plus, le chemin dans les terres est rempli de spots ultra stylés. Nous partons donc à l'aventure, tout excités, à la recherche de notre premier spot de camping sauvage allemand.

    Quelques kilomètres après la petite ville, vers 21h, nous décidons de nous arrêter dans un champ de blé pendant la golden hour pour afin de profiter d'un coucher de soleil rosé. Inspirés par la couleur dorée de ce champ, nous décidons de poser notre tente sur ce même champ en périphérie protégés par les arbres.

    Tente posée, ventre rempli, dents lavées, tous les éléments sont réunis pour passer une bonne nuit au calme dans notre champ de blé.
        `
    },
    {
        index: 28,
        latLng: [54.145588, 11.802623],
        markerId: "day28-kühlungsborn",
        iconType: TentIcon,
        description: "Jour 28 - Klütz -> Wismar -> Kühlungsborn - 71 km",
        kms: 71,
        title: "Les Riders-Ceuilleurs.",
        features: {
            "campings sauvages": 1,
            "jours de pluie": 1,
            "sandwichs": 1,
            "café": 1,
        },
        imagesName: ["DSC_3674.jpg", "PXL_20210811_074332546.jpg", "DSC_3685.jpg", "DSC_3691.jpg", "PXL_20210811_101027675.jpg", "DSC_3692.jpg", "PXL_20210811_101949635.jpg", "DSC_3699.jpg", "PXL_20210811_115331089.jpg", "DSC_3707.jpg", "DSC_3709.jpg", "DSC_3710.jpg", "original_e9bfecf5-df21-460f-a9e3-733b9fe8b1d1_PXL_20210811_174946999.jpg", "PXL_20210811_184029587.jpg", "PXL_20210811_184144314~2.jpg", "PXL_20210811_185928715.jpg", "DSC_3731.jpg"],
        text: `
    Ce matin levé 8h après 3 petits snoozes dans notre champ de blé. Malgré la fraîcheur, la nuit a été bonne, nous décollons aussitôt à 9h. 

    Après Amy Winehouse pour le réveil, c'est au tour du gros reggea dub de nous motiver de bon matin. On s'arrête sur une plage pour dire bonjour à la mer Baltique vers 10h. On en profite pour y prendre notre petit-déj à base de beurre de cacahouète bio et de pâte à tartiner bicolore (marron et blanc) tant appréciée par les allemands.

    Entre 2 vallons, l'apparition de pruniers sauvages sur le bord de la route nous oblige à nous arrêter. Tel des chasseurs cueilleurs préhistoriques, Waya et Kiki se mettent à ramasser un bon gros paquet de prunes d'environ 2 kg (largement suffisant pour un apport hebdomadaire en vitamine A). Sous prétexte que ''c'est gratuit'' comme il l'explique, Kiki ramasse 1 kilo supplémentaire pour la route. Notre pause ne s'arrête malheureusement pas là car nous croisons 200 mètres plus loin un énorme champ de fraises. Ne voyant aucun panneaux ''Achtung ! Fraises interdites !'' nous nous précipitons dans le champ pour goûter la marchandise. Malgré que le peu de fraises qui sont mûres soient très bonnes, la sagesse Waikiki nous rappelle à l’ordre et nous interdit de faire le stock.

    Après cette interlude fructueuse, nous reprenons la route et traversons la ville de Wismar où ne nous succombons pas à la tentation d'acheter des bons gros poissons grillés sur les étalages sur le port. Nous sommes vraiment sages aujourd’hui (Kzo serait fier de nous).

    Au bout de 37 km, nous trouvons un banc spirituel où nous déjeunons un sandwich fromage de chèvre salami (genre de saucisson allemand). Il faut maintenant que nous mangions nos kilos de prunes afin de nous alléger, nous procédons donc à un tri pour sélectionner les meilleures et se débarrasser des mauvaises.

    Départ à 16h après avoir fait quelques énigmes pour faire travailler les neurones. On ne sait pas si c'est à cause des énigmes, du camping sauvage ou du kebab de la veille mais le fait est que, nous sommes crevés. Heureusement qu'un banc spirituel se présente à nous quelques km plus tard pour se reposer. Nous repartons après la petite sieste de papi Kiki, direction la petite ville côtière Rerik où nous voulons faire une pause le temps de boire un café, remplir les gourdes et passer aux toilettes. Il se trouve qu'en arrivant au café là-bas, nous craquons pour une petite crêpe caramel ainsi que, plus tard sur la beach party du village, 2 grosses saucisses allemandes qui feront office de dîner.

    Vers 21h, nous trouvons un spot de camping sauvage situé à une centaine de mètres de la plage dans une mini forêt. Nous profitons de la proximité avec la plage pour se faire un gros coucher de soleil tombant sur la mer et les galets.

    Le bilan d'aujourd'hui est positif : nous avons ridé une de nos plus grosses journées avec un total de 72 km sur la route et plus de 300 mètres de dénivelé (record !) et ça se ressent dans les jambes.

    Ça pourrait être cool de trouver un vrai camping demain histoire de se laver un peu et recharger les batteries. Nous verrons ça dans le prochain épisode.
`
    },
    {
        index: 29,
        latLng: [54.3718, 12.420292],
        markerId: "day29-hafenweg",
        iconType: TentIcon,
        description: "Jour 29 - Kühlungsborn -> Hafenweg  - 57km",
        kms: 57,
        title: "Waikiki, Roi de la Foret, Esclave des Moustiques Enragés.",
        features: {
            "camports": 1,
            "piqûres de moustiques": 52,
            "plats de pâtes": 1,
            "ferry": 1,
        },
        imagesName: ["DSC_3733.jpg", "DSC_3736.jpg", "PXL_20210812_104821050.jpg", "PXL_20210812_110211417.jpg", "DSC_3748.jpg", "PXL_20210812_110713512.jpg", "DSC_3754.jpg", "PXL_20210812_111551500.jpg", "PXL_20210812_111753292.jpg", "PXL_20210812_113119378.jpg", "PXL_20210812_132531004.jpg", "DSC_3759.jpg", "PXL_20210812_173649813.jpg", "PXL_20210812_174302161.jpg", "DSC_3761.jpg", "DSC_3763.jpg"],
        text: `
        Levé à 9h après une douce nuit dans notre petit bois. 
        
        Quelques minutes après s'être levé, Waikiki prend son courage héroïque à 2 mains et part affronter l'hostile mer Baltique avec son froid polaire et ses millions de méduses qui ne piquent pas. Après ce moment intense, une petite pause bronzage séchage et ça part sur la route à midi tranquille.
        
        La côte est complètement différente de tout ce qu'on a vu depuis notre départ. Ici, plus de digues, mais des minis plages de galets, des mini falaises et surtout des forêts !!! En effet, nous découvrons enfin le plaisir de rider dans de VRAIES forêts remplies d'histoire, de hummus de qualité, de fraîcheur et d'arbres vieux comme le monde.
        
        On arrive à Warnemünde, ville côtière à l'embouchure de la grande ville Rostock où on se fait rapidement avoir par des odeurs de kebab allemand nous caressant les narines. ''Dwine chicken kebab mit käse bitte'' dit Waya au maître kebabier pour lui indiquer de préparer 2 gros kebabs des familles pour 2 gros fratés affamés. Il semblerait que l'allemand de Waya n'est pas parfait puisque le maitre kebabier réalise trois gros Kebab. A peine le kebab englouti, Waya se met à la recherche d'un magasin de Birkenstock locales afin de racheter ses birk ancestrales oubliées à Lübeck il y 2 jours chez Johannes. ''Ein birkenstock 41 bitte'' s'exprime-t-il à une vendeuse de chaussures qui lui fournit sa paire de Birk flambant neuve, produit typique et local de tout allemand qui se respecte. 
        
        Suite à cette acquisition, nous ratons notre ferry de peu à cause d’un accrochage avec un mec bossant sur le bateau que nous appellerons « vieux con » dans la suite de ce récit. En effet, ne sachant pas que les tickets ne s’achètent pas à bord ici, nous rentrons sur le ferry sans rien demander à personne. Le vieux con situé à l’entrée, pensant que nous avions essayé de le gruger, nous arrête tout de suite et nous indique d’aller acheter les tickets à une caisse un peu plus loin ce que Waya fait instantanément en s’excusant pendant que Kiki reste devant l’entrée du ferry à côté du vieux con. Comme il y a du monde et que les machines marchent à 2 à l’heure, nous ratons le ferry et, cerise sur le gâteau, le vieux con fait un gros doigt à Kiki en partant. Heureusement, plusieurs ferrys passent la rivière tous les jours, nous prenons donc le suivant une vingtaine de minutes plus tard sans recroiser le vieux con.
        
        Aujourd'hui, pas de Drop Alert ni de pull mais plutôt le gros combo ciel bleu + température de 25°C, on se croirait presque en été dans le Sud en croisant les quelques pins bordant la route. 
        
        À 5 kilomètres du camping où nous pensions dormir, nous tombons sous le charme d'un petit port doré par le soleil couchant où une une vingtaine de voilier y sont stationnés. En s'y rendant, nous remarquons qu'il y a 2 tentes posées à côté du port sur du gazon. Après investigation, il semblerait qu'il soit possible de poser la tente ici pour seulement 5 euros, douches et toilettes comprises. Quelle aubaine pour Waya et Kiki qui sont crevés de la journée et qui s’empressent donc de poser leur tente sur ce beau gazon. Nous comprenons assez rapidement que nous sommes en fait arrivés à Mosquito Land : il y en partout, des centaines par mètre cube. Cet environnement hostile non négligeable explique peut-être en partie le faible coût de ce camping improvisé. 
        
        Une fois la tente installée en vitesse, nous passons en mode top chef ce soir avec notre réchaud. Au menu, un plat réconfortant qui fait toujours plaisir et ne déçois jamais, j'ai nommé : le fameux jambon coquillettes (sans coquillettes malheureusement, ils ne connaissent pas ça ici). Ça sera plutôt avec des Penne, du beurre et du gruyère et du jambon, rien besoin de plus.
        
        Les moustiques profitent de ce moment de faiblesse pour nous attaquer le visage, seul endroit de notre corps à découvert. Nous dînons tant bien que mal notre repas paradisiaque en combattant ces animaux tous droits venus de l’enfer. En rentrant dans la tente, c'est la même histoire, nous effectuons un raid militaire équipés comme jamais avec nos gilets anti pare dard.
        
        Après avoir exécuté les quelques courageux moustiques ayant réussis à se faufiler dans la tente, nous partons nous coucher à 23h en toute paisibilité en comptant les moustiques rodant autour de notre tente.
        `,
    },
    {
        index: 30,
        latLng: [54.362209, 12.806611],
        markerId: "day30-barth",
        iconType: TentIcon,
        description: "Jour 30 - Ahrenshoop -> Barth - 46 km",
        kms: 46,
        title: "De Camport en Camport, la Chance nous Sourit",
        features: {
            "camports": 1,
            "piqûres de moustiques": 52,
            "plats de pâtes": 1,
            "ferry": 1,
            "pots de beurre de cacahouètes": 1,
            "café": 1,
            "concerts de musique": 1,
            glace: 1,
        },
        imagesName: ["DSC_3764.jpg", "DSC_3769.jpg", "DSC_3771.jpg", "DSC_3774.jpg", "PXL_20210813_112900496.jpg", "DSC_3778.jpg", "PXL_20210813_152655895.jpg", "PXL_20210813_172016294.jpg", "PXL_20210813_172726915.jpg", "PXL_20210813_174546268.jpg", "PXL_20210813_174613846.jpg", "PXL_20210813_174909731.jpg", "DSC_3779.jpg", "PXL_20210813_180722355.jpg", "DSC_3780.jpg", "DSC_3783.jpg", "PXL_20210813_184037283~2.jpg"],
        text: `
    Réveil à 9h après une grosse nuit de 10h, nous n'avons jamais connu une telle quantité de sommeil en 30 jours d'aventure et ça fait un grand grand grand bien.

    Une centaine de moustiques enragés nous attendent patiemment entre l'entrée de la tente et la toile étanche. Une stratégie militaire est mise en place par Waikiki afin de pouvoir sortir de ce guet-apens sans trop de dégâts. 

    Après un petit-déj de compétition nous permettant de finir notre pot de beurre de cacahuètes ainsi qu'un pot de miel, nous partons essayer de régulariser notre nuit à 5 euros. Cependant, après plusieurs demandes auprès des personnes travaillant dans le port, impossible de payer car il faut attendre le boss du port qui risque ''peut-être'' d'arriver un jour. Un employé nous fait clairement comprendre que, si personne n'est venu nous demander de payer ce matin, c'est notre jour de chance (peut-être voulait-il parler du fameux mojo...). 

    Comme il s'avère que nous sommes Vendredi 13, nous prenons ce que nous a dit le gars à la lettre et nous partons donc à midi sans avoir payé un seul denier pour entamer cette journée de chance qui commence très bien car nous recevons un très bon retour de mojo : la banane perdue de Waya aurait été retrouvée !!! En effet, après de nombreuses recherches infructueuses sur le net, Waya a désespérément tenté d'envoyer un message WhatsApp à une mairie (oui, ceci est possible aux Pays-Bas) située pas loin de l'endroit de la perte. Cette mairie répond dans l'heure qu'elle a entendu parler d'un sac retrouvé sur un camping pas loin de là. Waya appelle tout de suite ledit camping et tombe sur le gérant qui lui confirme que son frère (que nous appellerons ''fraté'' dans la suite du récit) a trouvé un sac similaire il y a quelques jours. Son fraté ne répondant pas au téléphone, Waya lui envoie un message WhatsApp et reçoit une réponse dans l'heure. Le fraté lui envoie une photo de LAdite banane en lui indiquant qu'elle est en sécurité et qu'il cherchait le propriétaire après avoir trouvé cette pochette surprise sur le bord d'un petit chemin de terre pas loin de l'endroit même où Waya pensait l'avoir perdue. C'est officiel ! La banane est entre de bonnes mains et ce n'est qu'une question de temps avant que le fraté envoie la banane à Waya ! Tout est mojo qui finit mojo ! 

    Suite à cette grosse tour de roue du mojo, nous partons rider dans les roseaux et les étangs en slalomant entre les armées de moustiques sataniques et de touristes capitalistes. Nous atteignons le point le plus au Nord de tout notre parcours et, paradoxalement, nous atteignons aussi le pic de chaleur le plus élevé depuis que nous sommes partis. Pas de vent, grand ciel bleu, le dérèglement climatique nous mène la vie dure. Heureusement que nous sommes en forme olympique aujourd'hui grâce à nos jambon-coquillettes du dîner de la veille !

    Nous nous arrêtons dans un café de la petite ville de Barth après 39 km de bon ride où nous nous récompensons par un gros Banana split et une généreuse coupe de fraise glace vanille. 1h après, sous prétexte que ''c'est le gâteau préféré de la serveuse'', Kiki achète une part de cheesecake en partant. 

    Avant de décoller, nous faisons la rencontre avec le frère du boss du café, en vacances ici. Il est né en 62 à Berlin du côté Est et a donc passé la moitié de sa vie avec un mur dressé à 500 mètres de chez lui, tout ce qu'il y a de plus normal. Il nous exprime clairement sa nostalgie, d'un temps où les inégalités étaient quasiment inexistantes côté communiste. Selon lui, il reste encore des traces de cette époque aujourd'hui, les Allemands de l'ouest seraient plus attirés par la moula et le capitalisme que les gens de l'est (au détour d'une discussion à vélo sur la route, un rider allemand nous a d'ailleurs appris aujourd'hui que les salaires étaient plus élevés à l'ouest qu'à l'est...). 

    Bref, nous saluons notre poto Staline qui s'en va manger un fischbröt (le fameux sandwich au poisson de la côte allemande) avec sa femme. 

    C'est reparti pour quelques kilomètres après quelques petites courses. Comme nous voulons prendre une douche et que nous connaissons maintenant la technique du camping port (appelé plus communément ''Camport'' par Waikiki), nous nous dirigeons tout droit vers le prochain port sur la map. 

    En arrivant sur les lieux vers 20h, ce n'est pas du tout la même ambiance qu'hier, il y a du monde partout et une grosse soirée est en cours car les Bummms Boys (groupe originaire de Rostock et apparemment connu dans la région) sont en train de foutre le feu au camping avec leur concert live. 

    On décide finalement de rester là pour la nuit tels de fidèles groupies parce que non seulement il y a vraiment une bonne ambiance et qu'on est un peu fatigué. Ce soir, c'est le retour du jambon-coquillettes en version améliorée avec, cette fois-ci, du bon comté pour relever le tout. Il semblerait que la redécouverte d'hier de ce plat traditionnel français nous a vraiment subjugués. Ce plat réunit toutes les caractéristiques d'un bon toureur expérimenté : c'est délicieux, pas cher, facile à confectionner n'importe où dans le monde et cela procure une énergie exponentielle le lendemain afin d'affronter des gros perible. De plus, il y avait 250g de pâtes restants dans notre sac qui n'attendaient qu'une chose, être dévorées. Bref, inutile de nous justifier plus que ça, nous adorons ce plat et nous vous recommandons vivement de l'essayer chez vous avec vos proches.

    Suite à ce festin, nous nous préparons une petite infusion gingembre camomille des familles et partons tenter une mission socialisation au milieu des groupies venues jusqu'ici juste pour le concert. Après la séance dédicace, nous perdons notre assortiment de couverts, assiettes et casserole propres laissés sur une table quelques minutes auparavant. Après 15 minutes de recherches et de discussions en anglais complexes avec des Allemands bourrés (pas facile d'expliquer à des gens en soirée que nous avons perdu une casserole et des couverts), Kiki retrouve finalement notre assortiment pas loin du bar posé sur une pile d'objets divers... 

    Nous nous endormons vers 23h avec le sifflement du vent sur les tentes et le ronflement complètement exagéré de notre voisin de tente.
        `
    },
    {
        index: 31,
        latLng: [54.081387, 13.473932],
        markerId: "day31-greifswald",
        iconType: TentIcon,
        description: "Jour 31 - Barth -> Stralsund -> Greifswald - 85 km",
        kms: 85,
        title: "Avant la Pluie Vient le Beau Champ, Après la Pluie Vient le Beau Chant",
        features: {
            "campings sauvages": 1,
            "jours de pluie": 1,
            "sandwichs": 1,
            "pots de miel": 1,
            "concerts de musique": 1,
            "poulets rotis entiers": 1,
        },
        imagesName: ["PXL_20210814_081854673.jpg", "PXL_20210814_091256364.jpg", "DSC_3788.jpg", "DSC_3791.jpg", "PXL_20210814_102110781.jpg", "DSC_3801.jpg", "PXL_20210814_114415694.NIGHT.jpg", "DSC_3806.jpg", "DSC_3808.jpg", "DSC_3810.jpg", "PXL_20210814_145242551.jpg", "DSC_3813.jpg", "PXL_20210814_173857791.jpg", "PXL_20210814_180913003.jpg", "PXL_20210814_181006606.jpg", "PXL_20210814_181158742.jpg", "PXL_20210814_181404610.jpg", "PXL_20210814_181446135.jpg", "PXL_20210814_182211560.jpg", "PXL_20210814_182306016~2.jpg", "PXL_20210814_182700401.jpg", "PXL_20210814_184251732.jpg", "DSC_3823.jpg", "PXL_20210814_201442830.jpg", "PXL_20210814_213508442.jpg"],
        text: `
    Dès le réveil à 8h, nos batteries sont pleines d'énergie et nous sommes définitivement déterminés à battre notre record de km aujourd'hui ! 
    Au cours d'un petit déjeuner de champion, nous rencontrons Franzi Panne, une Berlinoise de 33 ans qui parle grave bien français. Elle est actuellement sur son chemin du retour de son gros trajet Berlin -> Copenhague à vélo. Notre nouvelle amie a réussi à négocier un congé sabbatique de 6 mois avec son entreprise où elle travaille en tant que chargée de recherche sur les politiques migratoires en Allemagne. Elle nous fait donc un petit cours de géopolitique allemande et nous apprend que les immigrés principaux aujourd'hui sont originaires de l'Iran, la Syrie et l'Erythrée qui se rendent principalement en Allemagne de l'Ouest pour trouver du travail car, nous l'avons bien compris, à l'est, il n'y a pas de thunes ! (et les Allemands seraient apparemment plus racistes là-bas d'après elle (RDAAAAA)). Pour la suite de son congé sabbatique, elle partira effectuer un woofing de 1 mois dans une ferme collaborative en Allemagne pour tester ce nouveau mode de vie afin d'éventuellement l'adopter dans le futur. 
    À force de tchatcher, nos 2 compères prennent un peu de retard comme à leur habitude, c'est déjà très mal parti pour une journée où ils pensaient battre des records (depuis hier, on pouvait même surprendre ces deux loubards en train de discuter de potentiel record de 100 km, la blague). 
    Leur retard ne fait que s'allonger encore plus lorsqu'ils découvrent que les 2 seules toilettes du camping sont monopolisées par 2 Allemands depuis 30 min, pas de bol pour Waya qui aimerait bien faire sa traditionnelle pause toilette journalière avant de partir. 
    Nous quittons finalement ce camping après avoir payé le boss qui semble aussi être le party animal de la zone. En effet, le mec est encore explosé de la veille, en train de boire une bière en after-party avec ses potes qui danse sur du gros hard rock allemand diffusé à fond sur les enceintes qui résonne dans tout le camping. 
    Kiki avec sa coccinelle jaune et Waya avec son araignée montent enfin sur leur vélo et s'en vont vers de nouvelles aventures avec, cette fois-ci, du vent de face dominant. Heureusement qu'un mix énergétique d'Étienne de Crecy leur donne la force nécessaire pour vaincre les souffles du vent. 
    Nous profitons d'une pause banc spirituel perdus dans les champs pour faire quelques vérifications, cleaning et ajustements sur nos vélos et, bien sûr, manger notre carburant préféré : du miel et du beurre de cacahuètes étalés sur du pain. 
    Nous arrivons ensuite dans la banlieue de Stralsund après 35 km de ride ce matin. Kiki fait un tour dans un centre commercial pour acheter un nouveau panier car notre réparation sommaire effectuée il y a quelques jours n'est pas durable. Ainsi, après avoir récupéré le vieux matelas de Kiki, Waya récupère maintenant son panier et le fixe à l'arrière de son vélo avec un montage approximatif mais tout de même solide. Avant de repartir, nous achetons un bon gros poulet rôti que nous dévorons quelques minutes plus tard tels des Homo-Sapiens affamés dans un parc face à un lac situé en plein centre-ville. 
    À 17h, après cet apport en protéine conséquent, nous sommes enfin prêts à décoller pour la suite de notre parcours. En essayant d'échapper à un gros nuage de pluie arrivant droit sur nous, nous nous tapons une vingtaine de kilomètres sur des vieux pavés du moyen âge en mode gros vibreur sollicitant fortement nos muscles fessiers déjà fragilisés par cette grande épopée. 
    Afin de reposer nos fesses endolories, nous nous arrêtons nous amuser vers 20h dans un champ de botte de foin à l'atmosphère pré-apocalyptique. Après avoir joué avec les bottes et épuisé toutes les possibilités de fun dans cet immense champ, nous repartons pour un ride mystique sous nuage pluvieux et sous techno old school. 
    En arrivant finalement en ville à 21h, nous passons devant un bâtiment intriguant où nous distinguons du mouvement à l'intérieur. Par curiosité et pour s'abriter de la pluie, nous décidons d'aller voir ce qu'il se trémousse à l'intérieur. Nous nous apercevons rapidement que nous sommes arrivés dans un genre de centre culturel où se déroule actuellement un concert de musique classique et que nous arrivons pile poil pendant l'entracte. Sous prétexte qu' ''il est possible d'acheter des billets moitié prix pendant la pause'', nous achetons des billets moitié prix pendant la pause. Tout en profitant des prises de la salle pour charger nos équipements électroniques, nous assistons à un magnifique concert de musiciens finlandais composé de 2 pianistes, un clarinettiste et une castafiore. Nous sommes littéralement estomaqué et scotchés par ce qui est en train de se passer sous nos yeux, nous qui écoutions du gros Daft Punk à l'ancienne et sous la pluie il y a seulement quelques minutes. 
    Après cette pause musicale complètement lunaire, il se fait tard et sombre et nous n'avons toujours pas d'endroit où dormir ce soir. Il nous reste 5 km pour atteindre le camport objectif donc nous effectuons un ride nocturne avec nos frontales (bien entendu, la frontale de Waya décide de lâcher pile à ce moment-là, très pratique) sous une grosse musique techno nous accompagnant. Waya suit donc de près l'ombre du Pikachu de Kiki projetée au sol qui lui montre la voie à suivre tel un guide spirituel. 
    En arrivant devant le camping à 22h40, grosse déception, ce n'est pas du tout un camport mais bel et bien un gros camping de vieux camping-car qui semble complètement fermé depuis 22h. Personne pour nous ouvrir et  impossible de rentrer en vandale pour poser notre tente en plein milieu, le camping est clôturé tel un terrain militaire bien gardé. Petite lueur d'espoir quand le gardien sort soudainement de sa tanière et se dirige vers nous. Pas vraiment besoin d'avoir un niveau C1 en allemand pour comprendre qu'il est en train de nous expliquer de dégager rapidement de là tout en pointant son énorme Maglite 3000 sur nos têtes de sans abris. 
    Déçus par ce manque de tact, nous partons donc à la recherche d'un spot de camping sauvage, plus d'autres choix. 
    C'est donc dans cette nuit noire et obscure que nous nous empressons de trouver un logement sauvage pour la nuit. Nous pédalons alors tout droit sur la forêt la plus proche qui se trouve être sur le chemin du Waikiki Tour. Après une vingtaine de minutes de recherche, nous nous enfonçons dans cette forêt obscure aux arbres géants et intimidants. Une seule frontale pour 2 personnes rend la situation d'autant plus complexe et mystique car nous ne voyons pas grand chose. 
    Avec seulement une seule frontale pour 2, nos 2 compères ont bien du mal à s'organiser dans cette nature hostile. Kiki monte tant bien que mal le camp après avoir désherbé la zone pendant que Waya essaye de couper des tomates et faire des sandwichs : mission impossible, les tomates finissent par terre. Nous dégustons donc nos sandwichs approximatifs dans cet endroit mystique au milieu des bruits étranges non identifiés de la nature. 
    À minuit, nous nous retrouvons enfin posés dans notre tente au calme recroquevillés dans notre duvet 
    Bilan de la journée très positif malgré ce camping tardif improvisé et notre départ en début d'après-midi, nous avons explosé notre record perso avec 85 km au compteur tout en ayant même le temps d'apprécier un petit concert de musique classique.  
    Nous nous endormons, épuisés, la tête pleine de souvenirs de cette grosse journée riche en émotions. 

        `
    },
    {
        index: 32,
        latLng: [54.117181, 13.846067],
        markerId: "day32-karlshagen",
        iconType: TentIcon,
        description: "Jour 32 - Greifswald -> Freest -> Peenemünde -> Karlshagen - 35 km",
        kms: 35,
        title: "Perdus au Milieu des Epis de Maïs",
        features: {
            "camping normal": 1,
            "jours de pluie": 1,
            "sandwichs": 1,
            "plats de pâtes": 1,
        },
        text: `
    Ce matin réveil mystique à 8h dans notre forêt, on aurait bien dormi 3h de plus... Nous ouvrons la porte de la tente de Narnia et découvrons de jour le magnifique paysage  que nous avions eu du mal à apprécier la veille dans le noir. Nous sommes dans une immense forêt avec des arbres centenaires, du hummus de qualité et des bruits chelous. D'après les prévisions, nous n'allons pas pouvoir échapper à la pluie ce matin, c'est flux continu non-stop pour les 4 prochaines heures. 

    Nous enfilons donc nos k-ways pour contrer cette pluie (qui, soit dit en passant, sera finalement évitée par miracle) et nous commençons notre journée à 10h par une petite promenade forestière de 2 km nous procurant une grosse bouffée d'air pur pour le reste de la journée. 

    Nous sommes rapidement coupés dans notre lancée matinale quand, par hasard, après seulement 5 petits km parcourus, nous tombons nez à nez sur notre activité tant convoitée depuis notre entrée dans le pays : un labyrinthe dans un champ de maïs (activité typique de tout enfant allemand de moins de 14 ans qui se respecte). Comme il ne nous reste seulement quelques km avant la frontière polonaise, nous ne voulons surtout pas rater cette opportunité se présentant devant nous telle une offrande envoyée par dieu mojo. Ainsi, nous ne perdons pas une seule minute et fonçons tout droit dans le labyrinthe, papier, stylo et courage en main tels de bons ingénieurs. 

    Après 1 heure passée à se perdre entre les épis de maïs, nous trouvons finalement l'objectif situé au centre du Labyrinthe : nous comprenons que nous sommes en fait à l'intérieur d'une vache géante taillée dans le champ en mode complot crop circle : we are estomaqué ! Pour couronner le tout, nous finissons cette inoubliable expérience par un sprint final vers la sortie après s'être délibérément positionnés à l'opposé de la sortie. Note globale : 5/5, attraction d'ingénieur de qualité supérieure au prix imbattable de 2 euros l'entrée sans limite de temps. Ticket largement rentabilisé en à peine quelques minutes passées dans cet endroit rempli de mystère et magie. 

    Avec 28 km dans les jambes, nous arrivons à Freest, petit village de pêcheur où nous nous achetons un gros bout de saumon, des boulettes de poisson et le fameux backfisch (poisson frit de la côte) avant de prendre le ferry à 14h20 pour se rendre sur l'île moitié allemande moitié polonaise, Usedom. C'est donc sur cette île que nous passerons la frontière demain pour l'anniversaire de Waya. Île historique, car elle a été un des terrains de jeu des nazis pendant la seconde guerre mondiale. On peut y trouver de nombreux vestiges tels que des bunkers et laboratoires qui ont servi au développement d'une arme massivement utilisée pendant la guerre : le méchant missile Aggregat 4 ou A4 ou V2 (12 tonnes, 13 mètres, 800 kilos d'explosifs, rayon d'attaque énervé de 300 km). 

    Une fois nos boulettes de poisson ingurgitées et notre petit moment de repos dans le gazon effectué, nous réalisons la visite rapide d'un sous-marin soviétique le U461 stationné dans le port de Peenemünde, ville d'arrivée du ferry. Malgré notre micro sieste de papi sur l'herbe, nous sommes vraiment crevés de la grosse journée d'hier et de notre courte nuit dans les bois. Il faut que l'on trouve un camping au plus vite pour avoir un minimum de confort. Direction la côte Nord de l'île, remplis de touristes et de camping. 

    À 18h, on en trouve un 10 km plus tard qui fait largement l'affaire mais qui est également le plus cher que nous ayons rencontrés depuis le début (30€, 1 tente, 2 personnes) et qui ne nous permet même pas d'avoir accès à l'électricité à notre tente. S’ensuit durant 2 heures une grosse logistique d'ingénieurs entremêlant lavage et séchage des habits à la machine, cuisine du classique dîner jambon coquillettes sans coquillettes V3 tant apprécié de nos 2 sportifs de haut niveau et charge de tous nos équipements électroniques. En effet, suite à de nombreuses nuits consécutives passées sans électricité, nos téléphones, ordi, batteries externes et lampes sont quasi à sec. En tant que bons toureurs amateurs du 21ᵉ siècle, nous avons grandement besoin de ces outils modernes pour nous organiser et nous déplacer dans le temps et l’espace. Malheureusement, la réception nous indique qu’il est nécessaire d’avoir un câble adaptateur spécial de pro du camping-car pour avoir accès à cette précieuse énergie, câble que nous n’avons bien évidemment pas et qu’aucun de nos voisins de tente n’a en double. Par conséquent, en tant que bons filous, nous partons à la recherche de prises vandales cachées dans le camping.

    Bingo ! Nous trouvons la seule prise disponible dans la laverie du camping à côté du ballon d’eau chaude. Nous ne ratons bien entendu pas cette opportunité et nous nous empressons de charger tout notre matos sur cette prise. Comme la laverie ferme la nuit de 20h à 9h, nous décidons de mettre en place un stratagème : profiter de cette fermeture pour laisser charger nos 2 grosses batteries externes (25000 mAh pour les connaisseurs) toute la nuit en toute sécurité à l’abri des brigands de grand chemin.

    Go dans la tente à 22h après une douche bien méritée. Nous nous couchons exténués à 23h sans même avoir la force d'attendre minuit pour l'anniversaire de Waya (de toute manière, il n'est pas né à minuit donc cela n'a aucun sens). Demain grasse mat challah.
        `,
        imagesName: ["DSC_3835.jpg", "DSC_3838.jpg", "DSC_3839.jpg", "PXL_20210815_063955253.jpg", "PXL_20210815_071705791.PANO.jpg", "DSC_3841.jpg", "PXL_20210815_075208462.jpg", "DSC_3842.jpg", "DSC_3845.jpg", "PXL_20210815_082323112.jpg", "DSC_3852.jpg", "PXL_20210815_090257110.jpg", "DSC_3855.jpg", "DSC_3856.jpg", "PXL_20210815_105537355.jpg", "PXL_20210815_115128024.jpg", "DSC_3859.jpg", "DSC_3860.jpg", "PXL_20210815_130819974.jpg", "DSC_3864.jpg", "DSC_3866.jpg", "PXL_20210815_141916841.jpg", "PXL_20210815_142344886.jpg", "DSC_3868.jpg"],
    },
    {
        index: 33,
        latLng: [53.916895, 14.255778],
        markerId: "day33-swinoujscie",
        iconType: TentIcon,
        description: "Jour 33 - Karlshagen -> Swinoujscie - 40 km",
        kms: 40,
        title: "You are the Lucky One",
        features: {
            "camping normal": 1,
            "jours de pluie": 1,
            "anniversaires de Waikiki fétés": 1,
            "café": 1,
            "vrais restos": 1,
        },
        imagesName: ["PXL_20210816_075347515.jpg", "PXL_20210816_075404006.jpg", "PXL_20210816_092639120.jpg", "DSC_3872.jpg", "DSC_3878.jpg", "PXL_20210816_131034787.jpg", "PXL_20210816_132034603.jpg", "DSC_3882.jpg", "PXL_20210816_134139248.jpg", "PXL_20210816_134624483.jpg", "DSC_3889.jpg", "DSC_3891.jpg", "DSC_3892.jpg", "PXL_20210816_164627737.jpg", "PXL_20210816_165220421.jpg", "DSC_3894.jpg", "PXL_20210816_171042817.jpg", "PXL_20210816_171255390.jpg", "PXL_20210816_171538613.jpg", "DSC_3895.jpg", "DSC_3898.jpg", "DSC_3901.jpg", "1629142487862.jpg"],
        text: `
    Malgré cette nuit très mouvementée faite de pluies et d'orages, nous nous réveillons de notre grasse mat au plus grand des calmes (dans le monde des toureurs expérimentés, une grasse mat se termine généralement au maximum à 9h). 

    Première mission de la journée : récupérer nos fameuses batteries externes que nous avons planqué hier dans la laverie. À 9h pétante, heure de l’ouverture de la laverie, Kiki se dévoue et part chercher les bébés. Malheureusement, il revient bredouille car les batteries ont apparemment été confisquées par la femme de ménage qui les aurait laissées à la réception. Kiki prend son masque à 2 mains et part, déterminé plus que jamais, affronter le terrible boss de fin du camping dans l’arène de la réception. En arrivant sur le lieu du combat, le champion de l’arène l’informe qu’il vient de commettre une infraction : l’électricité est payante et il est illégal de détourner de l’électricité du camping à ses propres fins. Kiki, confus et paralysé par cette première attaque, réplique avec son attaque spéciale « I didn’t know ». Coup critique sur le boss, qui abandonne et rend le précieux matériel en murmurant ses dernières paroles : « You are the lucky one ! ».

    Suite à cet acte héroïque, nous enchaînons avec un petit-dej d'anniversaire tartine infusion dans la cuisine du camping au milieu de tous les Allemands faisant leur vaisselle. Ce moment est l'occasion de réaliser que nous avons, une fois de plus, semé nos affaires en Allemagne, à savoir, nos gobelets ecocup que nous avons très probablement oublié dans la cuisine du 2ème camport (cf. J30)... Nous retenons nos larmes et nous nous retrouvons donc à boire nos infusions dans des chapeaux pointus, parfait pour commencer une journée d'anniversaire réussie.

    Départ à 12h30 après une petite visite de la plage touristique du camping qui, d'après le boss du camping, serait payante...

    Playlist d'anniversaire en main, nous traversons une forêt de pins à toute allure. Sur la route, pendant que Kiki se fait pote avec tous les enfants qu'il croise grâce à sa mascotte Pikachu, Waya apprend comment dire ''je veux une grosse crêpe et du cidre'' à un Allemand qui souhaite partir à vélo en Bretagne.

    Malgré tous ces amusements présents sur le parcours, nous n'oublions pas l'objectif ultime du jour : dîner dans un restaurant italien à Swinoujscie, en Pologne, à 40 km du camping pour célébrer l'anniversaire du gros payot.

    Durant une visio-conférence avec notre cher ami Melvin (gourou ensoleillé) vers 14h, nous recevons une notification furtive de Drop Alert nous indiquant qu'une énorme tempête de plusieurs heures se dirige tout droit vers nous et qu’il nous reste 30 à 40 minutes avant le déluge, c'était pas du tout prévu dans le planning de la journée d'anniversaire ça. Nous entamons donc une course poursuite digne de Mario Kart au milieu d'une forêt sur le plus beau chemin cyclable que nous ayons connu depuis le début de cette épopée. Fait remarquable et non négligeable pour la course poursuite en cours : ce chemin sablonneux longeant les falaises dans la forêt est également la piste la plus vallonnée que nous ayons jamais ridée, avec des côtes atteignant même les 16% (cf. photos à l'appui).

    Nous arrivons pile poil à l’heure du goûter à Heringsdorf, juste le temps de confectionner et manger un petit sandwich et de se rendre dans un café avant l’arrivée du déluge.

    Sous prétexte ''qu'il fait froid'' Kiki opte pour un chocolat chaud pendant que William commande un café d’anniversaire. Qui dit déjeuner, dit bien sûr gros dessert ! Nous commandons 2 grosses parts de Torte allemande (part de gros gâteau) pour accompagner nos boissons chaudes.

    Nous souhaitions profiter de ce moment de pause pluviale pour mettre à jour le site. Malheureusement, à l'arrivée de notre grosse part de gâteau, nous préférons procrastiner et nous pencher sur la résolution d'un problème de math d'urgence absolue :

    À quelle distance ''x'' faut-il couper notre part de Torte dans sa longueur pour obtenir 2 parts égales tout en connaissant l’angle de coupe alpha et le rayon R de ladite part ? 

    Pendant une bonne grosse demi-heure, nos 2 scientifiques réalisent donc une démonstration mathématique de la plus haute complexité sur une petite serviette traînant sur le bord de la table (cf. solution sur la photo ci-dessous). Après avoir résolu ce problème cornélien et partagé leurs Tortes en 2 parts égales, nos 2 procrastinateurs en herbe décident de partir affronter le mauvais temps pour franchir la frontière et rejoindre le fameux restaurant italien situé en Pologne à Swinoujscie à 10 km, réservation effectué pour 19h, dans 1h.

    Quelques minutes après la reprise, nous atteignons enfin la fameuse frontière germano-polonaise tant convoitée ces derniers jours. C’est un moment très fort pour Waikiki et un beau cadeau d'anniversaire pour Waya. Nos 2 amis se félicitent par un câlin rempli de mojo, ils viennent de passer une étape très symbolique de leur parcours, la dernière frontière à franchir, la frontière de la destination finale. Après une petite séance photo pour célébrer ce moment fort en émotions, nous reprenons la route à 19h, pizzas italiennes dans le viseur.

    En arrivant au restau quelques km plus loin, Waya reprend très vite ses réflexes de polonais : « Dzien dobry, po prosze dwa wodka shots !». Le serveur n'en croit pas ses yeux et pense que nous voulons dire : ''dwa woda'' qui n'a strictement rien à voir… Et boom ! 2 shots glacés dans nos ventres vides pour bien commencer le dîner. Suite à cette introduction musclée, nous commandons 2 grosses pizzas italiennes bien méritées suivi de 2 cheesecakes du jour accompagnés d'une traditionnelle bougie pour marquer le coup.

    Après cet instant de pur bonheur, nous remettons les pieds sur terre et réalisons qu’il est maintenant bientôt 22h et que nous n’avons toujours pas d’endroits où dormir ce soir. En effet, suite à un appel téléphonique, nous apprenons que le camping que nous visions initialement est complet. Il est tard, il fait sombre et il va pleuvoir cette nuit ce qui rend la tâche très complexe pour effectuer un camping sauvage. De plus, il n’y a aucune auberge de jeunesse dans cette ville et les hôtels les moins chers sont à 100 euros la nuit… la grosse tuile. Dans un dernier élan d’espoir, nous nous rendons à un autre camping vers 22h situé en plein centre-ville, du jamais vu ! Grande surprise quand nous arrivons, le gardien nous accueille à bras ouverts et nous indique tout de suite où poser la tente. Gros gros soulagement pour Waikiki qui redoutait l’éventuel camping sauvage humide et galère qui allait arriver…

    Après une discussion en Deutsch avec des enfants allemands, un lavage de dents ainsi qu’un gros bilan de cette journée bien trop riche en émotions, nous partons enfin nous reposer aux pays des merveilles.



    Sur un ton plus sérieux, nous souhaitions rendre hommage aujourd’hui à une personne qui le mérite.

    Nous avons tristement appris cet après-midi, par son fils, le décès de notre chère amie Pia rencontrée sur notre route une dizaine de jours plus tôt jour 19.

    Pia était une grande fan de notre blog et suivait notre aventure à la lettre en traduisant tous les textes. Depuis que nous l'avions rencontré, elle prenait régulièrement des nouvelles de nous pour savoir si tout se passait bien. C'était une battante, remplie d'humour, d'amour, d'énergie et de mojo qu'elle nous a toujours partagé.

    Nous espérons sincèrement qu’elle ait enfin trouvé un endroit où se reposer.

    Nous la remercions pour toute la force et la protection qu'elle nous a transmis. 

    314a <3

    `
    },
    {
        index: 34,
        latLng: [53.711759, 14.551327],
        markerId: "day34-kopice",
        iconType: TentIcon,
        description: "Jour 34 - Swinoujscie -> Kopice - 57 km",
        kms: 57,
        features: {
            "campings sauvages": 1,
            "jours de pluie": 1,
            "café": 1,
            "vrais restos": 1,
            "jours en vent de face": 1,
            "pots de beurre de cacahouètes": 1,
        },
        title: "VTT : Vélo Toutes Tempêtes",
        imagesName: ["PXL_20210817_082231309.jpg", "DSC_3908.jpg", "DSC_3909.jpg", "DSC_3913.jpg", "PXL_20210817_121602928~2.jpg", "DSC_3916.jpg", "PXL_20210817_124622190.jpg", "DSC_3921.jpg", "PXL_20210817_125633823.jpg", "PXL_20210817_130716539.jpg", "PXL_20210817_133121636.jpg", "DSC_3924.jpg", "PXL_20210817_141402901.jpg", "PXL_20210817_141810142.jpg", "PXL_20210817_141849114.jpg", "PXL_20210817_144209721.jpg", "DSC_3932.jpg", "DSC_3937.jpg", "PXL_20210817_210455054.jpg", "DSC_3942.jpg", "DSC_3945.jpg", "DSC_3947.jpg"],
        text: `
    Premier levé et première grasse mat au pays des Polonais après notre journée d'hier très forte en émotions.

    En sortant de la tente, nous faisons l'heureuse découverte d'un buffet breakfast présent dans le camping donc nous fonçons prendre un brunch vu l'heure tardive (11h). Nous en profitons pour réserver un Airbnb pour dans 2 jours à Szczecin, la plus grosse ville polonaise de la frontière prononcée ''chtchétsine'' (impinable à prononcer), où nous avons RDV avec l'amoureuse de Waya, la petite espagnole Irène qui viendra nous rejoindre depuis Berlin sur notre parcours pendant 1 nuit. 

    Départ tardif à 13h pour aller prendre le ferry, traverser la rivière de la ville et ainsi quitter l'île d'Usedom où nous nous trouvons depuis maintenant 2 jours. 

    Aujourd'hui, gros temps de merde pour changer... Pluie intermittente, vent de face, froid, nous ne nous laissons pas faire et nous entamons un gros ride VTT d'une dizaine de kilomètre dans la forêt polonaise en se protégeant de la pluie grâce à nos amis les arbres. Moment d'émotion quand nous saluons pour la dernière fois, celle qui nous accompagnait depuis le début de notre périple et qui nous a offert des couchers de soleil du démon : la belle et l'immense mer ! Suite à ces adieux, nous nous dirigeons tout droit au sud dans les terres, où nous suivrons la frontière pendant plusieurs jours.

    Nous ridons encore une fois plusieurs kilomètres tous seuls au milieu de la forêt sur une piste cyclable en travaux. 

    Avec environ 36 km effectués, nous arrivons dans une petite ville vers 16h où nous faisons des sandwichs rapides et décidons d'aller se reposer dans le meilleur café/restaurant de la zone. En nous rendant là-bas, nous sommes chaleureusement accueillis par la serveuse trop cool et nous commandons un thé + cappuccino. Waya a la bonne surprise de découvrir que l'écran de son ordi est fissuré en 2 en l'ouvrant et qu'il ne charge même plus, énorme tuile… Après réflexion, il y a des chances que cet événement fâcheux soit corrélé avec la chute sur le sol d'un des sacs quelques minutes avant de rentrer le restaurant lors d'une session "Hula Hoop" de sac. Bref, heureusement qu'un généreux burger accompagné d'un gros sandwich pastrami réconfortant l'aide à oublier ce terrible malheur. 

    Suite à plus de 3h de chill dans le restau, nous reprenons la route vers 20h30. Juste avant de quitter la ville, nous croisons un supermarché ouvert et Waya en profite pour aller s'acheter des nouvelles piles AAA pour sa frontale afin de lui permettre de dompter le monde de la nuit. Une fois cet achat de première nécessité effectué, la lumière fût. Waikiki peut maintenant rider dans la nuit en titre confiance sans aucun problème. Pleins d'énergie électrique, ils décident même d'effectuer leur premier vraie sortie nocturne afin de se rapprocher un peu plus de leur objectif : Szczecin. 

    Nous entamons donc un long et complexe ride nocturne au milieu des renards et sous la lune timide qui se cache par intermittence derrière les nuages. Les éléments sont littéralement déchaînés sur la digue que nous empruntons, nous n'avons pas encore connu de vent de face de cette ampleur. Heureusement que les grands classiques de la techno à l'ancienne tels que DJ Tiesto, Calabria et Deadmau nous accompagnent pour nous aider à vaincre les bourrasques de vent qui nous frappent de plein fouet. Dans cette nuit noire, nous pouvons distinguer les yeux fluorescents des animaux nous guettant sur la route grâce à nos frontales. Nous croisons même 2 fratés sangliers qui chillaient par là. 

    Après une petite heure de combat contre les éléments, quand nous pensions ne pas pouvoir être dans une situation plus complexe que celle-ci, une pluie imprévue sur les radars vient s'ajouter à la liste des problèmes en cours. Nous passons enfin la digue après presque 20 kilomètres effectués dans le noir, en pleine nature. Malgré sa complexité, ce trajet restera un des plus fous et mémorables que nous ayons jamais fait.

    Nous nous arrêtons dans une forêt de pins à une centaine de mètres de la route pour y planter notre tente. Une fois le camp monté à minuit, nous réalisons que nous venons d'arriver à Aracnolandia, le pays des araignées. En effet, après les moustiques sataniques croisés il y a quelques jours, c'est au tour des araignées endiablées de nous mener la vie dure. Il y en a partout, pour tous les goûts, toutes les formes, toutes les tailles, tous les venins. Nos vélos, stationnés depuis quelques minutes, sont déjà couverts de toiles. 

    Pour survivre dans cet environnement hostile, nous prenons nos précautions et faisons en sorte qu'aucune araignée ne pénètre dans notre petite tente d'amour. Malgré cela, Kiki finit par se faire mordre au doigt. Par chance, la piqûre n'est pas mortelle, Kiki ne décède pas sur le coup et reste solide. Néanmoins, Waya reste sur ses gardes car, à n'importe quel instant, son cher compagnon pourrait subitement se transformer en Spider Kiki et l'empaqueter dans une grande toile pour son déjeuner du lendemain. 

    La nuit dans cette forêt interdite risque d'être longue...

        `
    },
    {
        index: 35,
        latLng: [53.398484, 14.667647],
        markerId: "day35-dabie",
        iconType: TentIcon,
        description: "Jour 35 - Kopice -> Szczecin Marina - 60 km",
        kms: 60,
        features: {
            "nuits à l'auberge": 1,
            "jours de pluie": 1,
            "jours en vent de face": 1,
            "vrais restos": 1,
            "sandwichs": 1,
        },
        imagesName: ["DSC_3948.jpg", "PXL_20210818_093213135.jpg", "PXL_20210818_094309269.jpg", "DSC_3951.jpg", "PXL_20210818_100214286.jpg", "DSC_3954.jpg", "DSC_3956.jpg", "DSC_3958.jpg", "DSC_3961.jpg", "DSC_3966.jpg", "DSC_3968.jpg", "DSC_3969.jpg", "DSC_3970.jpg", "PXL_20210818_123502832.jpg", "DSC_3976.jpg", "PXL_20210818_130115555.jpg", "PXL_20210818_132806994.jpg", "PXL_20210818_142153960.jpg", "PXL_20210818_145819327.jpg", "PXL_20210818_150953436.jpg", "PXL_20210818_155247959.jpg", "DSC_3983.jpg", "DSC_3984.jpg", "DSC_3986.jpg", "PXL_20210818_175314239.jpg", "PXL_20210818_191407719.NIGHT.jpg", "DSC_3988.jpg"],
        title: "De la Forée Interdite à l'Auberge Flottante en Passant par les Villages Fantômes ",
        text: `
    Levé à 10h, bonne nouvelle, Waya n'est pas mort étouffé dans un piège d'araignée et Kiki ne s'est pas transformé en spider Kiki, la journée semble bien commencer. Ce semblant de paisibilité ne dure guère car les araignées sont bel et bien encore là... En effet, des centaines de pièges en toile ont été installés autour de notre tente pendant la nuit... Ces horribles créatures attendent toutes sagement que nous tombions dedans pour faire de nous des prisonniers. Ce qu'elles ne savent pas, c'est qu'elles font affaire à des campeurs expérimentés qui connaissent ce genre de stratagème basique d'insectes. 
    
    Juste avant de sortir de la tente pour partir au front, nous recevons un email de Jan, un de nos hôtes rencontré J14 en Hollande. Il nous indique que nous sommes à présent célèbres dans sa petite ville en nous envoyant un lien vers le site internet de sa ville où un article nous est dédié ! Pas croyable ! Ça y est, le succès n'est à présent plus très loin, la roue du mojo est en train de tourner, tous ces efforts sont finalement en train de payer ! Afin de répondre aux interjections de type : ''non mai frer ta kru quoi la ?! arete de mito 1 pe avec t istoire abracabrantesk'', la preuve irréfutable de ce qui vient d'être énoncé ci-dessus se trouve sur le lien suivant : <a class="href" href="https://sintjabik.nl/actueel/toeristen-op-bezoek-in-sint-jacobiparochie/" target="_blank">https://sintjabik.nl/actueel/toeristen-op-bezoek-in-sint-jacobiparochie/</a>
    
    Après cette nouvelle improbable et un fight avec les araignées postichées devant la tente, nous reprenons nos vélos couverts de toiles vers 12h. Nous nous rendons dans un port situé à 2km de là car, d'après la légende, un petit ferry pourrait nous faire traverser la lagune de Szczecin et ainsi nous raccourcir le trajet de 30 km. En arrivant là-bas, pas de ferry en vue, seulement un mini port qui ne paye pas de mine et un vieux polak ne parlant pas un mot d'anglais. Il se trouve qu'une fille de notre âge est également présente dans ce mini port abandonné tel une oasis mirage dans un désert. Cette fille, qui a l'air grave cool, accepte de nous aider à communiquer avec le vieux en jouant l'interprète anglais-polak. Après quelques secondes d'investigation, elle nous confirme assez vite la légende en nous indiquant que le vent est probablement trop fort aujourd'hui pour faire circuler le ferry et qu'il faudrait appeler un certain numéro pour avoir plus d'informations blablabla. Bref, en voyant la tête du ''ferry'' qui ressemble plutôt à une barque de loisir, nous abandonnons rapidement l'idée de tenter ce raccourci d'autant plus que le polak en question semble nous indiquer qu'aujourd'hui les risques de naufrage sont importants. 
    
    Nous sympathisons ensuite avec cette fille, Julia, originaire d’Hanovre en Allemagne, mi-allemande mi-polonaise, en vacances ici pour 1 semaine avec des amis. Elle cherchait à louer des kayaks pour la journée mais le polak ne semble pas vouloir à cause du fort vent. Elle est tellement cool qu'elle nous invite chez elle à 2 pas d'ici et nous présente ses potes et sa sœur Véroniqua, également très sympa. Elles nous expliquent que c'est l'ancienne maison de leur mère polonaise et qu'elles viennent ici tous les ans en vacances depuis qu'elles sont petites. Comme nous avons pris du retard avec la fameuse légende du ferry fantôme, nous refusons gentiment tout café, thé, fruits et vodka proposés par ces sanchos et reprenons la route. 
    
    Nous traversons plusieurs petits villages polonais, villages fantômes aux maisons délabrées à vendre... Les nuages, les maisons en ruine, la route, tout est gris, il n'y a pas de vie... Ce qui nous arrange car on peut rouler tranquille sur la route. Les pistes cyclables ont bien changé depuis les belles pistes allemandes et hollandaise, nous circulons à présent sur des routes de campagne écorchées sans vie. 
    
    Petite pause sandwich qui nous permet de terminer nos pots de miel et de beurre de cacahuètes (heureusement que nous avons du stock d'avance). 
    
    Sur un coup de folie, nous bookons une nuit dans un genre de bateau hôtel situé dans un petit port près de Szczecin pour ce soir histoire de se récompenser des derniers jours de galère. 
    
    Nous sommes en manque d'eau suite à notre camping sauvage. La Pologne étant un pays où l'eau n'est pas vraiment réputée potable, il est quasi impossible de trouver un robinet dehors pour remplir nos bouteilles. Ici, tout le monde boit dans des bouteilles d'eau en plastique. Au cours d'une discussion en polonais avec un groupe de jeunes dans une école, nous comprenons que le seul endroit où il est possible d'avoir de l'eau est le mini shop du village. Pour notre survie, nous achetons donc une grosse bouteille d'eau là-bas. Kiki tombe dans le classique piège polonais et achète une bouteille d'eau gazeuse sans le savoir (ici, les bouteilles d'eau gazeuses ont quasiment la même couleur que les non gazeuses, il faut juste savoir parler polonais et bien lire l'étiquette).  
    
    Il est 14h et il nous reste maintenant 40 kilomètres pour arriver à notre objectif. On remet le turbo et on part pour une promenade champêtre où nous croisons même 2 cigognes sur la route nous indiquant le chemin au moment où notre compteur dépasse le gros cap des 1500 km ! 
    
    N'ayant plus de réseau sur nos téléphones au milieu de cette campagne polonaise, Kiki met du son avec la seule playslist stockée sur son téléphone : une playlist de musique espagnole des années 2000 (ne nous demandez pas pourquoi). Nous effectuons donc les 20 derniers kilomètres tels des Speedy Gonsalez voulant arriver au plus vite pour écourter ce supplice musical. 
    
    À 18h et quelques, nous arrivons finalement au petit port objectif à l'extrémité de Szczecin où nous resterons pour la nuit. 
    
    Avant toute chose, nous partons immédiatement au restaurant le plus proche, un italien à la devanture douteuse et très glauque sans aucun client à l'intérieur. Ayant la grosse flemme de trouver mieux, nous rentrons et nous nous fissurons le ventre avec une bière, une pizza et un gros plat de pâtes chacun. 
    
    Une fois ce grand festin achevé, nous retournons dans notre hôtel flottant où nous effectuons enfin un moment digestion sur des canapés dans le salon. Au milieu de cette fast bike life, nous faisons enfin une pause et nous nous autorisons même 2 épisodes de Rick et Morty saison 4 des barres de rire. Difficile d'expliquer l'émotion ressentie au moment de nos retrouvailles avec un lit après 9 nuits de tente, un grand bonheur. 
    
    Let's go au pays des merveilles. 
        `
    },
    {
        index: 36,
        latLng: [53.421123, 14.538534],
        markerId: "day36-szczecin",
        iconType: TentIcon,
        description: "Jour 36 - Szczecin Marina -> Szczecin - 13 km",
        kms: 13,
        features: {
            "nuits en Airbnb": 1,
            "jours de pluie": 1,
            "jours en vent de face": 1,
            "vrais restos": 2,
            "bières": 1,
        },
        title: "Journée Laitière à Szczecin",
        imagesName: ["PXL_20210819_091906747.jpg", "PXL_20210819_102631224.jpg", "PXL_20210819_103303444.jpg", "DSC_3990.jpg", "DSC_3991.jpg", "PXL_20210819_112132792.jpg", "PXL_20210819_112327469.jpg", "PXL_20210819_114027864.jpg", "DSC_3996.jpg", "DSC_3998.jpg", "PXL_20210819_115442912.jpg", "DSC_3999.jpg", "IMG-20210820-WA0004.jpg", "DSC_4000.jpg", "PXL_20210819_181319620.jpg", "PXL_20210819_184121400.jpg", "DSC_4003.jpg", "DSC_4004.jpg", "PXL_20210819_203037566.jpg"],
        text: `
    Levé 10h tranquille sur l'eau, aujourd'hui c'est jour de repos à Szczecin donc on est cool. Petite douche rapide puis nouveau moment de chill dans le salon du bateau à mater des programmes de cuisine et des séries polonaises cheap qui tournent en boucle sur la télé. On se fait pote avec une charmante dame qui travaille ici qui nous offre un café, un thé et des biscuits. On en profite même pour réviser notre polonais avec elle.

    Départ 12h30 après avoir salué et remercié notre nouvelle pote et son mari, direction le centre-ville situé à 10 kilomètres en passant par des voies rapides sataniques mais qui ont le mérite d'avoir une jolie vue sur la ville. 

    Première mission en arrivant en ville : aller manger dans un bar mleczny (''bar à lait'' en traduction française, qui sont des genres de cantines polonaises populaires de l'époque communiste où on peut manger vite et pas cher des plats typiques). Soupes de betteraves, patates, épinards, croquettes de viande... On ne pouvait pas faire meilleure entrée en matière dans cette ville ! 

    Plus tard, vers 15h, nous re-découvrons le bonheur et le confort d'avoir un appartement pour nous tout seul en effectuant notre check-in dans notre premier Airbnb de cette aventure. Wi-Fi avec un bon signal, bouilloire, machine à café, serviettes propres, prises électriques à portée de main, toilettes propres, canapés confortables... Le rêve de tout toureur en galère. 

    Vers 18h, Waya part chercher sa douce Irène qui arrive de Berlin en bus pendant que Kiki reste bosser à l'appart. De retour à l'appartement, Waya reçoit son cadeau d'anniversaire : une banane flambant neuve, encore plus stylée que la première, à lui de faire attention à ne plus préparer de banane perdue pour la suite de l'aventure. Une fois les retrouvailles effectuées, Irène, Waya et Kiki ne rigolent plus car ils ont faim. Ils se dirigent donc en plein centre-ville dans la rue centrale en quête d'un endroit pour se rassasier. Première tentative échouée dans un burger qui n'a plus que 2 pains en stock... Nos amis ne se découragent pas et re-tentent leur chance dans un autre burger pas loin. Cette fois-ci, ça marche : bières locales et burgers commandés, nos compères se régalent. Après ce festin, en tant que bons touristes, passage obligé au traditionnel et fameux bar PIJALNIA WÓDKI I PIWA, ancien bar communiste devenu une chaîne de bar incontournable dans toute la Pologne : il y en a au moins un dans chaque grande ville (36 au total dans le pays). Dans cette ambiance communiste d'antan, Kiki découvre les classiques shots de vodka aromatisés (Soplica) les plus classiques tels que la vodka cerise, la vodka prune et le fameux ''monte'', mélange tant apprécié des étudiants à base de vodka noisette et de lait (appelé aussi le ''nutella shot''). Une fois ces 3 loustics éméchés, ils enchaînent avec quelques parties de ''6 qui prend'' avant de rentrer à pied à l'appart et retrouver leur lit douillet.
        `
    },
    {
        index: 37,
        latLng: [53.251068, 14.439105],
        markerId: "day37-gryfino",
        iconType: TentIcon,
        description: "Jour 37 - Greifswald -> Freest -> Peenemünde -> Karlshagen - 35 km",
        kms: 27,
        features: {
            "campings sauvages": 1,
            "jours de pluie": 1,
            "pots de beurre de cacahouètes": 1,
            "franchissements de frontière": 2,
            "sandwichs": 1,
            "café": 1,
            "paires de chaussettes stylées achetées": 6,


        },
        title: "Quand Franco Rencontre Staline, ça Fait des Pierogis !",
        imagesName: ["IMG-20210820-WA0011.jpg", "IMG-20210820-WA0006.jpg", "DSC_4008.jpg", "DSC_4012.jpg", "DSC_4013.jpg", "PXL_20210820_131623802.jpg", "PXL_20210820_131900624.jpg", "IMG-20210820-WA0013.jpg", "DSC_4017.jpg", "DSC_4018.jpg", "PXL_20210820_135743666.jpg", "PXL_20210820_140206809.jpg", "PXL_20210820_144031920.jpg", "PXL_20210820_163258726.jpg", "DSC_4023.jpg", "DSC_4028.jpg", "DSC_4031.jpg", "DSC_4032.jpg", "DSC_4034.jpg", "DSC_4037.jpg", "PXL_20210820_195810922.jpg", "PXL_20210820_202505195.NIGHT.jpg", "DSC_4039.jpg", "PXL_20210820_205527911.jpg", "PXL_20210820_205536791.jpg", "PXL_20210820_205633914.jpg", "DSC_4042.jpg", "DSC_4043.jpg"],
        text: `
    Grasse matinée délicieuse jusqu'à 10h dans un lit propre et douillet après avoir passé une nuit de rêve dans notre Airbnb. 

    Après la belle nuit, vient la grosse pluie. En effet, d'après nos analyses météo-logistique, nous serons sous un gros nuage toute la journée donc les k-ways, ponchos et parapluies seront de rigueur aujourd'hui. 

    Nous passons en mode touriste sous la pluie après notre check-out à 11h. Direction un café chill repéré sur Google Maps pour rester au sec et prendre un bon petit déjeuner. 

    Croissants, thés, café, crumble, muffins nous réconfortent à l'abri de la pluie. Dès que la pluie se calme, Irène et Waya repartent dehors avec un objectif en tête : trouver un endroit pour réaliser un test covid antigénique pour qu'Irène puisse revenir en Allemagne sans problème ce soir. Après quelques recherches, appels et discussions en polonais infructueuses, ils abandonnent cette quête qui semble bien trop complexe dans ce pays sans masque. 

    Les 2 amoureux rejoignent Kiki à la cathédrale de la ville pour effectuer une pause divine après leur échec cuisant. Tels de bons touristes en Pologne, ils se rendent au centre du Rynek, vieille place du marché, situé dans le vieux centre de chaque ville polonaise. 

    Il est presque 17h et nos 3 ventres vides n'ont pas encore déjeuné. Ils rectifient vite le tir en visant une Pierogarnia, un restaurant spécialisé dans les pierogis, ces fameux raviolis traditionnels et incontournables de ce pays. Il y en a pour tous les goûts, salés, sucrés, les plus connus étant les pierogis russes à base de pomme de terre et fromage, les pierogis à la viande et les pierogis choux champignons. Une fois arrivés au restaurant après avoir visité quelques monuments de la ville, nos amis affamés effectuent plusieurs tournées de commande de pierogis pour arriver à un total d'environ une bonne quarantaine de raviolis qui satisfont largement leur ventre. 

    Avec le retour miraculeux du soleil, nous repartons à 18h pour aller récupérer nos affaires au Airbnb et se rendre à la gare pour le Flixbus de retour d'Irenita prévu à 19h.

    Après avoir attendu le Flixbus qui était en retard d'une heure, nous faisons nos adieux caliente à notre compagnon des dernières 24h et nous saluons la ville de Szczecin pour partir à 20h direction un camping repéré à 30 kilomètres. 

    Il est 21h. Nous entamons un ride nocturne sous la quasi pleine lune avec nos frontales allumées. Sur le bord de la route vers 22h, nous faisons l'étonnante rencontre avec une espèce humaine très rare en pleine action : des chasseurs de vers de terre. Cette espèce rare a la particularité de mener une double vie : chasseur de ver la nuit et pêcheur de poisson le jour. Ces 2 chasseurs nous montrent fièrement leur trophée de chasse : un seau rempli de ver de terre gluant énorme. Nous les félicitons puis remontons sur nos vélos.

    Nous poursuivons notre chemin vers notre objectif : l'entrée du parc naturel national protégeant la rivière Oder située le long de la frontière entre les 2 pays. Rivière que nous allons suivre pendant encore quelques jours côté allemand pour descendre au Sud. 

    Subitement, nous franchissons la frontière Germano-Polonaise. Pour fêter notre retour en Allemagne, nous effectuons une pause à la frontière où nous dégustons quelques Kabanos, sorte de petits saucissons polonais longs et fins. Nous ne tardons pas car il fait grave froid, environ 15 degrés, de la fumée sort même de nos bouches… On se dépêche donc de mettre le turbo pour arriver à destination. 

    Nous franchissons, pour la deuxième fois de la soirée, la frontière pour repasser côté Pologne et rejoindre le camping objectif du jour situé à encore 4km. Nous sommes vite interrompus dans notre objectif quelques mètres après la frontière lorsque nous croisons un panneau de signalisation indiquant un symbole tente à 200 mètres sur la droite. Par curiosité, nous décidons donc de suivre ce petit chemin caillouteux dans le noir pour aller voir ce que cela signifie. Nous finissons par trouver un endroit longeant la rivière qui semble être une zone de bivouac légal avec des bancs et des pierres en cercle  pour faire des feux de camp.   

    Nous décidons finalement de passer la nuit là et nous montons le camp devant la frontière polonaise avec vue sur l'Allemagne sous les attaques répétées des moustiques morts de faim. 

    Petit sandwich et dessert Kabanos avant d'aller au lit vers minuit. 
        `
    },
    {
        index: 38,
        latLng: [53.05679, 14.299153],
        markerId: "day38-schwedt",
        iconType: TentIcon,
        description: "Jour 38 - Gryfino -> Schwedt - 45 km",
        kms: 45,
        features: {
            "camports": 1,
            "concerts de musique": 1,
            "bières": 1,
        },
        title: "Waya Réalise son Rêve : Voir un Kiki Tordu",
        imagesName: ["PXL_20210821_072545962.jpg", "DSC_4045.jpg", "DSC_4047.jpg", "PXL_20210821_084540718.jpg", "PXL_20210821_094123098~2.jpg", "DSC_4051.jpg", "DSC_4052.jpg", "DSC_4054.jpg", "PXL_20210821_105143093.jpg", "DSC_4062.jpg", "DSC_4065.jpg", "DSC_4067.jpg", "DSC_4069.jpg", "DSC_4070.jpg", "DSC_4072.jpg", "DSC_4073.jpg", "DSC_4075.jpg", "PXL_20210821_165358055.jpg", "DSC_4083.jpg", "DSC_4087.jpg", "DSC_4088.jpg", "PXL_20210821_175809909.jpg", "PXL_20210821_180116367.jpg", "PXL_20210821_180739988.jpg", "PXL_20210821_184237123.jpg", "PXL_20210821_191135153.jpg", "DSC_4094.jpg", "DSC_4097.jpg", "DSC_4099.jpg"],
        text: `
    Malgré la nuit fraîche de 11-12 degrés, Waikiki a très bien dormi car il connaît maintenant les différentes techniques spéciales de sarcophagisation au sein des duvets. 

    Nous nous réveillons à 9h, vue sur la frontière et l'Oder depuis notre spot de camping stylé qui s'avère également être un spot de pêcheurs polonais après investigation. Nous prenons notre petit-déjeuner sous un magnifique ballet de centaine de canards migrateurs en formation V nous offrant le spectacle.

    En repartant vers 11h, nous passons rapide dans un petit marché pour acheter des spirales anti-moustiques, armes redoutables pour nous préparer à la guerre qui nous attend ces prochains jours autour du parc national longeant la rivière, fameux lieu de pèlerinage de milliard de familles de moustiques millénaires. Nous profitons de cette pause pour passer dans une des rares boulangeries du coin et effectuer un stock de pain pour la journée. Plus tard sur la route, nous faisons l'acquisition d'un bon gros kilo de fraises polonaises fraîches pour seulement 3 petits euros. 

    Waya réalise enfin un de ses goals life polonais en se rendant à la Crooked Forest, forêt mystique aux arbres mystérieusement tordus. D'après la légende, ces arbres seraient issus de graines mutantes. D'après la réalité, ces arbres ont été tordus par l'homme il y a une centaine d'années afin de pouvoir construire des meubles et bateaux aux formes improbables. D'après les manifestants écologiques, la forêt serait dotée d'un million d'arbres. D'après la police, seulement 400 arbres prendraient part à la manif'.

    Après s'être estomaqué plusieurs minutes sur la forme disruptive de ces arbres, Waya et Kiki repartent et reviennent sur leurs pas pour rejoindre les rives de l'Oder du côté allemand (bien plus sympa et aménagé que le côté polonais). 

    À 14h, juste après avoir franchi la frontière pour se rendre en Allemagne, nous nous retrouvons en plein milieu d'une fête de village allemande, avec de la bière et des saucisses coulant à flot, endroit rêvé pour une pause déjeuner de qualité. Nous effectuons donc un arrêt stratégique dans cette Comté après 17 km dans les jambes et célébrons cela tel de bons Hobbits : grosse commande de 2 bières, 2 backfisch, 1 poisson grillé, 1 anguille grillée et une patate au four. Waya et Kiki se cassent le ventre tout en profitant des douces chansons paillardes chantées par des Hobbits musiciens. Cet endroit magique les propulse tout droit en plein cœur de la culture allemande. Sous prétexte que ''les gâteaux fait maison sont à 1 euro" et qu' "il a 4 euros en pièce", Kiki achète 4 gâteaux qu'il ramène avec fierté à son compagnon de route. 

    Après 4h de chill dans la foire et 3-4 pauses toilettes, c'est reparti pour un ride de coucher de soleil dans le parc national allemand le long de la frontière et de la rivière. 

    Sur la route, nous ajoutons un objet de plus à notre collection d’objets perdus/oubliés : la gourde de vélo de Kiki laissée pleine d'eau dans les toilettes de la foire. "Ça tombe bien, je voulais justement la changer", s'exclame Kiki plein de mojo positif.

    Nous retrouvons avec grand plaisir nos pistes cyclables allemandes toutes propres et ridons dans le futur à toute allure accompagnée de la bande son originale de Tron réalisée par Daft Punk (aucun de nous 2 n'a pourtant vu ce film). Nous finissons par trouver un camport stylé à 21h pour poser la tente après avoir parcouru 45 km tranquillou. 

    Une fois le campement monté dans le noir, place au dîner où nous réalisons justement que nous avons 1 kg de fraises qui nous attend dans le sac et qui commence à se transformer doucement en purée avec le voyage. Une chose est certaine, elles ne tiendront pas la nuit… Plus le choix, nous faisons face à une situation d'urgence, il va falloir faire appel aux talents culinaires de Waikiki pour manger et finir ce kilo de fraises <i>sur le champ</i>. Pour cela, plusieurs variantes sont proposées au menu : fraise nature dans son jus, bruschetta de fraise et beurre de cacahuètes, toast de fraise sur lit de pâte à tartiner chocolat-noisette allemande, fraise enrobée dans une fine pellicule de chocolat noisette (<a class="href" target="_blank" href="https://www.germanshop24.com/groceries/breakfast-and-breads/spreads/nudossi-hazelnut-spread-7.05-oz/">Nudossi, la pâte à tartiner du bon communiste qui vous fera oublier la dictature</a>), purée de fraise arôme beurre de cacahuètes, jus de fraise vieilli dans un ancien fut de beurre de cacahuètes. 

    Après ce voyage gastronomique réalisé et ce kilo de fraises consommé, nos 2 amis rejoignent leur tente et se fendent la fraise sur 2 épisodes de Rick et Morty saison 4 avant de rejoindre le pays des fraises. 
        `
    },
    {
        index: 39,
        latLng: [52.845403, 14.126611],
        markerId: "day39-osinow",
        iconType: TentIcon,
        description: "Jour 39 - Schwedt -> Osinów Dolny - 39 km",
        kms: 39,
        features: {
            "camping normal": 1,
            "jours de pluie": 1,
            "vrais restos": 1,
            "franchissements de frontière": 1,

        },
        title: "La Bénédiction de Père Nature",
        imagesName: ["DSC_4101.jpg", "DSC_4104.jpg", "DSC_4106.jpg", "DSC_4109.jpg", "DSC_4110.jpg", "DSC_4113.jpg", "DSC_4117.jpg", "PXL_20210822_133650608.jpg", "PXL_20210822_133657781.jpg", "PXL_20210822_133729874.jpg", "PXL_20210822_134122092.jpg", "PXL_20210822_140842818.jpg", "DSC_4120.jpg", "DSC_4126.jpg", "PXL_20210822_160408471.jpg", "DSC_4127.jpg", "DSC_4131.jpg", "DSC_4132.jpg", "DSC_4134.jpg", "DSC_4135.jpg", "DSC_4138.jpg", "DSC_4141.jpg", "DSC_4144.jpg", "DSC_4145.jpg", "PXL_20210822_172233609.jpg", "DSC_4147.jpg", "DSC_4148.jpg", "PXL_20210822_183724771.jpg", "PXL_20210822_183748872.jpg", "DSC_4151.jpg", "PXL_20210822_192017195.jpg", "DSC_4156.jpg"],
        text: `
    Levé tranquille à 10h dans la tente qui est peu à peu en train de se transformer en sauna au soleil. 

    Waya le papi est furieux, il a mal dormi à cause des satanés jeun's écoutant du boom boom toute la nuit à côté du camping sous prétexte qu'ils célèbrent un anniversaire le Samedi soir. Sauf oubli de sa part, Waya ne se souvient pas avoir réalisé de telles soirées irrespectueuses de débauche durant sa jeunesse. 

    Bref, heureusement qu'une douche et un petit déjeuner à base de Nudossi sont là pour le réconforter. Nous profitons de ce camport chillax pour faire un gros nettoyage de printemps de nos vélos. Pendant plus d'une heure, nous dégraissons et lavons les moindres recoins de nos bébés et plus particulièrement la chaîne, le dérailleur et la cassette. Après une session de finition massage des vélos à l'huile de coco, nous repartons casser la route avec nos bolides tous neufs. 

    Après seulement 500 petits mètres parcourus, nous croisons une petite fête de village allemande célébrant l'anniversaire de Pippi Langstrumpf (plus connue sous le pseudonyme ''Fifi Brindacier'' en France). Occasion parfaite pour s'arrêter sachant qu'il est l'heure du déjeuner et que des Bratwurst (saucisse allemande) sont vendues en masse. Après une saucisse, un burger, des frites et une négociation musclée entre Kiki et la vendeuse de saucisse pour la convaincre de nous donner 2 ecocup, nous repartons sur la route en espérant que plus rien ne se mettra en travers de notre route à présent. 

    Bien entendu, c'est raté lorsque nous croisons le chemin d'un toureur plus que spécial 2 km plus loin. Nous faisons la rencontre d'Éric, un toureur et backpackeur allemand très expérimenté à l'allure de sans-abris d'environ 60 ans. Il a une grosse barbe blanche, porte un pyjama troué et des jumelles autour du cou car c'est un grand fan d'oiseaux, il aurait même croisé la route d'un Martin pêcheur hier. En plus d'avoir son vélo chargé comme une mule avec des sacs accrochés dans tous les sens, il traîne également derrière lui un chariot contenant ses plantes vertes qu'il transporte avec lui, 140 kilos de chargement au total, complètement farci le gars. Il nous explique qu'il a planté les graines de ses plantes il y a 3 mois, moment même où il est parti de chez lui à vélo. Eric étant un grand campeur sauvage expérimenté, il nous raconte des histoires abracadabrantesques de lui, son duvet et la police à travers le monde. Il dort tous les jours en camping sauvage au culot dans des endroits pas vraiment discrets, sous des ponts et des abris de pêcheurs... Quand nous lui demandons quel est son secret pour ne pas se faire attraper par la police, il nous répond littéralement ''I don't care, I am the nature''. Estomaqués d'apprendre que la nature est en fait un clodo qui se balade à vélo en pyjama troué, nous poursuivons l'interrogatoire avec ce curieux personnage. Malheureusement, une tempête approchant nous force à écourter notre enquête. Nous nous dépêchons donc de capturer ce moment intense avec le roi de la nature, nous le saluons puis lui souhaitons bonne chance pour ses 5 prochains mois de voyage et la tempête qui arrive vite. 

    Nous repartons pour 10 km interminables sous la pluie avec nos ponchos de compétition le long de la rivière. Une fois ces kilomètres et la tempête passés, nous effectuons une pause Snickers pour nous redonner des forces et repartir à fond pour les 15 kilomètres restants dans le parc national avec nos amis les bambis. 

    Le camping objectif est situé à la frontière côté polonais donc nous franchissons la frontière vers 19h sur un des rares ponts qui relie les 2 pays. 

    Nous débarquons dans une immense zone commerciale duty free satanique située juste derrière la frontière. Cette zone marchande polonaise existe car nous sommes situés au point de la frontière le plus proche de Berlin qui est à moins de 100 km en voiture. Cigarettes, alcool, légumes, nains de jardins et produits en tout genre sont vendus par milliers dans une ambiance de parc d'attraction abandonné de film d'horreur. Même si la plupart des magasins sont fermés car c'est Dimanche, nous pouvons facilement imaginer les foules d'allemand se jeter sur les promotions et remplir leur voiture de matériel inutile. 

    Nous faisons un tour rapide au camping qui est juxtaposé à cette zone démoniaque. En arrivant là-bas, pas de réception, seulement un mini-golf et des dizaines de camping-cars stationnés là. On croise un groupe d'allemands saouls de 50 ans qui nous apprend que c'est un camping gratuit et que nous pouvons poser la tente sans problème, bon délire… 

    Une fois ce repérage effectué, nous réussissons à trouver un restaurant typique polonais ouvert un peu plus loin où nous nous rendons pour dîner vers 20h. Au programme : des pierogis russes, une soupe goulash, un Bigos (spécialité polonaise avec de la choucroute et des saucisses) et une galette de pomme de terre avec une viande en sauce. 

    En rentrant au camping après ce bon dîner, nous découvrons qu'il y a une zone aménagée pour faire des feux de camp juste à côté de l'endroit où nous avons planté la tente. Nos 2 amis étant des saisisseurs d'opportunité reconnus dans le métier, il leur faut peu de temps pour se convaincre mutuellement de réaliser un gros bûcher. Kiki retourne donc au duty free à 21h pour aller acheter du bois et des chamallows (oui, tout se vend là-bas et à n'importe quelle heure, 24/24, 7/7). Une fois Kiki de retour avec son gros sac de petit bois (mais sans chamallows :(), il prépare le feu pendant que Waya prépare la playlist ''Best Epic Emotional Bivouac Music Ever''. Ces 2 fratés finissent donc la journée par cette touche chaleureuse et réconfortante au milieu de la nuit noire et du froid cosmique. 

    Chamallow sur le gâteau, nous terminons cette journée avec 2 épisodes traditionnels de Rick et Morty saison 5 dans notre petite tente.

    Bisou jeunes entrepreneurs. 
        `
    },
    {
        index: 40,
        latLng: [52.584346, 14.612022],
        markerId: "day40-neubleyen",
        iconType: TentIcon,
        description: "Jour 40 - Osinów Dolny -> Neubleyen - 61 km",
        kms: 61,
        features: {
            "camping normal": 1,
            "jours de pluie": 1,
            "franchissements de frontière": 3,
            "sandwichs": 1,
            "café": 1,
            "flashs de vodkas": 1,
            "frites": 1,

        },
        title: "Rencontre du Troisième Type",
        imagesName: ["DSC_4157.jpg", "DSC_4161.jpg", "DSC_4162.jpg", "DSC_4166.jpg", "DSC_4170.jpg", "PXL_20210823_110658014.jpg", "PXL_20210823_115926367.jpg", "PXL_20210823_120025253.jpg", "PXL_20210823_133547845.jpg", "DSC_4186.jpg", "DSC_4187.jpg", "DSC_4188.jpg", "PXL_20210823_162815830.jpg", "PXL_20210823_170327622.jpg", "PXL_20210823_170421619.jpg", "DSC_4193.jpg", "PXL_20210823_174421357.jpg", "PXL_20210823_175034356.jpg", "PXL_20210823_175427087.jpg", "DSC_4204.jpg", "PXL_20210823_184117268.jpg", "PXL_20210823_184329385.jpg", "PXL_20210823_184415800~2.jpg", "PXL_20210823_184422320.jpg", "PXL_20210823_184454033.jpg", "DSC_4208.JPG"],
        text: `
    Après cette nuit pluvieuse, nous nous levons à 10h et constatons avec tristesse que le ciel est, une fois de plus, gris et qu'il va pleuvoir dans une heure… En effet, un énorme nuage de tempête recouvre tout le sud de la Pologne et de l'Allemagne. De la pluie, de la pluie et encore de la pluie. Nous commençons à être habitué à cet été inexistant. Ajouté à cette nouvelle réjouissante, Kiki a le bonheur de découvrir qu'un de ses sacs d'habit, laissé sous la protection de tente pour la nuit, est trempé par la pluie. Pluie, pluie et encore pluie ! Il pleut, il mouille, c'est la fête à la citrouille. Début de journée pas facile pour ces 2 aventuriers. 
    
    Néanmoins, nos 2 fratés ne se laissent pas abattre et partent affronter cette journée pleine de surprises en commençant par un tour endiablé dans la zone commerciale de l'enfer. 
    
    En cherchant une boulangerie pour le petit déjeuner, nos 2 loustics, fragilisés par ce début de journée mojoless, ne peuvent malheureusement pas résister à la puissante force d'attraction capitalistique qui les attire tout droit dans les rayons démoniaques. Ils errent donc, perdus au milieu des promotions et produits internationaux importés du bout du monde… Coca vanille cerise, céréales oreos, boisson chupa chups…  leur cerveau lobotomisé est clairement stimulé par tous ces signaux sucrés les submergeant. Nos 2 amis se battent plus que jamais et abandonnent même l'idée d'acheter un magnifique paquet de céréales Pokémon à l'effigie de Pikachu à 14 euros. Ils arrivent finalement à s'enfuir de ce guet-apens sans trop de dégâts… Ils repartent seulement avec les acquisitions suivantes : un paquet de mochis Taïwanais, un flash de vodka à la figue (vodka préférée de Waya), des Kabanosy polonais, un fromage polonais, un jambon sec polonais et du pain polonais. Il se dépêche de fuir la Pologne pour retrouver un peu de calme du côté allemand. 
    
    Une fois la frontière passée, une grosse tomate bio achetée dans un Hofladen et 10 kilomètres dans les jambes, c'est l'heure des sandwichs.  
    
    Après la pause, il fait froid, pas beau, on a le vent de face et, pour couronner le tout, nous nous dirigeons tout droit vers la tempête. Pas facile de garder le mojo avec tous ces combos négatifs. Nos 2 compères n'ont plus le choix, ils font donc usage de 2 cartouches de concentré de mojo d'urgence, 2 shots de vodka figue et ça repart comme sur des roulettes pour affronter le sheitan.  
    
    Un peu plus tard sur la route, après 30 kilomètres, nous apercevons une lueur de mojo positif lorsque nous tombons par hasard sur 2 grandes balançoires sorties de nulle part et un café en hauteur situé dans un ancien bâtiment portuaire. Après avoir joué avec la balançoire pendant plusieurs minutes, nous nous autorisons une pause gourmande dans le café au 3ème étage que nous monopolisons de 16h à 18h. Cette pause sucrée nous permet entre autre de recharger nos batteries mentales et électriques pour mieux repartir.
    
    Quelques minutes après avoir repris la route, nous faisons la rencontre de Mathias qui nous rejoint à vélo sur notre chemin. Ce toureur expérimenté va dans la même direction que nous cet après-midi donc nous décidons de rider ensemble en papotant côte à côte. 
    
    Cet Allemand, originaire de Stuttgart, a décidé de laisser tomber son vélo électrique ainsi que sa femme pour réaliser le tour de son pays avec son vieux vélo en suivant la frontière et la côte, 5000 km de trajet au total et il a déjà parcouru 2500 km en seulement 1 mois avec des pauses, le mec est chaud. Ce développeur IT freelance a donc effectué quasiment le même itinéraire que nous sur la côte allemande et sur la frontière germano-polonaise ce qui nous donne plusieurs points en commun. 
    
    Il nous raconte son passé, l'étrange époque où il traversait chaque année le rideau de fer pour se rendre côté Est pour voir des amis et de la famille. Il nous partage également son incroyable ressentiment lorsqu'il se balade pour la première fois libre à vélo du côté soviétique en 90, 1 an après la chute du mur. Une seconde moitié de pays si différente de la sienne où l'architecture, les routes, la nourriture et même les mentalités n'ont rien à voir avec ce qu'il a connu du côté ouest. Il nous raconte que c'était comme si l'Allemagne de l'Est de l'Ouest était deux pays complément distincts.
    
    Sa destination finale se situe dans un camping localisé dans un marché polonais après la frontière (marché démoniaque similaire à celui où nous étions hier et ce matin). Comme ce camping n'est pas très loin de notre point d'arrivée et que Mathias est grave cool, nous décidons de modifier notre planning et d'aller passer la nuit au camping polonais où il se rend. 
    
    Après une vingtaine de kilomètres parcourus ensemble à papoter, nous nous retrouvons immédiatement stoppés sur le pont reliant les 2 pays lorsque nous essayons de franchir la frontière pour se rendre du côté polonais…  En effet, un chaleureux coucher de soleil nous barre la route et nous empêche d'aller plus loin. Impossible pour nous de rater cette occasion de revoir enfin le soleil qui fait son apparition sous la couche uniforme de nuage gris. 
    
    Suite à ce léger et doux apport en vitamine C de fin de journée, nous saluons une ultime fois l'Allemagne et suivons Mathias pour le dernier kilomètre vers le camping. Nous sommes extrêmement tristes de quitter ce pays une bonne fois pour toutes et nous demandons quand est-ce que l'on pourra y retourner. Une fois arrivé proche du marché polonais vers 20h, il demande son chemin à un polonais croisé sur la route. Ces 2 là commencent à discuter ensemble en allemand lorsqu'un 3ème interlocuteur polonais les rejoint, la discussion ne semble pas être triviale. Après quelques minutes de discussion Germano-polonaise intense, notre nouvel ami revient vers nous embarrassé et nous indique qu'il n'y a en fait aucun camping ici et qu'il s'est probablement trompé… la tuile… surtout qu'il commence à faire nuit et froid. On lui propose de repasser côté allemand pour aller au camping que Kiki avait repéré plus tôt dans la journée à 5 km de là, ce qu'il accepte avec plaisir. Lumières sur le front, nous franchissons donc la frontière à 3 pour la 3ᵉ fois de la journée et retrouvons à nouveau l'Allemagne que nous pensions ne jamais revoir. 
    
    Nos 3 compères arrivent finalement dans le petit camping allemand vers 21h puis posent la tente en prenant la précaution de sécuriser au préalable la zone avec les fameuses spirales fumantes anti-moustiques et anti-mauvaises ondes. 
    
    Pour le dîner, Waya se lance dans la préparation d'un plat de maître : pâtes salées accompagnées de tranches de jambon et de fromage polonais. Waya et Kiki dégustent ce repas tout en discutant de tout et de rien avec leur nouveau fraté. Les voyants manger par terre tels des primates de forêts tropicales, Mathias a de la peine pour ces 2 singes et leur prête sa chaise de campeur professionnel qu'ils prennent bien sûr soin de partager à temps d'utilisation égal (côté communiste oblige). 
    
    Au détour d'une discussion, nous nous rendons même compte que notre nouveau compagnon nous a déjà croisé dans un camping côté mer du Nord il y a une vingtaine de jour lorsque nous discutions avec nos doubles du futur (cf. J21), une histoire de plus à ajouter à la collection des histoires Truman Show complètement improbable. 
    
    Shots de vodka figue consommés, numéros échangés, dégustation de mochis réalisée, vaisselle effectuée, tout est rassemblé pour rejoindre nos duvets d'amour. Nous partirons peut-être ensemble demain direction le marché polonais...
        `
    },
    {
        index: 41,
        latLng: [52.51249, 15.160705],
        markerId: "day41-miechow",
        iconType: TentIcon,
        description: "Jour 41 - Neubleyen -> Miechow - 57 km",
        kms: 57,
        features: {
            "campings sauvages": 1,
            "sandwichs": 1,
            "flashs de vodkas": 2,
            "franchissements de frontière": 1,
        },
        title: "Une Bonne Côte Entre-Potes",
        imagesName: ["DSC_4209.jpg", "DSC_4210.jpg", "DSC_4211.jpg", "PXL_20210824_093141886.jpg", "PXL_20210824_095129743.jpg", "PXL_20210824_095134736.jpg", "DSC_4212.jpg", "PXL_20210824_115810013.jpg", "DSC_4217.jpg", "DSC_4218.jpg", "PXL_20210824_130159747.jpg", "PXL_20210824_131003459.jpg", "DSC_4219.jpg", "PXL_20210824_133948682.jpg", "DSC_4224.jpg", "DSC_4230.jpg", "DSC_4231.jpg", "PXL_20210824_152049149.jpg", "DSC_4234.jpg", "DSC_4235.jpg", "DSC_4238.jpg", "PXL_20210824_172404157~2.jpg", "PXL_20210824_172857852.jpg", "PXL_20210824_173527600~2.jpg", "PXL_20210824_174619106.jpg", "PXL_20210824_174820211.jpg", "PXL_20210824_175737659.jpg", "DSC_4243.jpg", "DSC_4244.jpg"],
        text: `
Levé 9h avec une très bonne nouvelle : le ciel est bleu, le soleil fait enfin son grand retour ! 
        
Notre nouvel ami Matthias nous casse instantanément notre délire lorsqu'il nous exprime son inquiétude quant à un gros orage arrivant sur nos têtes à partir de demain et pour les prochains jours. Il va même jusqu'à décider brutalement de mettre en pause son voyage à partir d'aujourd'hui, de louer une voiture et de rentrer chez lui à Stuttgart en attendant que la tempête passe… Waya et Kiki, anéantis par cette nouvelle, saluent donc leur nouvel ami qui s'en va en préférant choisir la sécurité cette fois-ci.  
        
Au diable les rumeurs de tempête ! Nous préférons nous délecter de ce doux moment d'allégresse chatoyante plutôt que de nous morfondre sur des prévisions affabulatrices. Après quelques étirements et séances de yoga ensoleillés, nous partons sur la route à 12h en espérant que la fameuse tempête ne soit qu'invention et mensonge. 
        
Nous sommes immédiatement stoppés dans notre lancée par le restaurant du camping, seule et dernière opportunité pour nous de déguster un poisson fumé allemand. D'autant plus que ce camping est réputé pour sa délicieuse poiscaille. Nous commandons donc 1 demi anguille et 1 portion de saumon accompagnés de salade et de légumes -> un délice ! Dommage que le saumon vienne d'un élevage en Norvège… nous repartons après que le boss nous ait appris le procédé de fumage des poissons réalisé dans le camping. 
        
Nous repassons une nouvelle et, très probablement, dernière fois la frontière pour se rendre définitivement en Pologne. Au revoir les poissons fumés, fischbröt, backfisch, bratwurst et kartoffel ! 
        
Nous traversons rapidement la zone commerciale de la frontière en n'oubliant pas d'acheter 2 flashs de vodka, une à la noisette et l'autre au citron nous permettant de gérer les situations d'urgence. Puis, nous entamons quelques kilomètres sur une voie rapide pas vraiment vélo friendly pour rejoindre la nature et les petits villages polonais. 
        
Aujourd'hui, beaucoup de dénivelé positif nous attendent sur les collines polonaises, nous nous préparons donc psychologiquement à dompter la colline en démarrant le fameux mix de Boris Brejcha du gala des 100 jours 2016.   Après seulement quelques mètres de dénivelé effectués et le premier drop de Boris pas encore atteint, la roue arrière de Kiki crève malencontreusement à cause d'un bout de verre de bouteille de vodka posé sur la piste cyclable. Nous nous protégeons du soleil à l'ombre et engageons donc une réparation de l'extrême accompagné d'une petite valse de Chopin (pianiste polonais le plus célèbre) pour adoucir nos mojos. Après une réparation digne de masterchef, nous nous remontons sur nos bolides et partons affronter les côtes qui nous attendent. 
        
Pendant une vingtaine de kilomètres,  nous traversons plein de petits villages mignons et non abandonnés au soleil et notamment la petite ville d'Ósno Lubuskie où nous faisons le plein de charcuterie et de fromage polonais pour les sandwichs du soir. 
        
Après plus de 40 kilomètres, nous effectuons une pause divine miel beurre de cacahuètes devant l'église d'un village où le pasteur de l'église vient même réaliser un gros transfert de mojo en nous serrant la main et en nous félicitant pour notre <b>effort herculéen</b>. Ainsi, nous dévorons nos tartines sacrées afin de nous procurer un pouvoir divin démesuré pour la suite de l'aventure. 
        
Nous reprenons la route vers 18h après cette pause spirituelle sucrée revigorante et nous nous mettons tranquillement à la recherche d'un endroit pour dormir. Après 57 km ridés, nous tombons soudainement sur le spot de camping parfait pas loin de la route à côté d'un champ non cultivé. Sol plat et dégagé, pas de vis-à-vis, caché depuis la route par des petits pins tous mignons, vue incroyable sur le coucher de soleil dans les champs, pas de moustiques, pas d'araignées… nous posons immédiatement la tente sur ce spot paradisiaque. 
        
Bonus non négligeable : une antenne 4G située à une centaine de mètres nous permet même de regarder le premier épisode de Koh Lanta La Légende sans pression en qualité supérieure. 
        
Après un coucher de soleil de qualité, un dîner à base de conserve de maïs et sandwichs et un épisode de Koh Lanta très fort en émotions, nous nous momifions dans nos duvets tels des pharaons égyptiens en empilant les couches pour affronter la nuit la plus froide que nous n'ayons jamais connu : une nuit glaciale descendant à 8°C ! 
        
Une fois enterrés dans nos tombes, nous prenons un ticket aller direct vers le pôle Nord. 
        `
    },
    {
        index: 42,
        latLng: [52.494683, 15.745509],
        markerId: "day42-pszczew",
        iconType: TentIcon,
        description: "Jour 42 - Karnatów -> Pszczew - 59 km",
        kms: 59,
        features: {
            "camping normal": 1,
            "sandwichs": 1,
            "vrais restos": 1,
            "café": 1,
            "frites": 1,
        },
        title: "Echappée Polyglote",
        imagesName: ["DSC_4246.jpg", "DSC_4248.jpg", "DSC_4250.jpg", "PXL_20210825_101328875.jpg", "PXL_20210825_110422006.jpg", "DSC_4253.jpg", "PXL_20210825_123855652.jpg", "DSC_4256.jpg", "DSC_4257.jpg", "PXL_20210825_132217260.jpg", "PXL_20210825_132527979.jpg", "PXL_20210825_132547037.jpg", "DSC_4263.jpg", "DSC_4264.jpg", "DSC_4267.jpg", "DSC_4271.jpg", "DSC_4282.jpg", "DSC_4285.jpg", "PXL_20210825_145155929.jpg", "PXL_20210825_170259711.jpg", "PXL_20210825_184159077.jpg"],
        text: `
    Lever ensoleillé à 9h dans notre spot de camping sauvage 4*. On kiffe tellement cet endroit que nous restons sur le spot pour chiller quelques heures et partons vers midi direction un lac situé à quelques mètres plus bas pour prendre notre petit déjeuner. 

    Arrivés au lac, nous nous installons sur une petite barque retournée pour un petit déjeuner brunch 4* vue sur le lac. Au menu, celui que l'on ne présentera plus, l'incontournable combo beurre de cacahuètes miel étalé sur du pain polonais de l'avant-veille. Avec en dessert, les restes de salami polonais achetés hier à la boucherie. Kiki profite même de ce confort 4* pour effectuer sa pause toilette journalière près d'un arbre 4*. 

    Suite à quelques débats sur la position corporelle optimale à avoir lors de grosses commissions (réponse : accroupie), nous reprenons la route et enchaînons les vallons à fond les ballons tout en papotant philosophie mojo. 

    Après l'achat d'une bouteille d'eau, 2 pauses Snickers et une pause forêt, nous atteignons déjà les 36 km vers 17h. Nous décidons de faire une pause plus sérieuse dans la plus grande ville située sur notre parcours du jour : Międzyrzecz (prononcé Myindzédjetch). Nous appliquons le protocole habituel pour une pause citadine réussie : 

Étape 1 : Ouvrir Google Maps ;
Étape 2 : Effectuer la recherche ''coffee'' à une distance de 2-3 km de l'itinéraire prévu ;
Étape 3 : Sélectionner le café le mieux noté ;
Étape 4 : Vérifier que ledit café possède des canapés maxi confort et qu'il est ouvert pour les 4 prochaines heures. 
Étape 5 : Si les 2 dernières conditions sont réunies, foncez dans ledit café les yeux fermés, vous ne serez jamais déçus. Si non, retourner à l'étape 3 et sélectionner le café suivant. 

    Grâce à ce protocole élémentaire, nous trouvons un café de rêve avec, en bonus, un détail qui fait la différence pour tout galérien expérimenté : des prises électriques disponibles et situées à moins d'un mètre des canapés maxi confort. 

    Pour ce goûter, nous commandons 2 parts de gâteaux, un classique crumble pour Kiki et une tarte au caramel beurre salé accompagné du traditionnel cappuccino pour Waya. 

    Cette pause goûter se transforme soudainement en pause dîner lorsque Kiki effectue la commande de 2 gros burgers vers 19h. Une fois ces burgers engloutis, nous nous dépêchons de partir car un gros nuage de pluie se dirige vers nous. Décollage à 20h pour un ride nocturne d'une dizaine de kilomètres qui nous permettra de rejoindre un camping situé à Pszczew (imprononçable) le long d'un lac. 

    Cependant, un problème d'envergure survient au moment du décollage, Waya se rend compte que le compteur officiel du Waikiki Tour situé sur son guidon a malencontreusement été réinitialisé. Plus de 1800 km au compteur parti en fumée (1822 d'après nos estimations). Malgré le fait que tous les kilométrages de chaque étape aient bien entendu été sauvegardés jour après jour, ce précieux compteur était d'une précision sans faille et Waikiki s'en servait pour célébrer les grands moments et grandes étapes officielles. 

    Waya et Kiki, chamboulés par cette nouvelle, repartent sur la route et se lancent dans des calculs de coin de table pour résoudre ce problème : s'ils voulaient atteindre à nouveau ces 1800 km sur le compteur, il leur faudrait tourner manuellement les pédales (vélo à l'envers) à grande vitesse pendant environ 80h en prenant en compte le ratio de vitesse le plus élevé, la taille des roues et la force démesurée de Waikiki… à raison de 4h par jour de pédalage à la main en se relayant, il leur faudrait 20 jours… temps que ces 2 jeunes hommes n'ont bien évidemment pas… il va falloir s'y faire, nous ne pourrons pas obtenir à nouveau le total exact sur ce compteur… 

    Après quelques calculs mentaux supplémentaires et une quinzaine de kilomètres, nous atteignons le lac en question à 21h où nous avons le choix entre 3 campings. Après discussions en polonais et investigations dans ces campings, nous comprenons rapidement que ces campings n'acceptent pas les tentes, seulement les campings cars et bungalows, triste concept… Nous tentons donc notre chance dans un 4ème camping situé un peu plus loin sur le lac.

    Malheureusement, nous sommes accueillis par un gardien qui ne parle pas un mot d'anglais. Une fois les présentations faites en polonais, nous apprenons que notre interlocuteur parle également italien. Waikiki utilise donc ses compétences linguistiques polyglottes pour expliquer à ce monsieur qu'il souhaite camper ici avec une tente piccolo. Bravissimo ! La communication passe multo bene, nous payons la nuit et le gardien nous indique la zone où poser notre pizza quattro formaggi. 

    Nous installons vite le campement sous la pluie fine puis go à la douche pour être tout propres dans les duvets. Nous sommes interrompus en plein milieu de notre douche par le 2ème gardien qui débarque soudainement dans les sanitaires. Celui-ci,  qui ne parle malheureusement ni italien ni anglais, tente tant bien que mal de nous expliquer quelque chose incompréhensible. Waya tente un passage par le polonais avant de comprendre 5 minutes plus tard que cette personne ne parle pas du tout polonais, mais que son charabia est en fait issus d'un des nombreux autres dialectes russes : L'Ukrainien (lol jpp frr je comprend R) ! 

    Après 10 minutes de mimes à poil et discussions sans queue ni tête, nous lui suggérons d'utiliser la technologie Google Translate pour s'exprimer ce qu'il s'empresse donc de faire. Il parle en russe à son téléphone qui exclame alors en français oral la phrase suivante : ''je veux vous donner la lumière''. Wow ! Tout ça pour ça ! Nous comprenons qu'il veut en fait tirer un câble jusqu'à notre tente pour y installer un spot de lumière. Waikiki explose de rire et le remercie en lui indiquant gentiment que nous n'avons pas besoin de lumière car nous avons déjà accès à cette technologie de pointe grâce à nos lumières frontales (certes défaillantes par moments !). 

    Nous saluons ce fraté dans toutes les langues du monde puis partons nous coucher en slalomant entre les gouttes de pluie. 

    Pour se reposer un peu et profiter des lacs, nous avons prévu de prendre un jour de congé demain au camping. Malheureusement, après vérification, le temps va encore être pourri ces prochains jours… nous nous endormons en priant pour que la météo soit au moins clémente le temps de notre jour de repos. 
    `
    },
    {
        index: 43,
        latLng: [52.494919, 15.745361],
        markerId: "day43-pszczew2",
        iconType: TentIcon,
        description: "Jour 43 - Pszczew -> Pszczew - 10 km",
        kms: 10,
        features: {
            "camping normal": 1,
            "sandwichs": 1,
            "café": 1,
            "plats de pâtes": 1,
            "jours de repos": 1,
            "pots de beurre de cacahouètes": 1,
        },
        title: "Objectif Pédalo !",
        imagesName: ["PXL_20210826_090247334~3.jpg", "DSC_4288.jpg", "DSC_4289.jpg", "DSC_4295.jpg", "DSC_4297.jpg", "DSC_4299.jpg", "DSC_4302.jpg", "PXL_20210826_123259833.jpg", "DSC_4304.jpg", "DSC_4306.jpg", "DSC_4309.jpg", "PXL_20210826_164251162.jpg", "PXL_20210826_164318717.jpg", "PXL_20210826_164658303.jpg", "PXL_20210826_170403902.jpg", "DSC_4314.jpg", "PXL_20210826_175604737.jpg", "DSC_4318.jpg", "DSC_4320.jpg"],
        text: `
    Réveil à 9h aujourd'hui après une nuit pluvieuse. Aujourd'hui, c'est les vacances, rien de prévu au planning, pas de prise de tête, pas de course contre la montre, pas de côtes endiablées, seulement du chill et de la farniente au programme… 
    
    Pendant que Kiki entame la journée en chillant fort dans la tente, Waya part laver ses caleçons sales sous les conseils affûtés de la vénérable Wejdene. En revenant à la tente vers 11h, Waya retrouve Kiki dans un sale état… Il tremble, angoisse et n'arrive pas à accepter qu'aucune molécule d'endorphine ne sera sécrétée au sein de son cortex primaire aujourd'hui. C'est un fait, Kiki est devenu complètement accro au coup de pédale. Pour étancher sa soif de drogue, ce junkie trouve un stratagème de génie : proposer à Waya d'aller faire du pédalo sur le lac. Afin de porter secours à son compagnon en danger de mort, Waya valide immédiatement cette idée remplie de mojo. 
    
    Kiki se rend donc à la réception pour supplier la personne de l'accueil de lui prêter un pédalo, un kayak, un canoë ou tout autre moyen de locomotion lagunaire lui permettant de mettre en mouvement ses quadriceps de taureau. Grande déception pour Kiki lorsqu'on lui annonce que les locations sont fermées jusqu'à 17h aujourd'hui. Kiki, accablé, revient bredouille à la tente pour annoncer la mauvaise nouvelle à son collègue.
    
    Nous nous rendons donc au camping voisin pour tenter à nouveau notre chance vers 12h. Pas de bol une fois de plus, c'est la même, tout est fermé, il semblerait que ce soit déjà la fin des vacances pour les Polonais. 
    
    Nous boycottons donc ce lac dépourvu de fun et partons en direction du centre-ville à vélo pour faire des courses, de vélo et voir ce que nous réserve le 2ème lac de la ville.  
    
    Une fois notre sac rempli de victuailles, nous trouvons un banc spirituel autour du 2ème lac pour déguster de délicieux sandwichs made in Waikiki. Le projet pédalo tombe rapidement à l'eau lorsque des brises de vent venues tout droit du pôle Nord nous hérissent les poils du sgeg. 
    
    Le projet pédalo est donc rapidement reconverti en projet pause gourmande caféinée. Nous appliquons donc le protocole habituel et trouvons un café posé au milieu de la ville. Une fois arrivés au café, nous mettons en place le classique combo thé + cappuccino + gourmandise + recharge des batteries nous procurant une grosse montée de mojo. Malheureusement, toutes les bonnes choses ayant une fin, nous nous faisons sortir du café par la serveuse à la fermeture à 18h après 3 bonnes heures de chill. 
    
    Cette pause revigorante nous a enfin permis de trouver un projet à la hauteur de cette journée pleine d'action : cuisiner des pierogis pour ce soir. Nous achetons donc 800g de pierogis, 400g de pierogis russes et 400g de pierogis kapusta i grzeb (chou et champignons). 
    
    Sur la route de retour au camping, nous remarquons que le pneu arrière de Kiki est quasi plat. Ceci signifie que notre réparation effectuée il y a quelques jours suite à la crevaison est littéralement à chier. En effet, nous avions utilisé une vieille chambre à air réparée à l'arrache pour effectuer cette opération délicate. Cependant, suite à cette journée pleine de rebondissements, nous sommes atteints d'une puissante flemme, impossible donc de mettre en place une action corrective instantanée. Le camping étant à seulement 2 km, nous préférons procrastiner et reporter cette action corrective à demain matin. Heureusement que 2 shots de vodka noisette sont là pour nous faire oublier rapidement  cette mésaventure. 
    
    De retour au camping, nous décidons soudainement de déterrer un vieux projet oublié parmi les millions de projets farfelus de Waikiki : le fameux plan pédalo. En effet, le vent s'étant calmé, nous tentons une dernière fois notre chance en se rendant à la réception. Après un appel avec le gardien, la dame de la réception semble valider in extremis le plan pédalo. Ce n'est tout de même pas gagné car cette dame nous redirige vers le gardien ukrainien rencontré la veille dans les sanitaires... 
    
    Eurêka ! Cette fois-ci, nous arrivons à communiquer sans problème avec l'ukrainien qui nous donne nos gilets de sauvetage et notre pédalo de compétition. Gros soulagement quand nous réalisons que nous allons enfin pouvoir sécréter un peu d'endorphine sur la fin de cette journée de repos. 
    
    Nous fonçons en plein milieu du lac sous musique lo-fi Japan chill. Une fois le barycentre atteint, nous mettons en place un gros apéro sous le ciel nuageux à base de kabanos, noix de cajou et cytrynówka (vodka citron). Nous profitons de cet énorme moment de chill pour arrêter le temps, entre le calme des oiseaux volant au-dessus de nos têtes et la colère des tempêtes se déchaînant au loin. En parlant de météo en colère, nous sommes contraints, une fois de plus, d'écourter notre plan pédalo car un nuage de pluie se dirige vers nous et Waya souhaite secourir ses caleçons qui sèchent dehors pendus à un arbre depuis ce matin. On se dépêche donc de rentrer au camping pour ranger nos affaires à l'extérieur et préparer tout ce qu'il faut pour le dîner. 
    
    Nous trouvons un spot à l'abri pour pouvoir cuisiner nos pierogis tranquilles, proche d'une colonie d'enfants assistant à une veillée cours de pêche. Nous nous inspirons de cette classe d'apprentis pêcheurs et partons pêcher, à notre tour, nos pierogis laissés dans l'eau bouillante. Nous achevons ce dîner par un yaourt grec nature mixé avec beurre de cacahuètes et miel, yin et yang, l'équilibre parfait est atteint ! 
    
    Encore une froid, il fait trop fois ! Les 12 petits degrés nocturnes nous précipitent vers notre tente pour se réchauffer au plus vite avec 2 épisodes poilants de Rick et Morty saison 5. 
        `
    },
    {
        index: 44,
        latLng: [52.615055, 16.207414],
        markerId: "day44-jezioro",
        iconType: TentIcon,
        description: "Jour 44 - Pszczew -> Jezioro Chrzypskie - 49 km",
        kms: 49,
        features: {
            "campings sauvages": 1,
            "sandwichs": 1,
            "flashs de vodkas": 2,
        },
        title: "Sous Pipes Depressurisés",
        imagesName: ["DSC_4321.jpg", "DSC_4322.jpg", "PXL_20210827_093220437.jpg", "DSC_4323.jpg", "DSC_4330.jpg", "DSC_4333.jpg", "DSC_4337.jpg", "PXL_20210827_110158317.jpg", "PXL_20210827_110615350.jpg", "DSC_4342.jpg", "DSC_4343.jpg", "DSC_4344.jpg", "DSC_4345.jpg", "PXL_20210827_131611755.jpg", "DSC_4352.jpg", "DSC_4353.jpg", "DSC_4354.jpg", "PXL_20210827_142709251.jpg", "DSC_4358.jpg", "PXL_20210827_172736504.jpg", "PXL_20210827_174653668.jpg", "PXL_20210827_174922265~2.jpg", "PXL_20210827_175312928.jpg", "DSC_4361.jpg"],
        text: `
    Levé classique à 9h après une nuit pluvieuse pour changer. Pour l'instant la météo est bonne mais un gros nuage de pluie devrait être là dans 2h, on se dépêche donc de tout ranger pour partir au plus vite. Kiki répare sa roue arrière crevée en 2 temps 3 mouvements tel un changeur de roue de Formule 1.

    Nous ralentissons un peu le rythme quand nous constatons que le cœur de la dépression atmosphérique se dirige droit vers nous, zone de calme absolu dépourvue d'intempéries, tant appréciée de tous météorologues amateurs. 

    Nous partons donc du camping à 11h et entamons la journée par des chemins sablés entre les champs et lacs pendant 10 kilomètres où nous essayons tant bien que mal de ne pas se faire ensevelir par les sables mouvants. Au milieu de cette route, nous croisons d'énormes pipes de gaz sur un chantier coupant la forêt en 2. Waikiki voit immédiatement un immense potentiel de fun présent sur ce chantier et s'arrête donc tout de suite pour aller s'amuser un peu. Il en faut pipe pour être heureux ! Waya et Kiki sont comblés, ils arrivent à communiquer ensemble en chuchotant tout en étant éloignés d'une centaine de mètres aux extrémités du pipe. Nous posons ensuite assez naturellement de la grosse techno de centaure résonnant le long du pipe et nous permettant de taper du pied sur le sol sablé. Après quelques cabrioles sur le pipe, un concours de lancer de cailloux et plusieurs cris barbares poussés dans les tuyaux, nos 2 amis défoulés et rassasiés d'amusement reprennent la route. Grosse dédicace à notre ami Blaise sans gluglu l'installateur de gros pipes qui aurait kiffé l'attraction. 

    Nous nous arrêtons dans un mini village pour acheter de l'eau et 2 petits flashs de vodka traditionnels. Cette fois-ci, Waya sort des sentiers battus en prenant une vodka amande caramel et une autre cerise chocolat. 

    Reprise des hostilités dans les chemins de campagne sablonneux où nous pédalons une dizaine de kilomètres. 

    -''Mais dis donc Waikiki ! Pourquoi y-a-t-il du sable en plein milieu de la Pologne à plus de 100 kilomètres de la côte ?''

    -''Eh bien Jamy ! C'est très simple ! Figure-toi qu'il y a plusieurs millions d'années, la Pologne était recouverte par un grand océan ! Qui dit océan, dit bien sur grain de sable, des milliards de milliards de grain de sable. Voilà donc pourquoi nous galérons comme des sgeg avec nos vélos sur ces chemins sablés ! Ceci explique donc cela !'' 

    Une petite pause prunes séchées noix du brésil est brutalement interrompue par un camion sorti de nulle part qui décide de stationner pile poil devant nous et qui effectue une vidange de crotte de la maison d'en face. Après cette pause excrémentale, nous reprenons la route direction la ville de Sieraków où Kiki a repéré un restaurant intéressant. 

    Sur la route, nous passons devant un immense champ de tomates. Ne voyant pas de panneaux ''Uwaga! Tomates interdites !'', Waikiki se precipite dans le champ et repart avec une petite dizaine de tomates en poche sélectionnées avec amour. 

    Nous reprenons la route avec un traditionnel calcul de coin de table de Waikiki : combien de piscines olympiques faudrait-il remplir pour éviter la montée des eaux de 1 mètre ? D'après les fins calculs de nos talentueux ingénieurs, la réponse s'élève à plus de 80 milliards… hum… intéressant… ça ne risque pas de nous sauver pour l'avenir. 

    Bref, nous arrivons enfin à destination au restaurant à 16h après plus de 35 km au compteur. Les Polonais sont connus pour être très bons dans l'élaboration de soupes donc nous commandons 2 zupy pour démarrer ce festin, une soupe de tomate pour rester dans le thème et un żurek (soupe traditionnelle à base d'œufs, saucisses et patates). Pour le plat principal, nous enchaînons avec une poitrine de porc sauce champignons et un canard pommes rôties accompagné de chou rouge. Le résultat est sans appel, ce repas de goûter est délicieux, nous ne pouvons plus rien insérer dans nos ventres. 

    Côté météo, nous avons eu de la chance depuis ce matin car la dépression descend au sud de l'Allemagne et il y a des chances pour qu'il ne pleuve pas pour le reste de l'après-midi. 

    Nous reprenons la route vers 19h pour faire nos derniers kilomètres et trouver un endroit chill pour camper, éventuellement proche d'un lac car il se trouve que nous sommes dans la région des 100 lacs. 

    Nous avons tellement mangé que Kiki en a même des crampes au ventre. Heureusement qu'un coucher de soleil nuageux accompagné de Dusty Kid sur l'enceinte lui facilite le transit. 

    Après presque une heure de recherche, nous finissons par trouver un spot pas trop mal pas loin du lac Chrzypskie. Avant de pouvoir installer définitivement la tente, quelques travaux de grande ampleur sont réalisés : mise à niveau du sol sableux et création d'une rigole pour évacuer l'eau en cas de pluie. 

    Suite à notre immense goûter de 16h, les seules choses que nous pouvons ingurgiter pour le dîner sont une tomate et une banane chacun, nous nous rattraperons au petit déjeuner 4* face au lac demain matin. 

    Après ce dîner rempli de sobriété, nous contemplons les belles étoiles et beaux satellites ornant le ciel du mois d'août avant de rejoindre la tente pour y trouver un peu de chaleur dans cette nuit glaciale. De nos jours, il n'est guère étonnant d'avoir plusieurs satellites à la fois dans le champ de vision puisque plus de 2000 spécimens gravitent autour de nos H24.

    En effet, depuis quelques jours, il commence à faire froid comme un début d'hiver dans le sud de la France. Les journées chaudes sont maintenant devenues des exceptions en cette fin d'été. Très très dur à accepter pour nos 2 sudistes qui ont le sentiment d'avoir sauté un été.

    On croise les doigts pour que la nuit ne soit pas trop froide… 
        `
    },
    {
        index: 45,
        latLng: [52.534629, 16.775508],
        markerId: "day45-rostworowo",
        iconType: TentIcon,
        description: "Jour 45 - Jezioro Chrzypskie -> Rostworowo - 55 km",
        kms: 55,
        features: {
            "campings sauvages": 1,
            "sandwichs": 1,
        },
        title: "Peace and Brunch",
        imagesName: ["PXL_20210828_053111315~2.jpg", "PXL_20210828_053119741.jpg", "PXL_20210828_053127503.jpg", "PXL_20210828_075008181.jpg", "DSC_4364.jpg", "PXL_20210828_075949889.jpg", "DSC_4366.jpg", "DSC_4367.jpg", "original_70b07b92-04c6-45f6-9d9f-dcf4d8e67a34_PXL_20210828_081529290.jpg", "PXL_20210828_081905902.jpg", "PXL_20210828_083010086.jpg", "DSC_4369.jpg", "PXL_20210828_100941330.jpg", "PXL_20210828_104248804.jpg", "PXL_20210828_111126106.jpg", "DSC_4371.jpg", "DSC_4373.jpg", "DSC_4374.jpg", "DSC_4376.jpg", "DSC_4380.jpg", "DSC_4382.jpg", "PXL_20210828_162044872.jpg", "PXL_20210828_162055871.jpg", "DSC_4383.jpg", "PXL_20210828_170752244.jpg", "DSC_4386.jpg", "PXL_20210828_173448625.jpg", "DSC_4387.jpg", "DSC_4388.jpg"],
        text: `
    Nous nous réveillons à 8h30 en position fœtus  après une nuit glaciale atteignant les 8°C… 
    
    Bonne nouvelle ! Un grand soleil bleu vient vite chasser le brouillard installé pendant l'aube. Enfin une matinée chaude digne d'une fin de mois d'août ! Waya en profite même pour ressortir les Birk et faire le kéké torse nu. 
    
    Après avoir rangé le camp, nous descendons à la plage du lac située quelques mètres plus bas pour démarrer la journée. En arrivant devant le lac, nous sommes complètement seuls au monde, seuls Mac Miller et Bob Marley nous accompagnent le long du ponton. Une fois nos pieds trempés dans l'eau, nous préparons le plus gros brunch de l'histoire du Waikiki Tour : yaourts, noisettes, bananes, prunes séchées, pain et bien sûr gros combo miel beurre de cacahuètes. Ce délicieux brunch est dégusté en mélangeant toutes les saveurs sous perfusion ensoleillée de vitamine C. Un important  moment de chill et d'intense bonheur succède immédiatement à ce festin, Waya et Kiki, allongés sur le ponton, les pieds dans l'eau, sous le soleil et sous mojo reggae, profitent de cet été qui refait enfin surface ! 
    
    Nous partons de cet endroit paradisiaque vers 11h30 accompagné par la sensuelle radio fever de Vice City qui nous apporte un gros coup de boost pour débuter cette journée. 
    
    Devant une maison, nous apercevons un panneau indiquant ''miód'' (miel en polish, à prononcer "mioude"). Comme nous sommes un peu fatigués de notre miel actuel acheté en Allemagne, nous décidons d'aller voir cette maison pour tâter le produit. Nous faisons la rencontre de Genio, un Polonais habitant ici qui vend son miel aux visiteurs. Ils nous apportent immédiatement 2 énormes pots de miel de 1L. Comme Waya et Kiki sont 2 personnes très sages, ils refusent son offre. En effet, il ne serait absolument pas raisonnable de transporter un litre de miel dans leurs sacs à moins d'une semaine de l'arrivée. Dommage, tentative d'achat de sucre ratée. Juste au moment où nous remontons sur nos selles, Genio nous interpelle depuis sa maison en criant ''Moment ! Moment ! Moment !''. Nous nous arrêtons sur le champ et l'attendons sagement à son portail. Il nous ramène un petit pot de miel d'acacia de 250 ml qu'il vient de confectionner sur mesure uniquement pour nous ! Waya et Kiki, estomaqués, sortent directement leur besace pour régulariser la situation. Cependant, Genio refuse instantanément tout paiement et insiste pour nous offrir ce pot sur mesure en prononçant la formule polonaise magique : ''Na zdrowie !'' (formule signifiant ''à la santé'', plus communément employée lors de trinquage de vodka). Improbable ! Nous délirons avec lui et le remercions gentiment pendant quelques minutes puis reprenons la route après ce trick de maître mojo. 
    
    Après 25 km dans les jambes, nous nous arrêtons à 14h30 pour déjeuner sur la place d'un petit village et faire les courses dans le mini shop situé quelques mètres plus loin. Nous en profitons pour faire la découverte du yaourt de la marque ''Monte'', marque très connue en Pologne qui vend des yaourts délicieux style Danette au lait, au chocolat et à la noisette. Ce yaourt a notamment donné son nom à l'incontournable shot de vodka au lait et à la noisette que nous avons bu à Szczecin. Cette pause nous laisse le temps de nous concentrer sur quelques calculs de coins de table forts surprenants. 
    
    Nous revoilà donc de retour avec ''Les calculs de coin de table'' présentés par Waikiki, programme désormais légendaire où petits et grands apprennent un tas de choses intéressantes et rocambolesques. 
    
    Le saviez-vous ? L'intégralité des fourmis vivant sur la Terre tiennent dans seulement 280 piscines olympiques, Wow! Ces mêmes fourmis, mises côte à côte, ne peuvent même pas couvrir l'intégralité des océans, seulement 1/10000ème de la surface, Sniff ! Pour autant, accrochez vous bien, toutes ces fourmis mises bout à bout, permettent de faire 250 aller retour Terre-Soleil, soit un saucisson de fourmi de 5 cm de diamètre tendu entre la Terre et le Soleil, gros Wow! 
    
    Après les applaudissements de la foule en délire, nous repartons vers 15h avec, cette fois-ci, la radio pop de GTA V diffusée sur la JBL de Kiki. Plus tard, pause à 17h dans la ville de Szamotuly, où nous faisons des petites courses pour le futur dîner sandwich du camping sauvage de ce soir. 
    
    Nous achevons cette journée musicale avec l'album futuriste The E.N.D. des Black Eyed Peas. L'énergie de cet album nous propulse et nous amène au cœur d'une fête de village organisée par les pompiers. Cette fête contient tellement de mojo que les gâteaux de la buvette sont gratuits, prétexte idéal pour effectuer une pause goûter réussie. 
    
    Nous arrivons finalement en avance dans la forêt que nous visions pour la recherche de notre spot de camping. Après seulement quelques minutes de recherches, nous trouvons notre spot de camping en bordure de forêt, vue sur le coucher de soleil. 
    
    Nous profitons des derniers rayons de soleil pour dîner un sandwich au kabanos sous une valse de chauve souris effectuant leur première sortie de la journée. Les ultrasons de ces dernières sont surprenants et arrivent à nous faire grincer.
    
    Demain, nous aurons seulement quelques kilomètres à parcourir pour rejoindre la ville de Poznan, cinquième ville la plus peuplée de Pologne et une des plus jolies. Nous effectuerons une longue pause de 2 nuits dans cette charmante ville où nous avons rendez-vous avec Wenjia, la douce de Kiki. Le temps de fêter dignement l'anniversaire de Kiki d'après demain, de chiller, de se reposer et surtout… de bien manger ! 
    
    Allez ! Encore une nuit de galère à passer avant de profiter du confort d'un Airbnb tout propre <3
        `
    },
    {
        index: 46,
        latLng: [52.41156342, 16.93313045],
        markerId: "day46-poznan1",
        iconType: TentIcon,
        description: "Jour 46 - Rostworowo -> Poznan - 27 km",
        kms: 27,
        features: {
            "nuits en Airbnb": 1,
            "vrais restos": 2,
            "pots de miel": 1,
            "jours de pluie": 1,
        },
        title: "Au Tour de Mao de Rencontrer Staline",
        imagesName: ["PXL_20210829_045521138.jpg", "PXL_20210829_065658007~2.jpg", "PXL_20210829_072810406.jpg", "DSC_4393.jpg", "DSC_4396.jpg", "DSC_4397.jpg", "DSC_4398.jpg", "DSC_4400.jpg", "PXL_20210829_090649695.jpg", "DSC_4401.jpg", "PXL_20210829_092106228.jpg", "DSC_4404.jpg", "DSC_4405.jpg", "DSCPDC_0002_BURST20210829124930049.jpg", "DSC_4406.jpg", "DSC_4409.jpg", "DSC_4413.jpg", "PXL_20210829_123440688.jpg", "PXL_20210829_125550551.jpg", "DSC_4414.jpg", "PXL_20210829_151732299.jpg", "PXL_20210829_152021429.jpg", "PXL_20210829_152906853.jpg", "DSC_4419.jpg", "PXL_20210829_153901732.jpg", "PXL_20210829_161301611.jpg", "PXL_20210829_161614889~3.jpg", "PXL_20210829_162303352.jpg", "PXL_20210829_163337356.jpg", "DSC_4427.jpg", "PXL_20210829_164320385~2.jpg", "PXL_20210829_172044497.jpg", "DSC_4429.jpg", "DSC_4430.jpg", "PXL_20210829_191154517.jpg"],
        text: `
    Levé à 8h dans notre forêt après une nuit à température convenable. Départ seulement 1h plus tard dans le brouillard polonais tels des toureurs pro. Aujourd'hui, c'est tranquille ! Nous avons seulement 22 km à parcourir jusqu'à notre rendez-vous avec Wenjia, la douce de Kiki qui arrive en gare de Poznan à 11h30. 

    Nous nous arrêtons devant un lac après 10 km pour prendre un petit déjeuner rapide puis enchaînons aussitôt avec un autre ride de 10 km à toute vitesse dans la grande forêt située à l'ouest de Poznan. Après cette balade forestière, nous faisons soudainement notre entrée dans la ville mouvementée au milieu des grands buildings et boulevards remplis de voitures. 

    Nous arrivons pile poil à l'heure à la gare où Kiki fait ses retrouvailles avec sa bien aimée Wenjia qui vient de réaliser un long voyage pour nous rejoindre. Elle a passé 5 frontières et a voyagé pendant plus de 24h en bus et en train pour arriver jusqu'ici depuis le petit village de Mozgovo en Serbie, belle performance que nous saluons ! 

    Nous profitons d'être là pour aller déjeuner ensemble dans l'immense centre commercial flambant neuf situé au sein même de la gare. C'est au dernier étage que nous trouvons notre bonheur : un immense food market rempli de restaurants pour tous les goûts. Kiki et Waya opte pour un buffet polonais pendant que Wenjia préfère renforcer les clichés en se rendant au restau thaï. 

    Une fois nos panses remplies à 14h, nous nous dirigeons vers l'Airbnb au nord du centre historique pour faire le check-in. En arrivant, nous découvrons que notre appartement est en fait situé au sous-sol de l'immeuble… aucun souci pour Waikiki qui n'a besoin que d'une douche et d'un lit, rien d'autre ! Nous entrons dans cette cave ultra moderne avec 2 chambres et même 2 toilettes pour des pauses cacas simultanées, le luxe ! Nous restons concentrés sur l'objectif ultime tant convoité dernièrement : LA DOUCHE. En effet, avec les gros dénivelés des derniers jours et la dernière douche prise il y a 4 jours (cf. J42 et l'épisode de l'Ukrainien altruiste monoglotte), nous sommes  répugnants. 

    Une fois nos corps lavés de tous péchés, nos 2 amis profitent de cet état d'hygiène maximale et se reposent à l'appartement en sirotant un bon thé. 

    Après ce petit moment de confort bien mérité, nos 3 touristes partent à 17h direction le centre-ville parapluie en main car un nuage les guette. Nous visitons la vieille place du marché de Poznan avec ses magnifiques maisons aux façades de toutes les couleurs, endroit parfait pour un shooting photo de qualité. Pour la petite histoire, ces belles façades colorées étaient anciennement la propriété des riches marchants qui venaient vendre leurs produits sur la place.  

    D'un côté de la place, un petit marché éphémère est installé pour le week-end. Nous ne pouvons malheureusement pas résister aux odeurs de vin chaud et de fromage grillé (appelé ''oscypek'' en polonais, fromage fumé typique du Sud de la Pologne souvent accompagné de confiture). 

    Nous errons ensuite dans les petites rues autour de la place principale avant de rejoindre un restaurant polonais traditionnel pour le dîner. En arrivant, nous démarrons tout de suite les hostilités avec, en entrée, la fameuse soupe polonaise żurek servie de manière traditionnelle : dans un pain rond ! Côté boisson, nous découvrons le ''kvas'', spécialité de la Russie et des pays d'Europe de l'Est, boisson à base de pain fermenté apparemment couramment consommée en Europe au Moyen-âge. 

    Pour la suite, Kiki peut enfin satisfaire ses désirs en commandant une grosse pièce de viande, ''jarret'' comme il le nomme et le convoite depuis plus de 40 jours. Pendant ce temps, Waya et Wenjia commandent des plats traditionnels à base de goulasz, ribs, galettes de pommes de terre, chou, betterave… Pas de dessert cette fois-ci, la vodka fera office de gourmandise au bar. 

    Comme à Szczecin, nous nous rendons au fameux bar communiste Pijalnia pour prendre le ou les digestifs. Grosse surprise en arrivant, ce soir c'est ambiance karaoké polonais, son au max, on ne s'entend même plus parler. Heureusement que 3 ou 4 shots de vodka améliorent vite la communication et la digestion. Spéciale dédicace à la délicieuse Cytrynowka que nous dégustons au comptoir, vodka au citron faite maison. 

    Nous fuyons cette cacophonie vers 23h pour retrouver un peu de calme à l'appartement. Une infusion gingembre nous ouvre paisiblement les portes du royaume du sommeil. Nous aurons besoin de beaucoup de repos pour affronter la grosse journée qui nous attend demain… 
        `
    },
    {
        index: 47,
        latLng: [52.41186620598915, 16.932791259568155],
        markerId: "day47-poznan2",
        iconType: TentIcon,
        description: "Jour 47 - Poznan -> Poznan - 0 km",
        kms: 0,
        features: {
            "nuits en Airbnb": 1,
            "vrais restos": 2,
            "verres de vin": 1,
            "flashs de vodkas": 2,
            "penis de veau mangé dans le noir": 1,
            "sauterelles grillées": 2,
            "anniversaires de Waikiki fétés": 1,
            "paires de chaussettes stylées achetées": 3,
            "jours de pluie": 1,
        },
        text: `
    ''Joyeux anniversaire sancho de la veine'' s'exclame Waya lorsque Kiki franchit la porte de sa suite royale à 9h. En effet, aujourd'hui est un grand jour, le petit Kiki fait son entrée dans le monde des grands sérieux et responsables. Il n'est désormais plus possible de se fracturer la jambe en effectuant un saut depuis la scène d'un concert pour échapper à un vigile furieux.
    
    Après un aller rapide dans une boulangerie pour un petit déjeuner express, Waya et Kiki se rendent à leur première activité d'anniversaire de la journée à 10h : un rendez-vous au Barbershop dénommé ''Scarface''. Avec leur look de sans-abris, ils débarquent dans l'enseigne sous le regard terrifié des barbiers expérimentés qui ont vraisemblablement rarement vu une telle négligence chevelue de leur carrière. 
    
    Pendant plus d'une heure, 2 barbiers courageux réalisent donc un chantier d'importance majeure pour bichonner et transformer nos 2 aventuriers. Tondex 3000,  Dry Touff Ultimate, Rasoir Blade Xtrem, toutes les plus grosses machines sont mobilisées pour achever ces travaux de grande ampleur. 
    
    Après avoir fait leur entrée en tant que SDF il y a plus d'une heure, Kiki Montana et Waya Ribera ressortent fièrement de ce Barbershop tels de vrais mafieux italiens sortant de 10 ans de prison. Avec leurs coupes de cheveux ultra-profilées, ces 2 truands de la galère sont plus que jamais déterminés à reprendre le business pour achever la mission Waikiki. Ils sont désormais bien plus aguerris qu'avant avec leurs nouvelles coupes de cheveux aérodynamiques de pointe leur apportant un gain de rendement non négligeable pour la suite de leur trafic. En effectuant un nouveau calcul de trajectoire prenant en compte ces améliorations, ils se rendent compte qu'il y a de grandes chances pour qu'ils achèvent leur mission plus tôt que prévu... 
    
    Ils retrouvent Wenjiaa puis se dirigent vers la vieille place du marché où va se dérouler un événement unique incessamment sous peu. Une fois par jour à midi, 2 chèvres mécaniques font leur apparition sur la mairie au-dessus de l'horloge située sur la tour en hauteur. Devant la foule de touristes en délire, ces 2 chèvres  se cognent la tête précisément 12 fois accompagnées d'une jolie mélodie jouée par un trompettiste perché à côté du clocher. 
    
    Après ce moment fort en émotion,  il est l'heure pour nos 3 touristes d'aller se rassasier. Comme c'est la journée spéciale du vénérable KitKat, c'est lui qui décide ! Il opte finalement pour un restaurant spécialisé dans les pierogis pour son déjeuner d'anniversaire. 
    
    En arrivant dans le restaurant, Kiki se retrouve confronté, pour la première fois, aux contraintes injustes du monde ennuyant et insipide des adultes. En effet, ayant passé l'âge de la folie depuis quelques heures, il ne peut désormais plus tenter le défi du restaurant qui est de manger 50 pierogis en 38 minutes pour gagner une réduction de 50%. Il accepte donc de vieillir et effectue une commande raisonnable comme tous les adultes sages de son âge. Cependant, le choix est très complexe devant cette carte aux millions de saveurs. Tellement complexe qu'ils demandent 2 fois à la serveuse de leur laisser un temps additionnel pour se décider. Ils choisissent finalement les pierogis disruptifs suivants : 5 gros pierogis au four goût pomme canard, 9 pierogis classiques au goulasch d'agneau et 9 pierogis frits poisson-poireau. 
    
    Pendant notre dégustation de pierogis, nous réalisons soudainement que nous avons booké la veille un rendez-vous à 13h50 au musée du Croissant Poznanien pour un show exceptionnel qui débutera dans précisément 20 minutes. Nous nous empressons donc d'engloutir nos raviolis polonais pour ne pas arriver en retard. 
    
    Nous arrivons devant le musée 1 minute en avance. Une fois les tickets récupérés, on nous indique le chemin à suivre puis nous arrivons dans une sorte de classe de cuisine où un chef cuisinier nous prend tout de suite en main et nous habille d'une toque et d'un tablier. Ce même chef nous indique que nous allons participer à un cours de cuisine et probablement mettre la main à la pâte pour réaliser les fameux croissants poznaniens. La folie ! Nous qui pensions visiter un musée classique… 
    
    Le cours commence à 14h pétante. Entre 2 étapes de préparation du croissant, le chef nous conte l'histoire de la ville et de cette pâtisserie si spéciale. 
    
    Pour la petite histoire Waikikipedia, Poznan est célèbre pour ses Croissants St Martin que les Polonais dégustent notamment le 11 novembre pendant la célébration de St Martin (1,5 million de croissants préparés en un jour). Ce fameux Martin est un ancien soldat du Moyen  ge reconverti en prêtre suite à un rêve divin dans lequel Dieu le félicite d'avoir été généreux envers un clodo qu'il a croisé dans la journée. Après cette hallucination, il fait un burnout, quitte son job de soldat et décide de consacrer sa vie à Dieu. Plus tard, au 19e siècle, un boulanger s'inspire alors de cette histoire de partage pour créer un business model innovant lui permettant de s'introduire dans le marché compétitif de la pâtisserie poznanienne : créer une pâtisserie en forme de fer à cheval, l'appeler croissant, les couper en 2 puis en donner une partie aux pauvres et vendre l'autre partie aux riches. Quel business man ce Smart boulanger ! 
    
    Bref, voilà comment les poznanais se retrouvent à manger des croissants qui, mis à part leur forme, n'ont rien à voir avec nos délicats croissants français. En effet, ces croissants réalisés avec une pâte pliée 81 fois sont fourrés avec une garniture faite à base de pavot blanc, figues, dattes, raisins et crème. Pour alourdir le bilan calorique de ce produit, les croissants sont finalement trempés dans un glaçage et parsemés de cacahuètes pour atteindre un poids total compris entre 150 et 250 grammes. En 2008, ce croissant a été inscrit au registre des produits protégés dans l’Union européenne et aujourd'hui, seulement une centaine de boulangeries en Pologne ont le certificat officiel pour les concevoir. 
    
    Nous apprenons également un peu d'histoire polonaise. D'après la légende slave, 3 fratés, Lech, Rus et Cech chassaient ensemble dans la forêt. Ils se séparèrent soudainement dans 3 directions différentes pour poursuivre leurs proies respectives. Rus partit vers l'est, Cech vers le sud et Lech resta dans la zone. Ils ne doutaient pas à ce moment-là qu'ils se perdraient de vue… Rus finira par fonder le royaume Russe, Cech le royaume Tchèque et Lech la Pologne. Askip, ils se retrouvèrent des années plus tard au même endroit par pur hasard en mode Trumanshow. Pour célébrer ce moment hautement improbable, ils décidèrent de faire une grosse soirée puis de créer la ville de Poznan au calme. 
    
    Durant ce cours, nos 3 amis se spécialisent chacun dans une étape de confection du Croissant : Wenjia aplati la pâte au rouleau, Kiki travaille la pâte en la boxant et Waya s'occupe de rouler des gros croissants avant le passage au four. 
    
    Nous finissons ce cours par un exam final. Chaque participant doit deviner et donner un poids estimatif pour le croissant réalisé. Celui qui est le plus proche de la vraie valeur repart avec la fameuse pâtisserie. À notre grande surprise, Wenjia remporte le gros lot avec ses 230 grammes annoncés.
    
    Nous finissons donc cette heure de cours intense sur une note sucrée dégustation du tant convoité croissant. Verdict : strictement rien à voir avec le croissant français, il est beaucoup plus lourd, un peu trop sucré, pas assez aéré et il manque probablement de beurre ! Néanmoins, il reste quand même assez bon (sucre !)… 
    
    Nous reprenons la visite de la ville en commençant par une pause chill sur des transats au château impérial situé à l'ouest, ancien château converti en centre culturel avec théâtre, concert, cinéma… Waya en profite pour offrir ses cadeaux d'anniversaire à son acolyte de longue date. Sans surprises, Kiki reçoit 3 paires de chaussettes chinées sur le parcours du Waikiki Tour. Nous continuons notre visite en passant par un magasin de trekking, une vielle brasserie reconvertie en immense centre commercial et un magasin de chaussette où nous ne pouvons pas résister à acheter quelques paires. Nous passons même devant une irrésistible vitrine remplie de pączki aux mille saveurs (typique beignets polonais fourrés avec  glaçage). Comme Waya et Kiki sont à présents 2 personnes sages et matures de 27 ans, ils se contrôlent et résistent tant bien que mal à la forte attraction sucrée qu'ils subissent pour laisser leurs ventres vierges avant  le restaurant de ce soir. 
    
    Il est maintenant 19h et nous avons une réservation au restaurant dans 30 minutes. Ce soir, nous allons dîner au Dark Restaurant de Poznan pour fêter comme il se doit l'anniversaire du grand Kiki. Waya s'est rendu dans ce restau avec Irène il y a 4 ans et souhaitait absolument partager ce concept inédit avec son compagnon de route. Cet endroit est bien plus qu'un restaurant, c'est une expérience innovante permettant d'ouvrir les portes de tous les chakras intra-psychique et ainsi accéder au tant convoité ''Umami'' (sorte de paradis de mojo pour la nourriture où tous les aliments sont heureux et dansent ensemble comme dans les pubs oasis). En d'autres termes, c'est un restau vraiment très stylé où le principe est de déguster un menu mystère dans le noir le plus total. Nos 3 protagonistes principaux débarquent dans le restau à 19h30 et commencent l'expérience par un entretien de quelques minutes avec la serveuse pour recenser les différentes allergies et préférences. Nous optons finalement pour le menu intermédiaire appelé le menu ''bizarre''...
    
    Avec ses lunettes infrarouges à la splinter cell, la serveuse nous accompagne à la queueleuleu jusqu'à notre table où nous nous retrouvons dans le noir complet, encore plus noir qu'un trou noir habillé en noir en soirée dans une dark room berlinoise. La serveuse nous indique où se trouve nos couverts, nos verres de vin et nos âmes. Quelques minutes plus tard, on nous amène l'entrée et nous commençons à déguster nos plats mystères en essayant tant bien que mal de transporter les aliments depuis notre assiette jusqu'à notre bouche en utilisant les couverts et les mains. Nous nous disputons à plusieurs reprises au sujet de l'identification des aliments présents dans nos assiettes, certains pensent avoir du poisson et d'autres du poulet. 
    
    Ce n'est qu'à la moitié du plat principal que nous réalisons que Wenjia a en fait des ingrédients complètement différents des nôtres depuis le début, ce qui explique notamment la plupart de nos querelles. Pensant que c'est une erreur, nous le faisons remarquer au serveur qui nous indique que c'est complètement normal et qu'il n'y a pas de boutade. Après moultes rigolades, nous finissons sur une note sucrée avec le dessert qui conclut à merveille ce dîner délicieux remplis de saveurs improbables. Après ces 2 heures de dark room, nous re-découvrons enfin la lumière vers 21h où nos rétines ont bien du mal à se ré-adapter au monde normal. Pour conclure, nous passons un entretien final avec la serveuse afin de faire le bilan sur l'expérience et enfin nous révéler ce que nous avons mangé. Pendant que Wenjia apprend qu'elle a dégusté des rouleaux de printemps au penis de veau accompagné de larves frites, Waikiki réalise qu'il a ingurgité un carpaccio de langue de cheval ainsi qu'une salade de sauterelle sauce soja… Wow ! Nos 3 amis sortent du restaurant sur le cucu après avoir vécu cette expérience plus qu'étonnante. 
    
    Nous rentrons à l'appartement vers 22h où les talents de cuisiniers de Wenjia et Waya permettent à Kiki d'avoir un gâteau d'anniversaire improvisé réalisé avec un KitKat, 2 gâteaux Monte et une feuille de papier pour la bougie (cf. photo à l'appui). Une fois la bougie soufflée, nos amis dégustent le gâteau puis réalisent une petite dégustation de vodka pour terminer la journée en beauté (vodka coing et vodka menthe cette fois-ci). 
    `,
        title: "Poznan: Pause Non Posée",
        imagesName: ["DSC_4433.jpg", "DSC_4434.jpg", "PXL_20210830_091345084.jpg", "DSC_4436.jpg", "1630318955331.jpg", "PXL_20210830_083436629~2-01~2-01~2.jpg", "IMG_5997.jpg", "IMG_5999.jpg", "IMG_6005.jpg", "DSC_4438.jpg", "20210901_160035-COLLAGE.jpg", "DSCPDC_0001_BURST20210830132849897_COVER.jpg", "PXL_20210830_112910560.jpg", "IMG_6010.jpg", "DSC_4444.jpg", "DSC_4449.jpg", "DSC_4451.jpg", "DSC_4458.jpg", "PXL_20210830_122038270.jpg", "PXL_20210830_123021487.jpg", "DSC_4465.jpg", "PXL_20210830_125035944.jpg", "PXL_20210830_125552139.jpg", "DSC_4468.jpg", "PXL_20210830_170307735.jpg", "PXL_20210901_141042062.jpg", "PXL_20210830_210723130.jpg", "IMG-20210830-WA0003.jpg"],
    },
    {
        index: 48,
        latLng: [52.271827, 17.169479],
        markerId: "day48-kromolice",
        iconType: TentIcon,
        description: "Jour 48 - Poznan -> Kromolice - 28 km",
        kms: 28,
        features: {
            "campings sauvages": 1,
            "sandwichs": 1,
            "vrais restos": 1,
            "bières": 1,
            "jours de pluie": 1,
        },
        title: "Epic 2 Mille Time : ZapiekanPark",
        imagesName: ["PXL_20210831_090201460.jpg", "PXL_20210831_094717041.jpg", "PXL_20210831_094816216.jpg", "DSC_4488.jpg", "DSC_4489.jpg", "DSC_4490.jpg", "DSC_4494.jpg", "DSC_4496.jpg", "PXL_20210831_113048787.jpg", "PXL_20210831_113320216.jpg", "PXL_20210831_113543116.jpg", "DSC_4500.jpg", "PXL_20210831_121500629.jpg", "PXL_20210831_121608718.jpg", "DSC_4508.jpg", "PXL_20210831_122334917.jpg", "PXL_20210831_140907888.jpg", "DSC_4511.jpg", "PXL_20210831_151637648.jpg", "DSC_4522.jpg", "PXL_20210831_152248845~2.jpg", "PXL_20210831_152441430~2.jpg", "DSC_4531.jpg", "PXL_20210831_164804264.jpg", "DSC_4535.jpg", "DSC_4539.jpg", "DSC_4540.jpg", "PXL_20210831_173754495~2.jpg", "PXL_20210831_175608476~2.jpg", "PXL_20210831_175707989.jpg", "DSC_4547.jpg"],
        text: `
    Nous profitons de notre dernier réveil maxi confort de notre aventure puis enchaînons tranquillement avec un petit petit déjeuner en partageant en 3 le croissant que Wenjia a gagné hier au musée. 

    Après avoir rangé nos affaires, nous partons à 12h pour une promenade pluvieuse dans le parc de la citadelle, ancienne base militaire qui a joué un rôle important entre la 1ère et 2nde guerre mondiale et a été un des derniers sièges nazis avant leur chute. Ce grand parc abrite de nombreux monuments de commémoration, un cimetière, 2 musées militaires et quelques vestiges de bunker et muraille. Nous nous baladons ainsi dans cette nature remplie d'histoire en naviguant entre les tanks et les roses. 

    Nous marchons jusqu'à tomber subitement sur un café restaurant de rêve situé au milieu du parc. Un lieu ultra chill situé sur l'ancien emplacement du bunker de radiocommunication de la guerre, dans une véranda avec des plantes et canapés maxi confort par milliers. Nous décidons de déjeuner ici vers 14h en commandant 2 soupes (żurek + borsch = <3) et 3 Zapiekankas, sorte de longue bruschetta polonaise pouvant atteindre 1 mètre dans certains boui-boui.

    Après 2h de chill intense, nous rentrons à l'appartement pour récupérer nos affaires, nos vélos et faire nos adieux à Poznań et à Wenjia. Cette dernière que nous quittons d'ailleurs pour très peu de temps car nous la retrouverons chez Paulina et Melvin dans quelques jours. 

    Une fois les adieux effectués à 17h, nous remontons sur nos fusées et partons fracturer la couche de bitume. Suite à cette grosse pause poznanienne de 2 nuits, nous sommes plus forts et plus déterminés que jamais pour rider la dernière ligne droite qui nous attend. Nous prévoyons d'achever les 220 kilomètres nous séparant de Lodz en seulement 4 jours incluant aujourd'hui. 

    Petit arrêt express 10 kilomètres plus tard pour effectuer des courses rapides pour le dîner sauvage de ce soir. 

    Quelques minutes plus tard et quelques kilomètres plus loin, nos 2 aventuriers s'arrêtent soudainement sur le bord de la route car ils pensent avoir passé l'énorme cap des 2000 km. Maître Waya, l'huissier de justice mandaté pour valider cet exploit hors norme, fait son entrée en scène et effectue le contrôle minutieux du compteur officiel du Waikiki Tour. Le suspense est à son comble… les tambours roulent… tout le monde retient son souffle… Maître Waya prend enfin la parole dans ce moment de tension insoutenable : ''Bonsoir à toutes et à tous ! Si je suis ici présent ce soir, c'est que j'ai été missionné pour effectuer une vérification officielle en toute impartialité. Après contrôle des éléments de comptage officiels de cette aventure remplie de rebondissements, je dois vous annoncer que…………..………. Vous aurez le résultat après une courte page de pub !'' 

    ''Et pour la première fois dans l'histoire du Waikiki Tour, nous vous offrons l'opportunité de participer à un jeu exceptionnel vous permettant de gagner l'incroyable montant de 3 paires de chaussettes stylées par mois pendant 20 ans. De quoi réaliser vos projets les plus fous comme acheter une maison par exemple. Pour cela c'est très simple, il vous suffit de répondre à la question suivante : Qui a réalisé la recette de la banane perdue au début de l'aventure ? Réponse 1 : Waya le caca, réponse 2 : Kiki le farci. Envoyez votre réponse par SMS au 3636 ou en cliquant sur ce <button onclick="(()=>{let answer = prompt('Qui a réalisé la recette de la banane perdue ? \\nKiki ou Waya ? : ');if (answer.toLowerCase()==='waya'){alert('Bravo, tu as réussi la devinette ! Tu auras peut-être la chance dêtre la personne tirée au sort')} else if (answer.toLowerCase() === 'kiki'){alert('Non, cela est faux, retente ta chance')} else {alert('ERROR 36 : only waya and kiki are allowed answers')};console.log(answer)})()">BOUTON</button>. L'heureux gagnant du concours sera révélé en fin d'aventure. À vos téléphones !''

    Après cette courte page de pub qui s'avère finalement être une longue page de pub, Maître Waya reprend le mic et annonce devant la Pologne entière que……………… la barre symbolique des 2000 km…………………. vient tout juste d'être franchie par nos 2 compétiteurs de talents !!! Gros câlin mojo et gros shot de vodka pour fêter ça ! 

    Nous repartons, débordant de mojo, affronter les chemins boueux de campagne accompagnés d'une grosse musique venant tout droit de la constellation du centaure. 

    Après plusieurs flaques de boue franchies, nous finissons par trouver un spot de camping sauvage improvisé entre 2 champs vers 20h où nous installons rapidement la tente en profitant d'un coucher de soleil rosé. 

    Nous enchaînons par un gros dîner de fête pour célébrer nos 2000. Au menu gastronomique : sandwich au camembert, jambon cru et confiture de figue préparée maison par Wenjia. 

    Pour couronner le tout, ce soir c'est Koh-Lanta la légende épisode 2 ! 2 grosses bières polonaises en main, nous nous fendons la poire devant cet épisode exceptionnel riche en rebondissements. Nous profitons des pubs pour réaliser l'énorme parallèle existant entre notre aventure et celle que vivent les aventuriers de Koh Lanta. En effet, à quelques détails près, nous vivons exactement la même chose. De nombreux points communs nous rapprochent : nous vivons une aventure d'une quarantaine de jours pleine de rebondissements, nous réalisons un exploit sportif de l'extrême, nous sommes aussi sale qu'eux et, plus que tout, nous réunissons toutes les qualités de la fameuse règle des 4S énoncé par Freddy de KL : survie, stratégie, sportivité et social. Nous allons tout donner pour aller jusqu'aux poteaux à Lodz et remporter le totem de l'immunité mojo. 

    Notre campement ayant été installé sous des pommiers, nous nous endormons en comptant les fruits qui s'abattent sur nous et croisons les doigts pour ne pas finir assommé. 

        `
    },
    {
        index: 49,
        latLng: [52.161275, 17.809233],
        markerId: "day49-zapowednia",
        iconType: TentIcon,
        description: "Jour 49 - Kromolice -> Zapowednia - 56 km",
        kms: 56,
        features: {
            "campings sauvages": 1,
            "vrais restos": 1,
        },
        title: "Rentrée des Classes Chillax", 
        imagesName: ["DSC_4548.jpg", "PXL_20210901_080055508.jpg", "DSC_4550.jpg", "DSC_4551.jpg", "DSC_4553.jpg", "original_b5a7673b-20e8-4340-bf98-289ae353a2cd_PXL_20210901_090520295.jpg", "PXL_20210901_094435495~2.jpg", "PXL_20210901_103232257.jpg", "PXL_20210901_103445071.jpg", "DSC_4559.jpg", "PXL_20210901_140056193.jpg", "PXL_20210901_142620667.jpg", "PXL_20210901_170627942.jpg", "PXL_20210901_172946418~2.jpg", "DSC_4569.jpg", "PXL_20210901_174359142.jpg", "DSC_4571.jpg", "PXL_20210901_175022160.jpg"],
        text: `
    Bonne nouvelle ! Nous constatons au levé à 9h que personne n'est mort assommé par une pomme après cette nuit passée dans cet environnement hostile. 

    Départ express à 10h, nous prendrons le petit déjeuner plus tard sur la route. 

    Quelques kilomètres après le départ, nous avons la chance d'assister à la rentrée des classes d'un lycée polonais. Nous nous  arrêtons un instant pour se marrer un peu et observer les jeunes passer cette épreuve spéciale, habillés sur leur 31. Cette odeur de rentrée de Proust rappelle tout un tas de souvenirs à Waya et Kiki qui s'empressent de partager leurs différentes expériences. Aujourd'hui, la rentrée est bien différente pour ces 2 loubards à vélo, aucun cahier à acheter, pas d'emploi du temps à respecter, aucune présentation ennuyante à écouter, seulement un petit déjeuner à planifier au plus vite.

    Après ce moment de divertissement, nous décidons de nous arrêter quelques mètres plus loin pour préparer notre gros brunch sur le banc d'un petit parc situé au centre-ville. Ce repas est l'occasion pour nous d'expérimenter un tout nouveau mix de cuisine moléculaire made in Waikiki : mélange du beurre de cacahuètes avec la confiture de figue. Le résultat est sans appel : une fois mélangé à du beurre de cacs (petit surnom donné par Waikiki) , tout aliment voit instantanément son pouvoir gustatif amélioré. 

    Après ce brunch de champion, nous reprenons la route à toute vitesse traversant les petits villages polonais à 18 km/h de moyenne. 

    Avec 45 km dans les pattes à 15h, nous arrivons finalement dans le petit village mignon de Pyzdry où nous sommes à la recherche d'un café stylé pour effectuer une pause goûter. Le problème est qu'il n'y a aucun café dans ce village perdu… nous finissons donc dans le seul endroit ouvert du village où il est envisageable de commander un kawa : la pizzeria de la place principale. Vous l'aurez compris, cette pause ''café'' va rapidement se transformer en pause ''pizza'' comme ces 2 gloutons ont l'habitude de faire. 

    En effectuant un rapide calcul de coin de table du ratio aire de la pizza par rapport au coût, Waya et Kiki comprennent rapidement qu'il est largement plus rentable de commander une pizza 40 cm de diamètre plutôt qu'une de 30 cm… d'autant plus qu'il est apparemment possible d'avoir 2 goûts différents en moitié-moitié sur la 40 cm et que cette dernière ne coute qu'un euro de plus que la 30 cm, tout bénef pour nos 2 acolytes qui s'empressent de commander une moitié 4 fromages moitié salami à partager. 

    Environ 3 heures plus tard et une pizza 40 cm engloutie, nous reprenons la route et effectuons quelques rapides courses pas loin de la pizzeria. Nous avons encore une dizaine de kilomètres à parcourir avant de pouvoir chercher notre spot de camping sauvage.

    Avec 56 km au compteur et un soleil venant à peine de se coucher, nous trouvons notre spot dans une forêt de pins proche d'un vieux cimetière abandonné à une centaine de mètres de la route de campagne. Entre quelques amanites tue-mouches et moustiques qui traînent par là, nous installons vite le campement avant que la nuit tombe. 

    Nous dégustons notre dîner sandwich kabanos figue protégés dans notre zone délimitée par nos spirales anti-moustiques.

    Il nous reste exactement 156 km (rpz Rpes) à parcourir en 2 jours avant d'arriver à la ferme : 56 km demain et 100 km en mode gros challenge pour le dernier jour (délire Waikiki). Afin d'optimiser notre condition mentale et physique pour les 2 derniers jours sportifs qui nous attendent, nous partons nous coucher tôt pour une fois vers 22h
        `
    },
    {
        index: 50,
        latLng: [52.21604, 18.408314],
        markerId: "day50-piersk",
        iconType: TentIcon,
        description: "Jour 50 - Zapowednia -> Konin -> Piersk - 56 km",
        kms: 56,
        features: {
            "campings sauvages": 1,
            "vrais restos": 1,
            "flashs de vodkas": 1,
            "pots de beurre de cacahouètes": 1,
        },
        imagesName: ["DSC_4572.jpg", "DSC_4577.jpg", "PXL_20210902_075346739.jpg", "DSC_4582.jpg", "PXL_20210902_091736417.jpg", "DSC_4583.jpg", "PXL_20210902_110955678.jpg", "PXL_20210902_122748781.jpg", "DSC_4585.jpg", "DSC_4588.jpg", "PXL_20210902_124222341.jpg", "DSC_4589.jpg", "DSC_4590.jpg", "PXL_20210902_125135340.jpg", "PXL_20210902_152811571.jpg", "DSC_4593.jpg", "PXL_20210902_164258286.jpg", "PXL_20210902_170450951.jpg", "PXL_20210902_170733351.jpg", "DSC_4594.jpg", "DSC_4595.jpg", "DSC_4598.jpg", "PXL_20210902_185828751.NIGHT.jpg", "PXL_20210902_190055699.NIGHT.jpg"],
        title: "En Quête du 51",
        text: `
    Levé à 8h après une nuit pas ouf où il a fait finalement… trop chaud… 
        
    Nous rangeons le camp vers 9h et nous nous préparons aussitôt pour attaquer cet avant-dernier jour d'aventure. 
        
    Grâce à un gros soleil combiné à de la techno Berlinoise mixée par DJ Villiam von Baraus, nous sommes immédiatement propulsés de 0 à 100 en 2 secondes 3. 
        
    Nous stoppons nos fusées pour effectuer un arrêt stratégique quelques kilomètres plus loin au premier marché que nous croisons afin de faire le plein de framboise pour le petit dej. 
        
    À seulement 11h, nous avons déjà parcourus 19 km à la vitesse moyenne incroyable de 19km/h. Nous sommes à présent devenus de vraies machines du vélo. En effet, désormais, nous ne sommes plus que des machines à transformer le beurre de cacahuète en énergie cinétique à très haut rendement. 
        
    Afin de remplir à nouveau nos réservoirs quasi vides, nous faisons le plein de beurre de cacahuètes durant 1h de brunch intensif. Waya rajoute un petit boost de miel nitro supplémentaire dans son réservoir pour réparer le rhume de fin de mois d'août qu'il vient de chopper furtivement. 
        
    Rallumage des machines à 12h, direction la ville de Konin ou nous allons réaliser un test antigénique avant d'arriver à la ferme demain.
        
    À leurs habitudes, Waya et Kiki trouvent un nouveau défi improbable pour la journée : acheter une bouteille de pastis 51 et la ramener à la ferme. Ce défi ne tombe par par hasard, bien au contraire… en effet, nos aventuriers cloturerons leur trépidante aventure par le jour 51 (jtm). Quoi de mieux qu'une bouteille de 51 pour célébrer l'arrivée des 2 sudistes à la ferme durant le 51ème jour ? La réponse est ''rien'' bien entendu ! Nous mettons donc une stratégie en place et effectuons un petit détour de 3 km pour viser un Intermarche dans une zone industrielle. Nous aurons probablement plus de chance de trouver cette bouteille française dans un supermarché français. D'après la légende, Waya aurait déjà acheté des produits français (pastis, raclette, saucisson…) dans un Auchan à Lodz… 
        
    En arrivant là-bas, nous commençons par effectuer nos ultimes courses alimentaires pour la journée du lendemain avant de se rendre au rayon alcool. Après avoir retourné le rayon alcool dans tous les sens, nous faisons un triste constat : il est impossible de trouver une quelconque bouteille ressemblant de près ou de loin à notre liqueur anisée préférée. Nous perdons une bataille mais pas la guerre ! 
        
    Nous repartons et arrivons finalement au sud de Konin avant 15h avec 38 kilomètres dans les jambes. Sans perdre de temps, nous nous dirigeons vers un laboratoire pour réaliser un test antigénique. Une fois le coton de tige inséré dans nos réseaux nasaux respectifs, nous patientons dans la salle d'attente en jouant avec la télécommande de la télé à la recherche de pubs polonaises hilarantes. Quelques minutes plus tard, l'infirmière se dirige vers nous et le verdict tombe : aucun virus n'a été détecté dans nos corps de saint, le puissant vaccin Pfizer injecté dans nos veines il y a quelques mois semble pour l'instant faire son travail. 
        
    Nous décidons de célébrer cette bonne nouvelle en enchaînant avec une pause café pizza comme nous savons bien le faire. Nous nous dirigeons donc vers la terrasse ensoleillée de la meilleure pizzeria de la ville, endroit réunissant toutes les qualités pour une pause réussie ! Juste après la dégustation de son thé, Kiki décide de retenter sa chance pour la bouteille de 51, il se rend tout seul au Leclerc situé à 3km de la pizza pendant que Waya continue de siroter tranquillement son cappuccino plein de vitamine D. Malheureusement, il fait une nouvelle fois face à un échec cuisant… impossible de trouver cette satanée bouteille et ni même un petit paquet de Pepitos Pockitos en lot de consolation. Il revient annoncer la terrible nouvelle à son copilote resté en farniente à la terrasse. Waya et Kiki se font à l'idée… cela risque d'être assez complexe de trouver cette fameuse bouteille sur les derniers 100 petits derniers km de minivillages polonais… ils ne perdent pas espoir pour autant et continuent leur pause théinée jusqu'à 17h avant de passer à la pause dîner à l'intérieur du restaurant, protégés du froid cosmique. Proche de prises électriques, Waya et Kiki rechargent leurs batteries en partageant une délicieuse pizza en entrée. Pour le plat principal, ils enchaînent avec 2 savoureux plats de pâtes italiennes al dente de champion qui leur procureront une force herculéenne pour affronter leur dernier jour de vélo. 
        
    Fin de la pause et du dîner à 19h. Nous repartons pour un ride de coucher de soleil sans un seul nuage accompagné de doux sons chills de fin de journée mixés par DJ Waya. Ce moment nous rend même nostalgique car il nous rappelle nos débuts ensoleillés en Hollande à la grosse différence que le soleil se couche maintenant à 19h30 (wtf) !! La playlist musicale sunset end of the summer concoctée par Waya nous permet de réaliser une quinzaine de kilomètres avant de trouver notre spot de camping situé pile poil à 100 km de la ligne d'arrivée pour demain. Nous installons notre tente dans un immense champ non cultivé sous le ciel étoilé faisant doucement son apparition. 
        
    Quelques minutes après, nous dégustons dans le noir notre dessert du soir : un yaourt monte special fan edition contenant seulement la partie blanche au lait des monte classique. Nous savourons cette pause sucrée sous le plus gros ciel étoilé que nous ayons vu depuis le début de l'aventure. En apercevant une grosse étoile filante traversant le ciel, nous croisons les doigts pour croiser la route d'une grosse bouteille de 51 demain. 
        
    ''Je suis pas chaud d'avoir froid ce soir !'' s'exclame Waya en rentrant congelé dans la tente pour passer cette dernière nuit d'aventure. En enfilant sa 4ème couche de protection au sein de leur igloo, Waikiki croise les  doigts pour que leur dernière nuit soit clémente.
        
    Nos 2 grands aventuriers restent concentrés et se couchent tôt pour être en forme demain pour la grande et ultime journée qui les attend. 
        `
    },
    {
        index: 51,
        latLng: MelvinPosition,
        markerId: "day51-wodzierady",
        iconType: MelvinIcon,
        description: "Jour 51 - Piersk -> Lodz - 100 km",
        kms: 100,
        features: {
            "dodo chez des potos": 1,
            "sandwichs": 1,
            "coups de soleil": 2,
            "livres lus": 0,
            "douches solaires prises": 0,
        },
        imagesName: ["PXL_20210903_050657863.jpg", "PXL_20210903_051851938.jpg", "PXL_20210903_053100395.jpg", "PXL_20210903_054454843.jpg", "PXL_20210903_055649751.jpg", "PXL_20210903_060627877.jpg", "PXL_20210903_070949760.jpg", "DSC_4599.jpg", "DSC_4601.jpg", "PXL_20210903_074954616.jpg", "DSC_4605.jpg", "DSC_4607.jpg", "PXL_20210903_075420935.jpg", "DSC_4610.jpg", "PXL_20210903_082144381.jpg", "PXL_20210903_100050565~2.jpg", "PXL_20210903_104912655.jpg", "DSC_4614.jpg", "DSC_4615.jpg", "DSC_4616.jpg", "PXL_20210903_124152332.jpg", "PXL_20210903_130147031.jpg", "PXL_20210903_135525488.jpg", "PXL_20210903_140831871.jpg", "PXL_20210903_140907415.jpg", "PXL_20210903_141304615.jpg", "PXL_20210903_143227725.jpg", "PXL_20210903_143431115.jpg", "DSC_4617.jpg", "PXL_20210903_143855657.jpg", "DSC_4621.jpg", "PXL_20210903_154153969.jpg", "PXL_20210903_154500116.jpg", "PXL_20210903_154626588.jpg", "PXL_20210903_154911579.jpg", "PXL_20210903_155759901.jpg", "DSC_4623.jpg"],
        title: "Les Centurions Sans 51 en Route pour la Ferme !",
        text: `
    7h : Jour spécial, levé spécial. Gros levé de guerrier à 7h du matin pour nos 2 aventuriers. Aujourd'hui, une journée de champion les attend, ils ont 100 kilomètres à parcourir… défi jamais réalisé jusqu'à présent. Aux dernières nouvelles, comme si ce dernier challenge ne suffisait pas, ils doivent également franchir la ligne d'arrivée à 18h pour être à l'heure pour le dîner à la ferme. Bon signe, les éléments sont pour l'instant de notre côté pour réaliser cet exploit : gros soleil et grand ciel bleu. Nous commençons notre journée musicale dans la tente sur une délicate et douce mélodie de papa pingouin remix hardtek. Nous retrouvons rapidement un peu de calme et de concentration avec une petite valse de Chopin en rangeant le campement. Objectif : départ à 8h pétante sans petit dej! 

    8h : Une fois le campement rangé, les casques enfilés et les lacets faits, nous sommes prêts pour le départ à 8h pile comme prévu. Nous partons immédiatement casser le bitume avec un gros mojo mix d'Étienne de Crécy sur l'enceinte chargée à bloc' chargée à bloc.  

    9h : 1ère heure passée et 13 kilomètres parcourus en se remémorant avec nostalgie les souvenirs et histoires des premiers jours de leur épopée. Nous effectuons une petite pause express au supermarché pour acheter une bouteille d'eau et manger un petit bout de Halva achetée au marché de Poznan, gros condensé d'énergie pour attaquer la 2ème heure. Petit shot de vodka goût café et ça repart instantanément à 9h pétante. 

    10h : 27 kilomètres au compteur. Nous avons perdu pas mal de temps à cause d'un changement de planning de dernière minute. En effet, une immense carrière polonaise, qui n'était pas indiquée sur Google maps, s'est mise en travers de notre route en plein milieu de la forêt. Nous avons dû la contourner et effectuer un petit détour. Quelques kilomètres plus loin, nous trouvons enfin un endroit de rêve pour prendre notre petit déjeuner de guerrier. En effet, une table et des chaises à l'ombre se présentent soudainement à nous sur le bord de la route devant une petite épicerie dans un minivillage. Des abricots secs, des tartines de beurre de cacs, un yaourt nature avec des noisettes et une vielle qui nous parle un dialecte polonais oublié nous apportent le carburant nécessaire pour attaquer le début de cette grande et longue journée. Une fois nos ventres remplis vers 10h30, nous repartons sans perdre de temps. 

    11h : Nous passons la fameuse et symbolique barre des 36 km avec un petit vent de face nous effleurant la peau. De minivillages en minivillages, nous ridons tous seuls sur des routes de campagne désertiques. La température ambiante commence à monter rapidement. Nous sommes à présent aussi chauds que le soleil, rien ne peut nous arrêter.

    12h : Chauds comme la braise, nous passons la barre symbolique des 50 km une quinzaine de minutes avant midi. Notre mojo est au max de sa forme, nous avons fait une heure à fond à 20 km/h de moyenne sous grosse musique Amine Edge énervée. Nous profitons de ce moment symbolique pour faire une micro pause et pour passer en mode massage de crème solaire stratégique pour pouvoir affronter notre plus grand ennemi du jour : le soleil. Afin de faire chauffer un peu plus les pneus et conserver cette mojo vitesse, Waya décide de se faire tatouer un tattoo Hot Wheels temporaire en plein milieu du front. 

    13h : Déjà 68 kilomètres au compteur !! Nous avons largement été dopés cette dernière heure par le mix boiler room de Yaeji à New York ! Nous n'avons toujours pas déjeuné, il est donc nécessaire de remédier à cela au plus vite. Nous nous arrêtons sur un banc devant l'église d'un petit village pour concevoir nos 2 énormes sandwichs ! Cependant, c'est à ce moment précis que le bilan des objets perdus s'alourdit un peu plus… En effet, Kiki se rend compte qu'il a oublié les couverts qui séchaient au soleil sur le lieu du petit déjeuner soit 30 kilomètres plus tôt (il a probablement été ensorcelé par les incantations prononcées par la vielle dame)… nous qui pensions en avoir terminé avec notre collection d'objets perdus… nous ne nous laissons pas abattre par cette nouvelle et sortons les couverts de secours contenus dans le sac de Waya.  Pour ce dernier déjeuner, nous avons décidé de ne prendre aucun risque en achetant des produits de qualité supérieure la veille à Intermarche. Nous partons donc sur un gros combo baguette + camembert + tomme des Pyrénées + jambon cuit du boucher et, en bonus, de la confiture de figue pour faire monter le sandwich au level Michelin. Seul malus : la baguette n'est pas de qualité française… et ça se sent… 

    14h : l'engloutissement de nos 2 énormes sandwichs nous a demandé beaucoup d'efforts. Jamais un si gros sandwich n'avait été ingurgité durant toute cette aventure. Par conséquent, nous sommes à l'arrêt depuis presque une heure, incapable de bouger. Rien  n'a changé si ce n'est notre position qui est passée d'assise sur le banc à allongée sur l'herbe. Nous regrettons presque d'avoir été trop gourmand… malgré nos 27 ans, nous avons toujours les yeux plus gros que le ventre, c'est d'ailleurs même une des caractéristiques fondamentales définissant Waikiki. Il nous faut maintenant un peu de temps pour pouvoir convertir tout ce fromage en énergie cinétique. Afin d'accélérer ce processus lent, nous avons la bonne idée d'augmenter la pression au sein de la machine  et d'huiler les engrenages en mangeant nos yaourts Monte qui nous attendaient sagement dans notre sac. Cette opération délicate est bien entendu réalisée en position latérale de sécurité. 

    15h : après plus d'une heure de repos, nous reprenons enfin la route à 15h pétante avec du gros son pour nous rebooster. Cependant, suite à une réaction chimique exceptionnelle ayant lieu au sein de la machine de Kiki, la pression au sein de son réservoir principal a subitement dépassé les limites acceptables. Ce dysfonctionnement interne nous pousse à nous arrêter quelques minutes sur le bord de la route afin de faire reposer la machine et lui laisser le temps de dépressuriser naturellement en toute sécurité. Après quelques minutes de repos, la machine de Kiki redémarre enfin... 

    16h : Tels Bip-Bip et Coyote sous cocaïne, nous sommes inarrêtables et franchissons les 81 km sur une grosse ligne droite de 10 km. La machine de Kiki fonctionne à nouveau comme au bon vieux temps. Nous sommes très proches du but et nous sommes large niveau timing, seulement 19 km à effectuer en 2h, facile ! Nous pourrons même peut-être nous autoriser une petite pause goûter… 

    17h : cette dernière heure fût importante car nous avons explosé notre record personnel du nombre de kilomètres ridés en une seule journée qui était de 85 km. Pour fêter ça, nous nous sommes arrêtés il y a quelques minutes sur un énorme tronc d'arbre multicentenaire abattu sur le bord de la route. Tout en se nourrissant de l'énorme mojo contenu dans cet arbre, nous dégustons nos derniers yaourts Monte avec plaisir. Avant de reprendre la route à 17h pétante, nous préparons une playlist de musiques épiques faite avec tous les sons qui nous ont marqué depuis le début de l'aventure. Oxia, Eric Prydz, Dusty Kid, Vandal, Polo and Pan et même Biga Ranx nous accompagneront pour la scène finale. 

    18h : une quinzaine de minutes avant l'heure fatidique, nous tentons d'effectuer un dernier arrêt shopping pour espérer chopper in extremis des croissants ou même encore, pour les plus rêveurs, une bouteille de 51 qui traînerait par là… qui ne tente rien n'a rien…  malheureusement, c'est un nouvel échec, nous reprenons la route les poches vides. Pas grave, nous aurons probablement le temps d'en trouver une plus tard une fois sédentarisé à la ferme. Bref, nous nous concentrons à nouveau sur l'objectif ultime et nous franchissons enfin le panneau du village de Wodzierady, fameux village où se trouve la ferme. À présent, il ne reste plus qu'un petit kilomètre à parcourir… Au fur et à mesure que nous nous approchons du but, la tension monte, les battements s'accélèrent, nous ne réalisons pas vraiment ce qui est en train de se passer… de nombreuses questions et doutes nous traversent l'esprit… Et si cette histoire de ferme n'était que mensonge ? Et si Melvin s'était en fait reconverti en tueur en série polonais ? Et si tout cela n'était finalement qu'un stratagème pour nous séquestrer toute notre vie dans une cabane sur l'île au milieu du lac ? Devant toutes ces questions sans réponses, nous sommes désemparés… 

    Quand soudainement… nous distinguons… à une centaine de mètres du bord de la route… une foule en délire de 4 personnes agitant leurs bras et en criant dans un dialecte non perceptible… ''Ils sont là !'' s'exclame Waikiki qui s'empresse de partir à leur rencontre pour aller franchir la ligne d'arrivée. En effet, Melvin, Paulina, Ania et Wenjia sont bels et bien là, devant le portail, bouteille de vodka à la main, toast dans l'autre, prêts à nous accueillir tels des soldats revenant de la guerre. 

    Émus, nous franchissons les portes de cette terre promise à 17:59:59. Suite à cela, tout s'enchaîne très vite. Nous buvons cul sec notre premier shot de vodka à 18:00:01. Nous effectuons notre première blague à 18:00:04. Nous saluons nos chaleureux hôtes à 18:00:10. Notre premier toast est mangé à 18:01:03. Nous ne pouvons refuser notre 2ème shot de vodka à 18:01:05. Début du black-out à 18:02:36.

    Non, en vrai de vrai, pas de black-out, que du bonheur et 1g de vodka dans le sang. La vérité c'est que nous sommes accueillis très chaleureusement par nos hôtes avec une très forte dose de mojo au sein de cet endroit paradisiaque : La Clairière du Lac.

    Pour l'occasion, ils ont même installé une table et des chaises devant leur portail pour faire un petit apéro de bienvenue. Nous restons donc là quelques minutes à papoter, débriefer et faire nos retrouvailles. 

    Après seulement une trentaine de minute d'apéro, nous sommes déjà saouls comme des Polonais. Avant de passer à toute autre activité de retrouvailles, il devient absolument vital pour nos 2 sans abris répugnants de prendre une douche au plus vite. Une fois Waikiki lavé de toute bactérie, nous pouvons enfin profiter à fond de ce moment de récompense avec tout le monde. 

    Nous passons tous à table et dégustons un délicieux dîner de bienvenue à base de fromage, charcuterie, pain, soupe de courge, champignons en sauce… mise à part la vodka, tous les produits sont issus de la ferme, on se régale, nous ne pouvions pas rêver mieux pour notre fin d'aventure. 

    Paulina prépare même une potion spéciale pour Waya qui a le nez bouché à cause du rhume. Cette potion réalisée à base d'herbes du jardin et de vodka le calme tout de suite.

    Nous profitons de ce moment de partage pour en apprendre plus sur leur vie quotidienne à la ferme entre permaculture, chèvres et réalisation de potion magique. Demain, c'est jour de marché à Lodz comme toutes les semaines, 2 fois par semaine. Avec son polonais qui a du succès auprès des mamies polak, Melvin se rend au marché avec son plus bel accent français pour séduire ces vielles dames et, accessoirement, vendre les nombreux produits de la ferme : légumes frais cueillis la veille, aromates, lait, fromages, huile, crème pour le corps, vinaigre, jus, œufs… Nous l'aidons à réaliser la dernière tâche de préparation du marché : concocter des boîtes de salades fraîches à base de fleurs, roquette et salade. 

    Une fois les salades prêtes, il est grand temps d'achever cette longue journée éprouvante. À partir d'aujourd'hui, nous passons de nomades puants à sédentaires qui sentent bon la rose en posant le campement dans le jardin de la ferme. ''Encore la tente wola !?'' nous direz-vous. ''Oui !'' répondrons-nous mais, cette fois-ci, avec 2 améliorations de confort non négligeable : une couette et une grosse tente 4 personnes supplémentaire. Fini les positions fœtus sarcophage recroquevillées et les tetris complexes réalisés dans la tente. Place aux nuits à 4°C en caleçon sous la couette en position étoile de mer. Kiki dormira dans la grande tente avec sa douce et Waya dans la tente officielle du Waikiki Tour à côté du lac. 

    Juste avant d'aller se coucher, Kiki fait l'heureuse découverte de retrouver son Pikabag tout déchiqueté à côté de la tente. Carla, une des deux chiennes, ne semble pas avoir apprécié l'arrivée soudaine de ces 2 mâles alpha sur son territoire et le fait savoir. Par chance, Pikachu et le Voltaren s'en sont sortis sans trop d'égratignures. Bref, après cette dernière péripétie, il est enfin temps pour Waikiki d'achever ce long trip en rejoignant le pays des merveilles. Nos 2 amis se saluent, se séparent et s'en vont, la larme à l'œil et le sourire en coin, rejoindre leurs tentes respectives pour y passer la nuit. 

    Ils se consolent rapidement en réalisant que ce qu'ils viennent d'achever n'était autre que le préambule des millions d'autres aventures folles qu'ils vivront au cours de leurs nouvelles vies. Chaque nouveau jour, même s'il n'est pas aussi fougueux que ceux de la veille, sera vécu à fond en respectant scrupuleusement les codes de la mojo life. La suite sera d'ailleurs tout aussi excitante pour Waikiki car il restera quelques semaines dans cet endroit paisible en compagnie de cette petite famille pour se reposer tranquillou, apprendre à faire pousser des citrouilles, tâter le pis des chèvres, les aider à faire la moula, sécréter un peu d'endorphine, manger des fruits et légumes non pesticidés, se taper des barres de rires et, par-dessus tout, boire de la vodka. Tous les ingrédients réunis pour atteindre le mojo nirvana. 
    
    C'est ainsi que nos deux acolytes, accomplis et rassasiés de mojos, partent se coucher et achèvent cet épique périple sur ce 51e et ultime jour. 
    
    Bonne nuit les petits ...
    
    
    <hr>


    Nous tenons à remercier tous les fratés et fratées qui ont suivi notre aventure depuis le début et ceux qui sont arrivés en cours de route. Aussi, nous voulons remercier toutes les personnes qui nous ont généreusement hébergés ainsi que tous les donneurs de mojos que nous avons rencontré lors du périple. Mention spéciale à nos mamans et papas qui nous supportent depuis notre départ de la maison. Grosse dédicace également au mojo lui-même qui ne nous aura pas lâché en 51 jours d'aventure. Ce fût une expérience de ouf, remplie de rebondissements, d'émotions, de rencontres, d'apprentissage… cette aventure nous a enrichi et nous à fait grandir jusqu'à même atteindre les 27 ans au compteur ! Elle nous a notamment permis de désacraliser tout un tas de choses allant de la nuit sauvage sous la tente avec des araignées aux journées consécutives de vélo sans douche en passant par les dîners pasta au réchaud et les discussions polyglottes à poil. 
    
    Ce périple restera gravé à jamais en nous et au sein de ce Waikiki Tour blog.
    
    Nous espérons avoir pu inspirer et faire voyager quelques personnes le temps d'un été avec nos histoires farfelues. Si une envie d'aventure vous prend, n'hésitez plus et partez vers l'inconnu ! Qui sait ce que le mojo vous réservera !
    
    Na zdrowie ! 

    <a class="href" href="/places/melvin-farm">Plus d'infos à venir prochainement sur le site</a> à propos de la vie de cette petite famille en pleine campagne polonaise. Stay tuned !

    <i><b> To Be Continued </b></i> 
        `
    }
]

// stepMarkers[stepMarkers.length - 1].iconType = TandemIcon
export let lastPosition = stepMarkers[stepMarkers.length - 1].latLng
console.log(lastPosition)

let KWMarkers: MarkerMeta[] = [
    {
        markerId: "ephesus",
        latLng: [37.93925431799232, 27.341197623031263],
        iconType: BinocularsIcon,
        description: "Nice antik ruin in the south of Izmir",
        text: "Turkish don't like french debate. payot !",
        imagesName: [
            "IMG_20201114_132813.jpg",
            "IMG_20201114_134447.jpg",
            "IMG_20201114_134916.jpg",
            "IMG_20201114_141712.jpg",
            "IMG_20201114_145739.jpg",
        ]
    },
]

export let allMarkers: MarkerMeta[] = specialMarkersPositions.concat(stepMarkers)//.concat(KWMarkers)


export function fetchMarkerMeta(markerId: string): MarkerMeta | undefined {
    return allMarkers.find(marker => marker.markerId === markerId)
}

interface getPreviousAndAfterId_RV {
    previousMarkerId: string | undefined
    afterMarkerId: string | undefined
}

export function getPreviousAndAfterId(markerId: string): getPreviousAndAfterId_RV | null {
    let thisMarkerMeta = fetchMarkerMeta(markerId)

    if (thisMarkerMeta === undefined) {
        return null
    }

    if (!thisMarkerMeta.index) {
        return null
    }
    let thisMarkerIndex = thisMarkerMeta.index


    let previousMarkerId;
    let previousAdjustedIndex = thisMarkerIndex - 2
    if ((previousAdjustedIndex >= 0) && (previousAdjustedIndex < stepMarkers.length)) {
        previousMarkerId = stepMarkers[previousAdjustedIndex].markerId
    }

    let afterMarkerId;
    let afterAdjustedIndex = thisMarkerIndex
    if (afterAdjustedIndex < stepMarkers.length) {
        afterMarkerId = stepMarkers[afterAdjustedIndex].markerId
    }


    return { previousMarkerId, afterMarkerId }
}